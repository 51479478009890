import { FormControl, FormField, FormItem } from "@/components/ui/form"
import { Input } from "@/components/ui/input"
import { useFormContext } from "react-hook-form"
import { GroupedInspections, useInspectionData } from '../hooks/useInspectionData'
import { FormLabelWithIndicator } from './form-label-with-indicator'
import { useEffect, useMemo } from 'react'

interface AssetDisplayProps {
  inspections: GroupedInspections;
  name: string;
  label: string;
  required: boolean;
}

export function AssetDisplay({ inspections: groupedInspections, name, label, required }: AssetDisplayProps) {
  const { control, watch, setValue } = useFormContext();
  const selectedInspectionId = watch('assessmentId');

  const { assetTitle, assetId } = useMemo(() => {
    console.debug('AssetDisplay: useMemo', { selectedInspectionId, groupedInspections });
    let assetTitle = '(None)';
    let assetId = undefined;

    if (selectedInspectionId) {
      for (const [assetName, inspections] of Object.entries(groupedInspections)) {
        const inspection = inspections.find(i => i.id === selectedInspectionId);
        if (inspection) {
          assetTitle = assetName;
          assetId = assetName === 'Unknown' ? null : inspection.asset;
          break;
        }
      }
    }

    return { assetTitle, assetId };
  }, [selectedInspectionId, groupedInspections]);

  useEffect(() => {
    console.debug('AssetDisplay: useEffect', { assetId, name });
    if (assetId) {
      setValue(name, assetId, {
        shouldDirty: true,
        shouldTouch: true
      });
    } else {
      setValue(name, undefined, {
        shouldDirty: true,
        shouldTouch: true
      });
    }
  }, [assetId, name, setValue]);

  return (
    <FormField
      control={control}
      name={name}
      render={({ field }) => (
        <FormItem>
          <FormLabelWithIndicator
            label={label}
            isValid={!!field.value}
            required={required}
          />
          <FormControl>
            <Input
              value={assetTitle}
              readOnly
              disabled
              className="border-primary/20"
            />
          </FormControl>
        </FormItem>
      )}
    />
  )
}
