import { FormControl, FormDescription, FormField, FormItem, FormMessage } from "@/components/ui/form"
import { Control } from "react-hook-form"
import { FormLabelWithIndicator } from "../form-label-with-indicator"
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group"
import { CustomFieldProps } from '../custom-fields-container';

type CustomRadioFieldProps = CustomFieldProps & {
  options: { id: string; displayName: string }[]

}

export function CustomRadioField({
  control,
  name,
  label,
  description,
  showDescription,
  info,
  required,
  options,
  isValid,
}: CustomRadioFieldProps) {
  return (
    <FormField
      control={control}
      name={name}
      render={({ field }) => (
        <FormItem>
          <FormLabelWithIndicator 
            label={label} 
            isValid={isValid} 
            required={required} 
            info={info} 
          />
          <FormControl>
            <RadioGroup
              name={name}
              onValueChange={field.onChange}
              value={field.value}
              className="flex flex-col space-y-0.5"
            >
              {options.map((option) => (
                <div key={option.id} className="flex items-center space-x-2">
                  <RadioGroupItem className="" value={option.id} id={option.id} />
                  <label className="text-sm" htmlFor={option.id}>{option.displayName}</label>
                </div>
              ))}
            </RadioGroup>
          </FormControl>
          {showDescription && (<FormDescription>{description}</FormDescription>)}
          <FormMessage />
        </FormItem>
      )}
    />
  )
}
