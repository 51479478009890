import { Header } from './header';
import { useState } from 'react';

export function InvitationPage() {
  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100 text-gray-900 relative">
      <div className="flex w-200 min-h-full flex-col justify-center px-6 py-12 lg:px-8">
        <Header />

        <form className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
          <div className="mt-2 mb-3 text-raytd-900 text-xl">Accept Invite</div>
          <div className="mb-4 text-grey-500"><span className="font-semibold">Alice Smith</span> wants you to join their team at <span className="font-semibold">Queensland Health</span></div>
          <label
            htmlFor="user"
            className="block text-sm font-medium leading-6 text-gray-500"
          >
            I am a
          </label>
          <div className="inline-block relative w-full">
            <select
              className="block appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
              name="user"
            >
              <option>Project Manager</option>
              <option>Accreditored Assesor</option>
              <option>Assesor</option>
            </select>
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
              <svg
                className="fill-current h-4 w-4"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
              </svg>
            </div>
          </div>
<div className="mt-4"/>
          <div className="flex w-full justify-start">
            <button
              type="submit"
              className="flex w-40 bg-raytd-600 justify-center px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Create Account
            </button>
            <div className="flex-1 w-1" />
          </div>
          <div className="flex w-full justify-end mt-10">
            <div className="text-sm justify-end">
              <a href="#login" className="text-raytd-600 hover:text-raytd-500">
                Already have an account? Login
              </a>
            </div>
          </div>

          {/* <FormAction handleSubmit={handleSubmit} text="Signup" /> */}
        </form>
      </div>
    </div>
  );
}

export default InvitationPage;
