import { Provider } from 'react-redux';
import { rootStore } from '@app.raytd.com/store';
import * as ReactDOM from 'react-dom/client';
import App from './app/app';


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <Provider store={rootStore}>
        <App />
  </Provider>
);
