import { Building, Floor, FloorLocation } from "@app.raytd.com/store";

function sortByKey(key: string) {
    return (a: any, b: any) => {
        return a[key].localeCompare(b[key], undefined, { numeric: true, sensitivity: 'base' });
    };
}

function convertToNestedStructure(
    buildings: Building[],
    floors: Floor[],
    locations: FloorLocation[]
) {
    return [...buildings].sort(sortByKey('description'))
        .map((building) => ({
            id: building.id.toString(),
            building_id: building.id,
            parentId: building.id,
            original: building,
            title: building.description,
            isOpen: true,
            children: floors
                .filter((floor) => floor.building_id === building.id)
                .sort(sortByKey('description'))
                .map((floor) => ({
                    id: `${building.id}-${floor.id}`,
                    parentId: floor.id,
                    level_id: floor.id,
                    building_id: building.id,
                    original: floor,
                    title: floor.description,
                    isOpen: true,
                    children: locations
                        .filter(
                            (location) =>
                                location.level_id === floor.id
                        )
                        .sort(sortByKey('description'))
                        .map((location) => ({
                            id: `${building.id}-${floor.id}-${location.id}`,
                            parentId: floor.id,
                            area_id: location.id,
                            level_id: floor.id,
                            building_id: building.id,
                            original: location,
                            title: location.description,
                            isOpen: false,
                        })),
                })),
        }));
}

function searchNestedStructure(items: any[], searchTerm: string) {

    if (searchTerm.trim() === '') {
        return items;
    }

    const searchTermLower = searchTerm.toLowerCase();

    function search(items: any[]): any[] {
        const results = [];

        for (const item of items) {
            const match = item.title.toLowerCase().includes(searchTermLower);
            const childResults = item.children ? search(item.children) : [];
            const actualChildren = item.children?.length || 0;

            if (match || childResults.length > 0) {
                results.push({
                    ...item,
                    children: childResults,
                    actualChildren,
                });
            }
        }

        return results;
    }

    return search(items);

}

export { convertToNestedStructure, searchNestedStructure };