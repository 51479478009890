import { RootState } from '../root/root.store';
import { Action, Middleware } from '@reduxjs/toolkit';
import axios from 'axios';
import { 
  startUpload, 
  updateProgress, 
  completeUpload, 
  failUpload 
} from './image-upload-slice';

const API_URL = (import.meta.env.VITE_API_URL as string) + '/api/v1';

export const uploadMiddleware: Middleware = store => next => (action: Action & { payload?: any })  => {
    if (action.type === 'uploads/initiateUpload') {
        const { id, file, formId, testItemId } = action.payload;

        const state = store.getState() as RootState;
        const token = state.auth.user?.token;
        const csrf = state.auth.xsrfToken;
    
    
        // Start the upload process
        store.dispatch(startUpload(id));
    
        const formData = new FormData();
        formData.append('file', file);
        formData.append('tempId', id);        // Required by Laravel
        formData.append('formId', formId);    // Required by Laravel
        
        if (testItemId) {
          formData.append('test_item_id', testItemId.toString());
        }
    
        axios.post(`${API_URL}/tests/uploadImage`, 
            formData, {

          headers: {
            'Content-Type': 'multipart/form-data',
            'Accept': 'application/json',
            'Authorization': token ? `Bearer ${token}` : '',
            'X-XSRF-TOKEN': csrf || ''
          },
          withCredentials: true,
          onUploadProgress: (progressEvent) => {
            const progress = Math.round(
              (progressEvent.loaded * 100) / (progressEvent.total ?? 100)
            );
            store.dispatch(updateProgress({ id, progress }));
          }
        })
        .then(response => {
            store.dispatch(completeUpload({ 
              id, 
              mediaInfo: response.data.image 
            }));
          })
          .catch(error => {
            const errorMessage = error.response?.data?.errors || error.message;
            store.dispatch(failUpload({ 
              id, 
              error: typeof errorMessage === 'string' ? errorMessage : JSON.stringify(errorMessage)
            }));
          });
      }
      
      return next(action);
}
