import { ClassificationTree } from '../element/types';
import { CompanyEntity } from '../company/company.slice';

import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
  EntityState,
  PayloadAction,
} from '@reduxjs/toolkit';

import { UsersEntity } from '../users/users.slice';
import { TestSuiteType } from '../tests/entity';

export const ASSESSMENT_FEATURE_KEY = 'assessment';

/*
 * Update these interfaces according to your requirements.
 */

type RatingType = 'standard' | 'advanced' | 'custom' | 'basic'; // Add more types as needed

// interface RatingSummary<T extends RatingType, Categories extends Record<string, number>> {
//     type: T;
//     ratings: Categories;
// }

interface RatingSummary<Categories extends { type: RatingType } & Record<string, number|string>> {
  ratings: Categories;
}

export interface StandardRatingCategories {
  type: 'standard';
  [key: string]: number | string;
  veryPoor: number;
  poor: number;
  fair: number;
  good: number;
  veryGood: number;
}

interface CustomRatingCategories {
  type: 'basic';
  [key: string]: number | string;
  low: number;
  medium: number;
  high: number;
}

type RatingSummary_Standard = RatingSummary<StandardRatingCategories>;
type RatingSummary_Basic = RatingSummary<CustomRatingCategories>;
type AnyRatingSummary = RatingSummary_Standard | RatingSummary_Basic; // Add more types as needed

export type Assessment = {
  id: number;
  asset: {
    id: number;
    title?: string;
    organisation?: CompanyEntity;
  };
  title: string;
  subtitle: string;
  imageUrl: string;
  owner: string;
  type: string;
  totalCompleted?: number;
  totalTests: number;
  //status: 'In Progress' | 'Completed' | 'Not Started';
  status: 'active' | 'archived';
  collaborators?: UsersEntity[];
  startDate?: Date;
  dueDate?: Date;
  completionDate?: Date;
  saved:number;
  published:number;
  draft:number;
  ratings?: AnyRatingSummary;
  testSuiteTypes?: TestSuiteType[];
  testCounts?: {
    archived?: number;
    draft?: number;
    qa?: number;
    published?: number;
  },
  testSuiteId: number[];
  elementGroupId: number[];
  elementGroups?: ClassificationTree[];
  is_globally_archived: boolean;
  retired?: boolean;
  // ratingSummary?: RatingSummary_Standard | RatingSummary_Advanced | RatingSummary_Custom;
};


export interface AssessmentState extends EntityState<Assessment, number> {
  loadingStatus: 'not loaded' | 'loading' | 'loaded' | 'error';
  error?: string | null;
}

export const assessmentAdapter = createEntityAdapter<Assessment>();

/**
 * Export an effect using createAsyncThunk from
 * the Redux Toolkit: https://redux-toolkit.js.org/api/createAsyncThunk
 *
 * e.g.
 * ```
 * import React, { useEffect } from 'react';
 * import { useDispatch } from 'react-redux';
 *
 * // ...
 *
 * const dispatch = useDispatch();
 * useEffect(() => {
 *   dispatch(fetchAssessment())
 * }, [dispatch]);
 * ```
 */
export const fetchAssessment = createAsyncThunk<Assessment[]>(
  'assessment/fetchStatus',
  async (_, thunkAPI) => {
    /**
     * Replace this with your custom fetch call.
     * For example, `return myApi.getAssessments()`;
     * Right now we just return an empty array.
     */
    return Promise.resolve([]);
  }
);

export const initialAssessmentState: AssessmentState =
  assessmentAdapter.getInitialState({
    loadingStatus: 'not loaded',
    error: null,
  });

export const assessmentSlice = createSlice({
  name: ASSESSMENT_FEATURE_KEY,
  initialState: initialAssessmentState,
  reducers: {
    add: assessmentAdapter.addOne,
    remove: assessmentAdapter.removeOne,
    // ...
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAssessment.pending, (state: AssessmentState) => {
        state.loadingStatus = 'loading';
      })
      .addCase(
        fetchAssessment.fulfilled,
        (state: AssessmentState, action: PayloadAction<Assessment[]>) => {
          assessmentAdapter.setAll(state, action.payload);
          state.loadingStatus = 'loaded';
        }
      )
      .addCase(fetchAssessment.rejected, (state: AssessmentState, action) => {
        state.loadingStatus = 'error';
        state.error = action.error.message;
      });
  },
});

/*
 * Export reducer for store configuration.
 */
export const assessmentReducer = assessmentSlice.reducer;

/*
 * Export action creators to be dispatched. For use with the `useDispatch` hook.
 *
 * e.g.
 * ```
 * import React, { useEffect } from 'react';
 * import { useDispatch } from 'react-redux';
 *
 * // ...
 *
 * const dispatch = useDispatch();
 * useEffect(() => {
 *   dispatch(assessmentActions.add({ id: 1 }))
 * }, [dispatch]);
 * ```
 *
 * See: https://react-redux.js.org/next/api/hooks#usedispatch
 */
export const assessmentActions = assessmentSlice.actions;

/*
 * Export selectors to query state. For use with the `useSelector` hook.
 *
 * e.g.
 * ```
 * import { useSelector } from 'react-redux';
 *
 * // ...
 *
 * const entities = useSelector(selectAllAssessment);
 * ```
 *
 * See: https://react-redux.js.org/next/api/hooks#useselector
 */
const { selectAll, selectEntities } = assessmentAdapter.getSelectors();

export const getAssessmentState = (rootState: {
  [ASSESSMENT_FEATURE_KEY]: AssessmentState;
}): AssessmentState => rootState[ASSESSMENT_FEATURE_KEY];

export const selectAllAssessment = createSelector(
  getAssessmentState,
  selectAll
);

export const selectAssessmentEntities = createSelector(
  getAssessmentState,
  selectEntities
);
