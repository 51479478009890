import React, { useState, useMemo, useEffect, useCallback } from 'react'
import { useFormContext } from "react-hook-form"
import { Button } from "@/components/ui/button"
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@/components/ui/command"
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover"
import { FormControl, FormField, FormItem, FormLabel } from "@/components/ui/form"
import { useTestSpecificationData } from '../hooks/useTestSpecifications'
import { TestSpecification, TestSuites } from '../types/test-specification'
import { ChevronDown, ChevronRight, X, Loader2, MapPinPlusIcon } from 'lucide-react';
import { cn } from '@/lib/utils'
import { sentenceCase } from 'change-case';

const getTestSuiteTypeBorderVariant = (type: string) => {
  switch (type) {
      case 'compliance':
          return 'border-l-compliance-500';
      case 'condition':
          return 'border-l-condition-500';
      case 'generic':
          return 'border-l-zinc-500';
      default:
          return '';
  }
}

type FoundSpec = {
  suite: TestSuites;
  specification: TestSpecification;
} | undefined;

interface TestSpecificationSelectorProps {
  name: string;
  label: string;
  testSuites: TestSuites[];
  labelComponent?: React.ReactNode;
  onSelect?: (value: number | null) => void;
  onChange?: (value: number) => void; // Added onChange prop
}

export const TestSpecificationSelector: React.FC<TestSpecificationSelectorProps> = React.memo(({ name, label, testSuites, labelComponent, onSelect, onChange }: TestSpecificationSelectorProps) => {
  const { control, watch } = useFormContext();
  const organizedData = useTestSpecificationData(testSuites);
  const [step, setStep] = useState<'suite' | 'category' | 'subcategory' | 'test'>('suite');
  const [selectedSuite, setSelectedSuite] = useState<string | null>(null);
  const [selectedCategory, setSelectedCategory] = useState<string | null>(null);
  const [selectedSubcategory, setSelectedSubcategory] = useState<string | null>(null);
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState('');

    const initialValue = watch(name);

  const getSelectedTest = useCallback((specId: number) => {
    return testSuites.reduce<FoundSpec>((found, suite) => {
      if (found) return found;
      const specification = suite.specifications.find(spec => spec.id === Number(specId));
      return specification ? { suite, specification } : undefined;
    }, undefined);
  }, [testSuites]);

  const updateSelection = useCallback((selectedTestId: number | null) => {
    if (selectedTestId === null) {
      setSelectedSuite(null);
      setSelectedCategory(null);
      setSelectedSubcategory(null);
      setStep('suite');
    } else {
      // const selectedTest = testSpecifications.find(spec => spec.id === Number(newValue));
      const selectedTest = testSuites.reduce<FoundSpec>((found, suite) => {
        if (found) return found;
        const specification = suite.specifications.find(spec => spec.id === Number(selectedTestId));
        return specification ? { suite, specification } : undefined;
      }, undefined);

      if (selectedTest) {
        setSelectedSuite(selectedTest.suite.title || null);
        setSelectedCategory(selectedTest.specification.category || null);
        setSelectedSubcategory(selectedTest.specification.subcategory || null);
        setStep('test');
        onSelect(Number(selectedTestId));
      }
    }
  }, [testSuites, onSelect]);

  useEffect(() => {
    if (initialValue !== undefined) {
      updateSelection(initialValue);
    } else {
      updateSelection(null);
    }
  }, [initialValue, updateSelection]); 

  useEffect(() => {
    console.debug('test suites updated');
    }, [organizedData]);


  const filteredTestSpecifications = useMemo(() => {
    if (!search) return [];

    return testSuites
      .flatMap(suite => suite.specifications)
      .filter(spec => spec.label?.toLowerCase().includes(search.toLowerCase()));
  }, [testSuites, search]);

  const groupedSearchResults = useMemo(() => {
    const grouped: { [key: string]: TestSpecification[] } = {};
    console.debug('tests', )
    filteredTestSpecifications.forEach(spec => {
      const testSuite = testSuites.find(suite => suite.id === spec.test_suite_id);
      let key;
      if (testSuite.type !== 'condition') {
        key = `${testSuite.title} > ${spec.category} > ${spec.subcategory}`;
      } else {
        key = `${testSuite.title}`;
      }
      if (!grouped[key]) {
        grouped[key] = [];
      }
      grouped[key].push(spec);
    });
    return grouped;
  }, [testSuites, filteredTestSpecifications]);

  const goToTestSuites = useCallback(() => {
    setStep('suite');
    setSelectedSuite(null);
    setSelectedCategory(null);
    setSelectedSubcategory(null);
  }, []);

  const goToCategory = useCallback(() => {
    if (selectedSuite) {
      setStep('category');
      setSelectedCategory(null);
      setSelectedSubcategory(null);
    }
  }, [selectedSuite]);

  const goToSubcategory = useCallback(() => {
    if (selectedSuite && selectedCategory) {
      setStep('subcategory');
      setSelectedSubcategory(null);
    }
  }, [selectedSuite, selectedCategory]);

  const handleSelect = useCallback((value: number) => {
    setOpen(false);
    onChange && onChange(value); // Updated to call onChange
    onSelect && onSelect(Number(value) || null);
  }, [onChange, onSelect]);

  const autoSelectSingleChild = useCallback(async (currentSuite: string, currentCategory: string | null = null, currentSubcategory: string | null = null, depth: number = 0) => {
    if (depth >= 4) return; // Prevent infinite loops by limiting depth
    
    if (!currentSuite) return;

    console.debug('autoSelectSingleChild', { currentSuite, currentCategory, currentSubcategory, depth });
    
    // Check current level and get next options
    let nextOptions: string[] = [];
    if (!currentCategory) {
      // At suite level, get categories
      nextOptions = Object.keys(organizedData[currentSuite] || {});
    } else if (!currentSubcategory) {
      // At category level, get subcategories
      nextOptions = Object.keys(organizedData[currentSuite][currentCategory] || {});
    } else {
      // At subcategory level, get tests
      nextOptions = (organizedData[currentSuite][currentCategory][currentSubcategory] || [])
        .map(test => test.label);
    }
  
    // If exactly one option exists, auto-select it
    if (nextOptions.length === 1) {
      if (!currentCategory) {
        setSelectedCategory(nextOptions[0]);
        setStep('subcategory');
        // Recursively check next level
        await autoSelectSingleChild(currentSuite, nextOptions[0], null, depth + 1);
      } else if (!currentSubcategory) {
        setSelectedSubcategory(nextOptions[0]); 
        setStep('test');
        // Recursively check next level
        await autoSelectSingleChild(currentSuite, currentCategory, nextOptions[0], depth + 1);
      } else {
        // At test level, select the test
        const test = organizedData[currentSuite][currentCategory][currentSubcategory][0];
        onChange && onChange(test.id);
        handleSelect(test.id);
      }
    }
  }, [organizedData, onChange, handleSelect]);
  

  const renderSearchResults = useCallback((onChange: (value: number) => void) => (
    <>
      {Object.entries(groupedSearchResults).map(([path, specs]) => (
        <CommandGroup key={path} heading={path}>
          {specs.map((spec) => (
            <CommandItem
              key={spec.id}
              onSelect={() => {
                onChange(spec.id);
                handleSelect(spec.id);
              }}
            >
              {spec.label}
            </CommandItem>
          ))}
        </CommandGroup>
      ))}
    </>
  ), [groupedSearchResults, handleSelect]);

  const renderContent = useCallback((onChange: (value: number) => void) => {
    if (search) return renderSearchResults(onChange);

    const groupedByType = testSuites.reduce((acc, suite) => {
      if (!acc[suite.type]) {
        acc[suite.type] = [];
      }
      acc[suite.type].push(suite.title?.trim());
      return acc;
    }, {} as Record<string, string[]>)

    switch (step) {
      case 'suite':
        return (
          <>
            {Object.keys(groupedByType).sort().map((type) => (
              <CommandGroup 
                key={type} 
                heading={sentenceCase(type)}
                className={cn(
                  "border-l-2 border-l-green-500 ml-2 rounded-none mb-0.5 my-1",
                  getTestSuiteTypeBorderVariant(type)
                )}
                >
            {groupedByType[type].map((suiteId) => (
              <CommandItem
                key={suiteId}
                className=''
                onSelect={() => {
                  setSelectedSuite(suiteId);
                  setStep('category');
                  autoSelectSingleChild(suiteId);
                }}
              >
                {suiteId}
              </CommandItem>
            ))}
            </CommandGroup>
            ))}
            </>
        );
      case 'category':
        return selectedSuite && organizedData[selectedSuite] ? (
          <CommandGroup heading="Select Category">
            {Object.keys(organizedData[selectedSuite]).map((category) => (
              <CommandItem
                key={category}
                onSelect={() => {
                  setSelectedCategory(category);
                  setStep('subcategory');
                  autoSelectSingleChild(selectedSuite, category);

                }}
              >
                {category}
              </CommandItem>
            ))}
          </CommandGroup>
        ) : null;
      case 'subcategory':
        return (selectedSuite && selectedCategory && organizedData[selectedSuite][selectedCategory]) ? (
          <CommandGroup heading="Select Subcategory">
            {Object.keys(organizedData[selectedSuite][selectedCategory]).map((subcategory) => (
              <CommandItem
                key={subcategory}
                onSelect={() => {
                  setSelectedSubcategory(subcategory);
                  setStep('test');
                  autoSelectSingleChild(selectedSuite, selectedCategory, subcategory);

                }}
              >
                {subcategory}
              </CommandItem>
            ))}
          </CommandGroup>
        ) : null;
      case 'test':
        console.debug('selectedSuite', { selectedSuite, selectedCategory, selectedSubcategory }, organizedData);
        return (selectedSuite && selectedCategory && selectedSubcategory &&
          organizedData[selectedSuite][selectedCategory][selectedSubcategory]) ? (
          <CommandGroup heading="Select Test">
            {organizedData[selectedSuite][selectedCategory][selectedSubcategory].map((test) => (
              <CommandItem
                key={test.id}
                onSelect={() => {
                  onChange(test.id);
                  handleSelect(test.id);
                }}
                className="flex flex-col justify-center items-start border-b border-b-muted"
              >
                <div>{test.label}</div>
                <div className="text-xs text-zinc-500">{test.description}</div>
                
              </CommandItem>
            ))}
          </CommandGroup>
        ) : null;
    }
  }, [search, step, selectedSuite, selectedCategory, selectedSubcategory, organizedData, renderSearchResults, handleSelect]);

  const renderFixedHeader = useCallback(() => {
    if (search) return null;

    let path = [];
    if (selectedSuite) {
      path.push(`${selectedSuite}`);
    }
    if (selectedCategory) {
      path.push(selectedCategory);
    }
    if (selectedSubcategory) {
      path.push(selectedSubcategory);
    }

    if (path.length === 0) return null;

    return (
      <div className="sticky top-0 bg-white dark:bg-gray-800 p-2 border-b flex items-center justify-between text-xs text-muted-foreground">
        <div className="flex items-center space-x-1">
          {path.map((item, index) => (
            <span key={item} className="flex items-center">
              {index > 0 && <ChevronRight className="h-4 w-4 mx-1" />}
              <button
                onClick={() => {
                  if (index === 0) goToTestSuites();
                  else if (index === 1) goToCategory();
                  else if (index === 2) goToSubcategory();
                }}
                className="hover:underline focus:outline-none"
              >
                {item}
              </button>
            </span>
          ))}
        </div>
        <Button
          variant="ghost"
          size="icon"
          onClick={goToTestSuites}
          className="h-6 w-6"
        >
          <X className="h-4 w-4" />
        </Button>
      </div>
    );
  }, [search, selectedSuite, selectedCategory, selectedSubcategory, goToTestSuites, goToCategory, goToSubcategory]);

  const renderDisplayValue = useCallback(() => {
    if (initialValue) {
      const selectedSpec = getSelectedTest(initialValue);
      if (!selectedSpec) {
        return "Select Test Specification";
      }
      //return selectedSpec ? selectedSpec.specification.label : "Select Test Specification";
      return <div className={cn("flex flex-col items-start gap-1 border-l-2 pl-2", getTestSuiteTypeBorderVariant(selectedSpec.suite.type))}>
        <div className="text-sm text-muted-foreground">{selectedSpec.suite.title}</div>
        <div className="flex flex-row gap-1 items-center text-sm text-muted-foreground">{selectedSpec.specification.category}<ChevronRight className="h-4 w-4 text-zinc-400"/>{selectedSpec.specification.subcategory}</div>
        <div className="font-semibold">{selectedSpec.specification.label}</div>
      </div>;

    } else {
      return "Select Test Specification";
    }
  }, [initialValue, getSelectedTest]);

  return (
    <FormField
      control={control}
      name={name}
      render={({ field }) => (
        <FormItem>
          {labelComponent || <FormLabel>{label}</FormLabel>}
          <FormControl>
            <Popover open={open} onOpenChange={setOpen}>
              <PopoverTrigger asChild>
                <Button
                  variant="outline"
                  role="combobox"
                  aria-expanded={open}
                  className="h-full w-full justify-between"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setOpen(true);
                  }}
                >
                  {/* {initialValue
                    ? (() => {
                      // const selectedSpec = testSuites.find(spec => spec.id === Number(value));
                      const selectedSpec = getSelectedTest(initialValue);
                      return selectedSpec ? selectedSpec.specification.label : "Select Test Specification";
                    })()
                    : "Select Test Specification"} */}
                    {renderDisplayValue()}
                  <ChevronDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                </Button>
              </PopoverTrigger>
              <PopoverContent 
                className="min-w-[400px] w-[var(--radix-popover-trigger-width)] p-0 popover-content" 
                side="bottom" 
                align="start"
                modal={true}
                onOpenAutoFocus={e=>e.preventDefault()}
                >
                <Command className="max-h-[300px] overflow-y-auto">
                  <CommandInput
                    placeholder="Search test specifications..."
                    value={search}
                    onValueChange={setSearch}
                         className="w-full"
                  aria-label="Search spaces"
                  />
                  {renderFixedHeader()}
                  <CommandList>
                    <CommandEmpty>No test specification found.</CommandEmpty>
                    {renderContent(field.onChange)}
                  </CommandList>
                </Command>
              </PopoverContent>
            </Popover>
          </FormControl>
        </FormItem>
      )}
    />
  );
});

export default TestSpecificationSelector;

