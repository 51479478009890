interface FieldDefaults {
    active: boolean;
    required: boolean;
  }
  
  export const getDefaultFieldSettings = (
    fieldName: string, 
    testSuiteType: 'compliance' | 'condition' | 'generic'
  ): FieldDefaults => {
    const baseFields = {
      asset: { active: true, required: true },
      location: { active: true, required: true },
      element: { active: true, required: true },
      element_name: { active: true, required: true },
      element_id: { active: true, required: true }
    };
  
    const testSuiteSpecificFields: Record<string, Record<string, FieldDefaults>> = {
      compliance: {
        test: { active: true, required: true },
        observations: { active: true, required: true },
        recommendations: { active: true, required: true },
        photographs: { active: true, required: true },
        rating: { active: true, required: true }
      },
      condition: {
        rating: { active: true, required: true },
        observations: { active: true, required: true },
        recommendations: { active: true, required: true },
        photographs: { active: true, required: true }
      },
      generic: {
        photographs: { active: true, required: true },
        observations: { active: true, required: true },
        recommendations: { active: true, required: true },
        rating: { active: false, required: false }
      }
    };
  
    return (
      baseFields[fieldName] || 
      testSuiteSpecificFields[testSuiteType]?.[fieldName] || 
      { active: true, required: false }
    );
  };
  