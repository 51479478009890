
// eslint-disable-next-line @typescript-eslint/no-unused-vars
// import {
//   LoginPage
// } from '@app.raytd.com/ui';

import { RouterProvider, createBrowserRouter } from 'react-router-dom';


// eslint-disable-next-line
import '../../store/src/lib/mocks';

import { PrivateRoute } from './_components';
import { Logout } from './layout/logout';

import AssetPage from './features/assets/AssetPage';
import AssetView from './features/assets/AssetView';
import AcceptInvite from './features/userOnboarding/components/acceptInvite';
import UsersAdminPage from './features/admin/UsersAdminPage';
import NewAssetPage from './features/assets/NewAssetPage';
import ElementGroups from './features/elements/ElementGroupPage';
import ElementPageLayout from './features/elements/ElementPageLayout';
import NewElementGroupPage from './features/elements/NewElementGroupPage';
import TestSuitePage from './features/testsuites/TestSuitePage';
import TestSuiteLayout from './features/testsuites/TestSuiteLayout';
import CreateTestSuitePage from './features/testsuites/CreateTestSuitePage';
import ReportBlocks from './features/blocks/BlocksList';
import BlockPageLayout from './features/blocks/BlockPageLayout';
import NewReportBlockPage from './features/blocks/NewBlockPage';
import InspectionsLayout from './features/inspections/InspectionsLayout';
import InspectionsPage from './features/inspections/InspectionsPage';
import NewInspectionPage from './features/inspections/NewInspectionPage';
import ReportsPage from './features/reports/ReportsPage';
import ReportLayout from './features/reports/ReportLayout';
import TestPage from './utils/TestPage';

import { lazy, Suspense } from 'react';
import LoadingFallback from '../components/layout/loading-fallback';

// Lazy load layouts
const AppLayout = lazy(() => import('./layout2/AppLayout'));
const AuthLayout = lazy(() => import('@/../ui/src/lib/login/AuthLayout'));

// Lazy load route modules
const AuthRoutes = lazy(() => import('./features/auth'));
const DashboardPage = lazy(() => import('./features/dashboard/DashboardPage'));
const ProfilePage = lazy(() => import('./features/profile/ProfilePage'));
const OrganisationPage = lazy(() => import('./features/organisation/OrganisationPage'));
const AssetRoutes = lazy(() => import('./features/assets'));
const ElementRoutes = lazy(() => import('./features/elements'));
const TestSuiteRoutes = lazy(() => import('./features/testsuites'));
const BlockRoutes = lazy(() => import('./features/blocks'));
const InspectionRoutes = lazy(() => import('./features/inspections'));
const ReportRoutes = lazy(() => import('./features/reports'));
const AdminRoutes = lazy(() => import('./features/admin'));



const ErrorThrowingComponent: React.FC = () => {
  throw new Error('This is a simulated error');
};
const router = createBrowserRouter([
  // Public auth routes
  {
    element: (
      <Suspense fallback={<LoadingFallback />}>
        <AuthLayout />
      </Suspense>
    ),
    children: [
      {
        path: '/*',
        element: (
          <Suspense fallback={<LoadingFallback />}>
            <AuthRoutes />
          </Suspense>
        )
      }
    ]
  },
  // Protected routes
  {
    element: <PrivateRoute />,
    children: [
      {
        path: '/',
        element: (
          <Suspense fallback={<LoadingFallback />}>
            <AppLayout />
          </Suspense>
        ),
        children: [
          {
            index: true,
            element: (
              <Suspense fallback={<LoadingFallback />}>
                <DashboardPage />
              </Suspense>
            )
          },
          {
            path: 'profile/*',
            element: (
              <Suspense fallback={<LoadingFallback />}>
                <ProfilePage />
              </Suspense>
            )
          },
          {
            path: 'organisation/*',
            element: (
              <Suspense fallback={<LoadingFallback />}>
                <OrganisationPage />
              </Suspense>
            )
          },
          // Feature routes
          {
            path: 'assets/*',
            element: (
              <Suspense fallback={<LoadingFallback />}>
                <AssetRoutes />
              </Suspense>
            )
          },
          {
            path: 'elements/*',
            element: (
              <Suspense fallback={<LoadingFallback />}>
                <ElementRoutes />
              </Suspense>
            )
          },
          {
            path: 'test-suites/*',
            element: (
              <Suspense fallback={<LoadingFallback />}>
                <TestSuiteRoutes />
              </Suspense>
            )
          },
          {
            path: 'report-blocks/*',
            element: (
              <Suspense fallback={<LoadingFallback />}>
                <BlockRoutes />
              </Suspense>
            )
          },
          {
            path: 'inspections/*',
            element: (
              <Suspense fallback={<LoadingFallback />}>
                <InspectionRoutes />
              </Suspense>
            )
          },
          {
            path: 'reports/*',
            element: (
              <Suspense fallback={<LoadingFallback />}>
                <ReportRoutes />
              </Suspense>
            )
          },
          {
            path: 'test',
            element: (
              <Suspense fallback={<LoadingFallback />}>
                <TestPage />
              </Suspense>
            )
          }
        ]
      },
      // Admin routes
      {
        path: 'admin/*',
        element: (
          <Suspense fallback={<LoadingFallback />}>
            <AdminRoutes />
          </Suspense>
        )
      }
    ]
  },
  // Catch-all route for 404
  {
    path: '*',
    element: (
      <div className="flex h-screen items-center justify-center">
        <h1 className="text-2xl font-semibold">404 - Page Not Found</h1>
      </div>
    )
  }
]);

export const App = () => {
  return <RouterProvider router={router} />;
}

export default App;
