import {
  alertsActions,
  selectAlert,
  useAppDispatch,
  useAppSelector,
} from '@app.raytd.com/store';
import { CheckCircleIcon, ExclamationCircleIcon } from '@heroicons/react/24/outline';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export function Alert() {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const alert = useAppSelector(selectAlert);

  useEffect(() => {
    if (alert?.timeout) {
      setTimeout(() => {
        dispatch(alertsActions.clear());
      }, alert.timeout);
    }
  }, [alert, dispatch]);

  useEffect(() => {
    dispatch(alertsActions.clear());
  }, [location, dispatch]);

  if (!alert) return null;
  const message = alert?.message || '';

  let alertClasses = 'bg-green-100 border-green-400 text-green-700';
  let AlertIcon = CheckCircleIcon;

  if(alert.type === 'alert-danger') {
    alertClasses = 'bg-red-100 border-red-400 text-red-700';
    AlertIcon = ExclamationCircleIcon;
  }

  return (
    <div
      className={`fixed top-0 z-50 opacity-90 inline-flex w-full items-center px-6 py-5 text-base ${alertClasses}`}
      role="alert"
    >
      <span className="mr-2">
        <AlertIcon className="h-6 w-6" />
      </span>
      {message}
      {alert?.dismissable && (
        <button
          type="button"
          className="text-warning-900 hover:text-warning-900 ml-auto box-content rounded-none border-none p-1 opacity-50 hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
          data-te-alert-dismiss
          aria-label="Close"
          onClick={() => dispatch(alertsActions.clear())}
        >
          <span className="w-[1em] focus:opacity-100 disabled:pointer-events-none disabled:select-none disabled:opacity-25 [&.disabled]:pointer-events-none [&.disabled]:select-none [&.disabled]:opacity-25">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              className="h-6 w-6"
            >
              <path
                fillRule="evenodd"
                d="M5.47 5.47a.75.75 0 011.06 0L12 10.94l5.47-5.47a.75.75 0 111.06 1.06L13.06 12l5.47 5.47a.75.75 0 11-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 01-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 010-1.06z"
                clipRule="evenodd"
              />
            </svg>
          </span>
        </button>
      )}
    </div>
  );
}

export default Alert;
