import { TestItem } from 'store/src/lib/tests/entity';
import { TestFormValues } from '../types/test-form';

const convertInspectionToFormValues = (
  tests: TestItem
): Partial<TestFormValues> => {
  const formattedData: Partial<TestFormValues> = {
    formId: tests.formId,
    assessmentId: tests.assessmentId,
    assetId: tests.assetId,
    test_specification_id: tests.testSpecificationId,
    building_id: tests.buildingId,
    level_id: tests.levelId,
    area_id: tests.areaId,
    element: tests.element_classification_id,
    element_id: tests.element_id ?? undefined,
    element_name: tests.element_name ?? undefined,
    observations: tests.observations,
    recommendations: tests.recommendations,
    result: tests.result as any,
    status: tests.status.toLowerCase() as any,
    images: tests.images,
    custom: tests.custom,
  };

  return formattedData;
};

export default convertInspectionToFormValues;
