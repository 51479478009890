import { XIcon } from "lucide-react";
import { Progress } from "./progress";
import { UploadItem } from "./types";
import { useEffect, useState } from "react";
import { cn } from "@/lib/utils";

interface UploadPreviewProps {
  upload: UploadItem;
  previewUrl?: string;
  onRemove?: () => void;
}

const UploadPreview: React.FC<UploadPreviewProps> = ({
  upload,
  previewUrl,
  onRemove
}) => {
  const [thumbnailLoaded, setThumbnailLoaded] = useState(false);
  const [thumbnailError, setThumbnailError] = useState(false);

  // Reset states when upload changes
  useEffect(() => {
    setThumbnailLoaded(false);
    setThumbnailError(false);
  }, [upload.mediaInfo?.thumbnail_url]);

  const handleThumbnailLoad = () => {
    setThumbnailLoaded(true);
  };

  const handleThumbnailError = () => {
    setThumbnailError(true);
  };

  return (
    <div className="relative group">
      <div className={cn("aspect-square rounded-md overflow-hidden bg-zinc-100",
        {
          "opacity-50": upload.mediaInfo?.deleted
        }
      )}>
        {upload.status === 'uploading' ? (
          <div className="w-full h-full flex items-center justify-center">
            <div className="space-y-2 w-3/4">
              {previewUrl && (
                <img
                  src={previewUrl}
                  alt={upload.fileName}
                  className="absolute inset-0 w-full h-full object-cover opacity-50"
                />
              )}
              <Progress
                value={upload.progress}
                size="sm"
                className="w-full relative z-10"
              />
              <p className="text-xs text-gray-500 text-center relative z-10">
                {upload.progress}%
              </p>
            </div>
          </div>
        ) : upload.mediaInfo ? (
          <>
            {/* Show local preview while thumbnail loads */}
            {previewUrl && (!thumbnailLoaded || thumbnailError) && (
              <img
                src={previewUrl}
                alt={upload.fileName}
                className="absolute inset-0 w-full h-full object-cover"
              />
            )}

            {/* Server thumbnail */}
            {!thumbnailError && (
              <img
                src={upload.mediaInfo.thumbnail_url}
                alt={upload.fileName}
                className={cn(
                  "absolute inset-0 w-full h-full object-cover transition-opacity duration-300",
                  thumbnailLoaded ? "opacity-100" : "opacity-0"
                )}
                onLoad={handleThumbnailLoad}
                onError={handleThumbnailError}
              />
            )}
          </>
        ) : (
          // Show local preview while processing
          <div className="w-full h-full flex items-center justify-center">
            {previewUrl ? (
              <img
                src={previewUrl}
                alt={upload.fileName}
                className="w-full h-full object-cover opacity-50"
              />
            ) : (
              <span className="text-sm text-zinc-500">Processing...</span>
            )}
          </div>
        )}
      </div>

      {/* Overlay for hover state */}
      <div className={cn(
        "absolute inset-0 bg-black/0 transition-colors",
        "group-hover:bg-black/10"
      )} />

      {onRemove && (
        <button
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            onRemove();
          }}
          className={cn(
            "absolute -top-2 -right-2 bg-red-500 text-white rounded-full p-1",
            "opacity-0 group-hover:opacity-100 transition-opacity",
            "focus:opacity-100 focus:outline-none focus:ring-2 focus:ring-red-500",
            "shadow-lg"
          )}
          aria-label={`Remove ${upload.fileName}`}
        >
          <XIcon className="h-4 w-4" />
        </button>
      )}

      {upload.error && (
        <div className="absolute bottom-0 left-0 right-0 bg-red-500 text-white p-1">
          <p className="text-xs truncate text-center" title={upload.error}>
            {upload.error}
          </p>
        </div>
      )}

      <div className={cn(
        "absolute bottom-0 left-0 right-0 bg-black/50 text-white py-1 px-2",
        "opacity-0 group-hover:opacity-100 transition-opacity"
      )}>
        <p className="text-xs truncate">
          {upload.fileName}
        </p>
      </div>
    </div>
  );
};

export { UploadPreview };