import { cn } from "@/lib/utils";
import { ImageOffIcon, Loader2, XIcon } from "lucide-react";
import { useCallback, useEffect, useState } from "react";
import { MediaInfo } from "./types";
import { usePhotoViewer } from "@/app/contexts/PhotoViewerContext";
import { ImageType } from "@/types/Images";

interface ExistingMediaPreviewProps {
    media: MediaInfo;
    onRemove?: () => void;
    onClick?: (id: number) => void;
    isRemoving?: boolean;
    disabled?: boolean;
    fallbackDelay?: number;
}

interface ImageState {
    loading: boolean;
    error: boolean;
    loaded: boolean;
}

export const ExistingMediaPreview: React.FC<ExistingMediaPreviewProps> = ({
    media,
    onRemove,
    onClick,
    isRemoving,
    disabled,
    fallbackDelay = 1000
}) => {
    const [imageState, setImageState] = useState<ImageState>({
        loading: true,
        error: false,
        loaded: false
    });
    const [showFallback, setShowFallback] = useState(false);

    useEffect(() => {
        const img = new Image();
        let timeoutId: NodeJS.Timeout;

        const loadImage = async () => {
            try {
                await new Promise<void>((resolve, reject) => {
                    img.onload = () => resolve();
                    img.onerror = () => reject();
                    img.src = media.thumbnail_url;
                });

                setImageState({
                    loading: false,
                    error: false,
                    loaded: true
                });
            } catch {
                setImageState({
                    loading: false,
                    error: true,
                    loaded: false
                });
            }
        };

        // Show fallback after delay if still loading
        timeoutId = setTimeout(() => {
            if (imageState.loading) {
                setShowFallback(true);
            }
        }, fallbackDelay);

        loadImage();

        return () => {
            clearTimeout(timeoutId);
            img.onload = null;
            img.onerror = null;
        };
    }, [media.thumbnail_url, fallbackDelay]);

    const handleRemove = useCallback((e: React.MouseEvent) => {
        e.stopPropagation();
        e.preventDefault();
        onRemove?.();
    }, [onRemove]);

    const handleClick = useCallback((e: React.MouseEvent) => {
        e.stopPropagation();
        e.preventDefault();
        onClick?.(media.id);
    }, [onClick]);

    return (
        <div className="relative group" onClick={handleClick}>
            <div
                className={cn(
                    "aspect-square rounded-md overflow-hidden bg-gray-100",
                    "transition-opacity duration-200",
                    {
                        "opacity-50": media.deleted
                    }
                )}
            >
                {imageState.error ? (
                    <div className="w-full h-full flex items-center justify-center bg-gray-50">
                        <ImageOffIcon className="h-8 w-8 text-gray-400" />
                    </div>
                ) : (
                    <>
                        {(imageState.loading && showFallback) && (
                            <div className="w-full h-full flex items-center justify-center animate-pulse">
                                <Loader2 className="h-8 w-8 text-gray-400 animate-spin" />
                            </div>
                        )}

                        <img
                            src={media.thumbnail_url}
                            alt={media.name}
                            className={cn(
                                "w-full h-full object-cover transition-opacity duration-200",
                                {
                                    "opacity-0": imageState.loading,
                                    "opacity-100": imageState.loaded
                                }
                            )}
                        />
                    </>
                )}

                {isRemoving && (
                    <div className="absolute inset-0 bg-black/50 flex items-center justify-center">
                        <Loader2 className="h-6 w-6 text-white animate-spin" />
                    </div>
                )}
            </div>

            {onRemove && !disabled && !isRemoving && (
                <button
                    onClick={handleRemove}
                    className={cn(
                        "absolute -top-2 -right-2 bg-red-500 text-white rounded-full p-1",
                        "opacity-0 group-hover:opacity-100 transition-opacity duration-200",
                        "focus:opacity-100 focus:outline-none focus:ring-2 focus:ring-red-500",
                        "shadow-lg hover:bg-red-600",
                        "transform hover:scale-110 active:scale-95 transition-transform"
                    )}
                    aria-label={`Remove ${media.name}`}
                >
                    <XIcon className="h-4 w-4" />
                </button>
            )}

            <div
                className={cn(
                    "absolute bottom-0 left-0 right-0 bg-black/50 text-white py-1 px-2",
                    "opacity-0 group-hover:opacity-100 transition-opacity duration-200",
                    "backdrop-blur-sm"
                )}
            >
                <p className="text-xs truncate">{media.name}</p>
            </div>
        </div>
    );
};
