import { createContext, useContext, ReactNode, useState } from 'react';
import { PhotoViewerDialog } from '@/components/raytd/photo-viewer';
import { ImageType } from '@/types/Images';

interface PhotoViewerContextType {
  openPhotoViewer: (images: ImageType[], initialIndex?: number) => void;
  closePhotoViewer: () => void;
}

const PhotoViewerContext = createContext<PhotoViewerContextType | undefined>(undefined);

export function PhotoViewerProvider({ children }: { children: ReactNode }) {
  const [isOpen, setIsOpen] = useState(false);
  const [images, setImages] = useState<ImageType[]>([]);
  const [initialIndex, setInitialIndex] = useState(0);

  const openPhotoViewer = (images: ImageType[], initialIndex = 0) => {
    setImages(images);
    setInitialIndex(initialIndex);
    setIsOpen(true);
  };

  const closePhotoViewer = () => {
    setIsOpen(false);
  };

  return (
    <PhotoViewerContext.Provider value={{ openPhotoViewer, closePhotoViewer }}>
      {children}
      <PhotoViewerDialog
        images={images}
        isOpen={isOpen}
        initialIndex={initialIndex}
        onClose={closePhotoViewer}
      />
    </PhotoViewerContext.Provider>
  );
}

export const usePhotoViewer = () => {
  const context = useContext(PhotoViewerContext);
  if (!context) {
    throw new Error('usePhotoViewer must be used within a PhotoViewerProvider');
  }
  return context;
}
