import { AlertDialogProps, useAlertDialog } from "@/hooks/useAlertDialog";
import React from "react";

type AlertContextData = {
    showAlertDialog: (props: AlertDialogProps) => Promise<boolean>;
};

const AlertContext = React.createContext<AlertContextData | undefined>(undefined);

export const AlertProvider = ({ children }) => {

    const { AlertDialogComponent, showAlertDialog } = useAlertDialog();

    return (
        <AlertContext.Provider value={{ showAlertDialog }}>
            {children}
            <AlertDialogComponent />
        </AlertContext.Provider>
    );
}

export const useAlert = () => {
    const context = React.useContext(AlertContext);
    if (!context) {
        throw new Error('useAlert must be used within a AlertProvider');
    }
    return context;
}

