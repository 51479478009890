import { useMemo } from 'react';
import { SpaceData, BuildingNode, LevelNode, AreaNode } from '../types/spaces';
import {
  useGetAssetAreasQuery,
  useGetAssetBuildingsQuery,
  useGetAssetLevelsQuery,
} from '@app.raytd.com/store';
import { convertToNestedStructure } from '@/utils/convertSpacesToNestedStructure';
import { skipToken } from '@reduxjs/toolkit/query';

export function useSpacesData(assetId: number | null) {
  const shouldFetch = assetId !== null;

  const {
    data: buildings,
    isLoading: isBuildingLoading,
    error: buildingError,
  } = useGetAssetBuildingsQuery(shouldFetch ? assetId : skipToken, {
    selectFromResult: ({ data, isLoading, error }) => ({
      data,
      isLoading,
      error,
    }),
  });

  const {
    data: levels,
    isLoading: isLevelsLoading,
    error: levelsError,
  } = useGetAssetLevelsQuery(shouldFetch ? assetId : skipToken, {
    selectFromResult: ({ data, isLoading, error }) => ({
      data,
      isLoading,
      error,
    }),
  });

  const {
    data: areas,
    isLoading: isAreasLoading,
    error: areasError,
  } = useGetAssetAreasQuery(shouldFetch ? assetId : skipToken, {
    selectFromResult: ({ data, isLoading, error }) => ({
      data,
      isLoading,
      error,
    }),
  });

  const isLoading = isBuildingLoading || isLevelsLoading || isAreasLoading;
  const error = buildingError || levelsError || areasError;

  const spaces = useMemo(() => {
    if (!shouldFetch || isLoading || error) {
      return [];
    }

    return convertToNestedStructure(buildings || [], levels || [], areas || []);
  }, [buildings, levels, areas, isLoading, error, shouldFetch]);

  return {
    spaces,
    isLoading,
    error,
    // Additional metadata
    hasData: !!(buildings?.length || levels?.length || areas?.length),
    isEmpty: !isLoading && !error && spaces.length === 0,
  };
}
