import { useState, useMemo, useEffect, useCallback } from 'react'
import { useForm, Controller, useFormContext, useFormState } from 'react-hook-form'
import { Button } from "@/components/ui/button"
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@/components/ui/command"
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover"
import { ChevronDown, ChevronRight, X, Loader2 } from 'lucide-react'
import { SpaceData, TreeNode, BuildingNode, LevelNode, AreaNode } from '../types/spaces'
import {isEqual} from 'lodash';

const noSelectionClass = "border-b border-gray-200 dark:border-gray-700 pb-2 mb-2 last:border-b-0 last:pb-0 last:mb-0";

type Selection = {
  buildingId: number | null;
  levelId: number | null;
  areaId: number | null;
}

interface SelectorState {
  path: TreeNode[];
  previousPath?: TreeNode[];
  search: string;
  isOpen: boolean;
  initialSelection: Selection
  selection: Selection;
  pendingSelection: Selection;
}

const initialState: SelectorState = {
  path: [],
  previousPath: [],
  search: '',
  isOpen: false,
  selection: {
    buildingId: null,
    levelId: null,
    areaId: null
  },
  pendingSelection: {
    buildingId: null,
    levelId: null,
    areaId: null
  },
  initialSelection: {
    buildingId: null,
    levelId: null,
    areaId: null
  }
};

interface SpaceSelectorProps {
  spaces: BuildingNode[];
  onSelect?: (building_id: number | null, level_id: number | null, area_id: number | null) => void;
  disabled?: boolean;
  defaultValues?: {
    building_id: number | null;
    level_id: number | null;
    area_id: number | null;
  };
  isLoading?: boolean;
}

// Type guard helper
const isNodeWithChildren = (node: TreeNode): node is BuildingNode | LevelNode => {
  return 'children' in node && Array.isArray(node.children);
};


export function SpaceSelector({ spaces, onSelect, defaultValues, disabled = false, isLoading = false }: SpaceSelectorProps) {
  const { control, setValue, watch } = useFormContext();

  // const [path, setPath] = useState<TreeNode[]>([]);
  //const [open, setOpen] = useState(false);
  // const [search, setSearch] = useState('');

  const building_id = watch('building_id');
  const level_id = watch('level_id');
  const area_id = watch('area_id');

  const [state, setState] = useState<SelectorState>(initialState);

  const updateState = useCallback((updates: Partial<SelectorState>) => {
    setState(prev => ({
      ...prev,
      ...updates
    }));
  }, []);

  useEffect(() => {

    let mounted = true;

    const { buildingId, levelId, areaId } = state.selection;


    const updateFormValues = async () => {
      if (!mounted) return;

      console.debug('updateFormValues:', {buildingId, levelId, areaId});

      if (isEqual(state.initialSelection, state.selection)) {
        console.debug('updateFormValues: Initial selection matches current selection:', state);
        return;
      }  
  
      try {
        await Promise.all([
          setValue('building_id', buildingId, { shouldDirty: true }),
          setValue('level_id', levelId, { shouldDirty: true }),
          setValue('area_id', areaId, { shouldDirty: true })
        ]);
  
        if (mounted) {
          onSelect?.(buildingId, levelId, areaId);
        }
      } catch (error) {
        console.error('Error updating form values:', error);
      }
    };

    updateFormValues();

    // Cleanup function to prevent updates after unmount
    return () => {
      mounted = false;
    };
  }, [state.selection, state.initialSelection, setValue, onSelect]);

  useEffect(() => {
    let mounted = true;
  
    const restoreState = async () => {
      if (!mounted || !spaces?.length) return;

      console.debug('updateFormValues restoreState:', {building_id, level_id, area_id});
  
      try {
        const newPath: TreeNode[] = [];
        const initialSelection = {
          buildingId: null,
          levelId: null,
          areaId: null
        };

  
        if (building_id) {
          const building = spaces.find(node => node.building_id === building_id);
          if (building) {
            newPath.push(building);
            initialSelection.buildingId = building.building_id;
  
            if (level_id && building.children) {
              const level = building.children.find(node => node.level_id === level_id);
              if (level) {
                newPath.push(level);
                initialSelection.levelId = level.level_id;
  
                if (area_id && level.children) {
                  const area = level.children.find(node => node.area_id === area_id);
                  if (area) {
                    newPath.push(area);
                    initialSelection.areaId = area.area_id;
                  }
                }
              }
            }
          }
        }
  
        if (mounted) {
          setState(prev => ({
            ...prev,
            path: newPath,
            previousPath: newPath,
            initialSelection: initialSelection,
            selection: initialSelection,
            isOpen: false
          }));
        }
      } catch (error) {
        console.error('Error restoring state:', error);
        if (mounted) {
          setState(prev => ({
            ...prev,
            path: [],
            selection: {
              buildingId: null,
              levelId: null,
              areaId: null
            },
            pendingSelection: {
              buildingId: null,
              levelId: null,
              areaId: null
            }
          }));
        }
      }
    };
  
    restoreState();
  
    return () => {
      mounted = false;
    };
  }, [building_id, level_id, area_id, spaces]);
  
  // useEffect(() => {
  //   if (!isLoading && spaces.length > 0 && !building_id && !level_id && !area_id) {
  //     // Set the first available space as default if none is selected
  //     // const firstBuilding = spaces[0];
  //     // const firstLevel = firstBuilding.buildings[0];
  //     // const firstArea = firstLevel.levels[0];

  //     // setValue('building_id', firstBuilding.building_id);
  //     // setValue('level_id', firstLevel.level_id);
  //     // setValue('area_id', firstArea.area_id);
  //     // onSelect && onSelect(firstBuilding.building_id, firstLevel.level_id, firstArea.area_id);
  //   }
  // }, [isLoading, spaces, building_id, level_id, area_id, setValue, onSelect]);


  const filteredSpaces = useMemo(() => {
    if (!state.search || state.search.length < 2 || !Array.isArray(spaces)) {
      return [];
    }

    const searchLower = state.search.toLowerCase().trim();
    const MAX_RESULTS = 50;
    const results: { node: TreeNode; path: string[] }[] = [];

    const searchNodes = (nodes: TreeNode[], parentPath: string[] = []) => {
      for (const node of nodes) {
        if (results.length >= MAX_RESULTS) break;

        try {
          const currentPath = [...parentPath, node.title];
          const matchesSearch = node.title?.toLowerCase().includes(searchLower);

          if (matchesSearch) {
            results.push({
              node,
              path: currentPath
            });
          }

          if ('children' in node && Array.isArray(node.children) && node.children.length > 0) {
            searchNodes(node.children, currentPath);
          }
        } catch (error) {
          console.error('Error processing node in search:', error);
          continue;
        }
      }
    };

    try {
      searchNodes(spaces);
      return results;
    } catch (error) {
      console.error('Error in filteredSpaces:', error);
      return [];
    }
  }, [spaces, state.search]);
  // const resetSelection = useCallback(() => {
  //   setPath([]);
  //   setValue('building_id', null);
  //   setValue('level_id', null);
  //   setValue('area_id', null);
  //   onSelect && onSelect(null, null, null);
  //   setOpen(false);
  // }, [setValue, onSelect]);

  const resetSelection = useCallback(() => {
    console.debug('resetSelection:', state);
    setState(prev => ({
      ...prev,
      path: [],
      isOpen: false,
      selection: {
        buildingId: null,
        levelId: null,
        areaId: null
      }
    }));
  }, []);

  const goToLevel = useCallback((index: number) => {
    setState(prev => {
      const newPath = prev.path.slice(0, index);
      const lastNode = newPath[newPath.length - 1];

      return {
        ...prev,
        path: newPath,
        selection: {
          buildingId: lastNode?.building_id ?? null,
          levelId: lastNode && 'level_id' in lastNode ? lastNode.level_id : null,
          areaId: lastNode &&  'area_id' in lastNode ? lastNode.area_id : null
        }
      };
    });
  }, []);

  const handleSearch = useCallback((value: string) => {
    setState(prev => ({
      ...prev,
      search: value
    }));
  }, []);

  const handleSelect = useCallback((node: TreeNode | null) => {

    console.debug('handleSelect:', {node, state});

    const currentDepth = state.path.length;

    if (!node) {

      const newSelection = {
        buildingId: currentDepth >= 1 ? state.pendingSelection.buildingId : null,
        levelId: currentDepth >= 2 ? state.pendingSelection.levelId : null,
        areaId: null
      };

      setState(prev => ({
        ...prev,
        path: [],
        pendingSelection: newSelection,
        isOpen: false,
        selection: newSelection
      }));
      return;
    }

    const newPath = [...state.path, node];

    const newPendingSelection = {
      buildingId: node.building_id,
      levelId: 'level_id' in node ? node.level_id : null,
      areaId: 'area_id' in node ? node.area_id : null
    };

    setState(prev => ({
      ...prev,
      path: newPath,
      isOpen: !('area_id' in node),
      pendingSelection: newPendingSelection,
      // Only update actual selection if area is selected or popover is closing
      selection: 'area_id' in node ? newPendingSelection : prev.selection
    }));

  }, [state.path]);

  const renderSearchResults = () => {
    const groupedResults = filteredSpaces.reduce((acc, { node, path }) => {
      const buildingName = path[0];
      if (!acc[buildingName]) {
        acc[buildingName] = [];
      }
      acc[buildingName].push({ node, path });
      return acc;
    }, {} as Record<string, { node: TreeNode; path: string[] }[]>);

    return Object.entries(groupedResults).map(([buildingName, results]) => (
      <CommandGroup key={buildingName} heading={buildingName}>
        {results.map(({ node, path }) => (
          <CommandItem
            key={node.id}
            onSelect={() => handleSelect(node)}
          >
            <span className="flex items-center space-x-1">
              {path.slice(1).map((title, index) => (
                <span key={index} className="flex items-center">
                  {index > 0 && <ChevronRight className="h-3 w-3 mx-1 text-muted-foreground" />}
                  <span>{title}</span>
                </span>
              ))}
            </span>
          </CommandItem>
        ))}
      </CommandGroup>
    ));
  };

  const renderContent = useCallback(() => {
    if (!spaces || !Array.isArray(spaces)) {
      return <CommandEmpty>No spaces available</CommandEmpty>;
    }

    if (state.search) {
      return renderSearchResults();
    }

    try {
      const currentLevel = state.path.length;
      let currentNodes: TreeNode[] = [];
      let nextLevelKey: string;
      let noSelectionText: string;

      switch (currentLevel) {
        case 0:
          currentNodes = spaces;
          nextLevelKey = 'Building';
          noSelectionText = 'No Building';
          break;
        case 1:
          currentNodes = state.path[0]?.children || [];
          nextLevelKey = 'Level';
          noSelectionText = 'No Level';
          break;
        case 2:
          currentNodes = state.path[1]?.children || [];
          nextLevelKey = 'Area';
          noSelectionText = 'No Area';
          break;
        default:
          return <CommandEmpty>Invalid navigation level</CommandEmpty>;
      }

      if (!currentNodes.length) {
        return <CommandEmpty>No {nextLevelKey.toLowerCase()}s available</CommandEmpty>;
      }

      return (
        <CommandGroup heading={`Select ${nextLevelKey}`}>
          <CommandItem
            onSelect={() => handleSelect(null)}
            className={noSelectionClass}
            data-testid={`no-selection-${nextLevelKey.toLowerCase()}`}
          >
            ({noSelectionText})
          </CommandItem>
          {currentNodes.map((node) => (
            <CommandItem
              key={node.id}
              onSelect={() => handleSelect(node)}
              className="flex justify-between items-center"
              data-testid={`node-${node.id}`}
            >
              <span className="truncate">{node.title}</span>
              {isNodeWithChildren(node) && node.children.length > 0 && (
                <ChevronRight className="h-4 w-4 flex-shrink-0 opacity-50" />
              )}
            </CommandItem>
          ))}
        </CommandGroup>
      );
    } catch (error) {
      console.error('Error rendering content:', error);
      return <CommandEmpty>An error occurred while rendering content</CommandEmpty>;
    }
  }, [spaces, state.search, state.path, handleSelect, renderSearchResults]);

const handleOpen = useCallback((newOpen: boolean) => {
  console.debug('handleOpen:', newOpen, state);
  setState(prev => ({
    ...prev,
    isOpen: newOpen,
    search: newOpen ? prev.search : '',
    previousPath: newOpen ? prev.path : prev.previousPath,
    path: newOpen ? [] : prev.previousPath,
    // Update selection when closing the popover
    selection: !newOpen ? prev.pendingSelection : prev.selection,
    // Reset pending selection when opening
    pendingSelection: newOpen ? prev.selection : prev.pendingSelection
  }));
}, []);

  const renderFixedHeader = useCallback(() => {
    if (state.search || !state.path.length) return null;

    return (
      <div
        className="sticky top-0 bg-white dark:bg-gray-800 p-2 border-b flex items-center justify-between text-sm text-muted-foreground"
        role="navigation"
        aria-label="Navigation breadcrumb"
      >
        <div className="flex items-center space-x-1 overflow-x-auto">
          {state.path.map((node, index) => (
            <span
              key={`path-${node.id}-${index}`}
              className="flex items-center min-w-0"
            >
              {index > 0 && (
                <ChevronRight
                  className="h-4 w-4 mx-1 flex-shrink-0 text-muted-foreground"
                  aria-hidden="true"
                />
              )}
              <button
                onClick={() => goToLevel(index)}
                className="hover:underline focus:outline-none focus:ring-2 focus:ring-offset-2 rounded px-1 truncate"
                aria-label={`Go to ${node.title} level`}
              >
                {node.title}
              </button>
            </span>
          ))}
        </div>
        <Button
          variant="ghost"
          size="icon"
          onClick={resetSelection}
          className="h-6 w-6 flex-shrink-0"
          aria-label="Reset selection"
        >
          <X className="h-4 w-4" />
        </Button>
      </div>
    );
  }, [state.search, state.path, goToLevel, resetSelection]);

  const getDisplayValue = useMemo(() => {

    console.debug('getDisplayValue:', {isLoading, state});

    if (isLoading) return "Loading...";
    if (!state.path.length) return "(No Building)";

    try {
      return state.path.map((node, index) => {
        // Type guard for better type safety
        const isLastNode = index === state.path.length - 1;

        if (index === 0 && !state.selection.levelId) {
          return `${node.title} > (No Level)`;
        }

        if (index === 1 && !state.selection.areaId) {
          return `${node.title} > (No Area)`;
        }

        // Truncate long titles
        const title = node.title.length > 30
          ? `${node.title.slice(0, 27)}...`
          : node.title;

        return isLastNode ? title : title;
      }).join(' > ');
    } catch (error) {
      console.error('Error generating display value:', error);
      return "(Error)";
    }
  }, [isLoading, state.path, state.selection]);

  return (
    <div className="space-y-2">
      <Popover open={state.isOpen} onOpenChange={handleOpen}>
        <PopoverTrigger asChild>
          <div className="relative w-full">
            <Button
              variant="outline"
              role="combobox"
              aria-expanded={state.isOpen}
              className="w-full justify-between"
              onClick={(e) => {e.preventDefault(); e.stopPropagation(); handleOpen(true);}}
              disabled={isLoading || disabled}
            >
              <span className="truncate">{getDisplayValue}</span>
              {isLoading ? (
                <Loader2 className="h-4 w-4 animate-spin" />
              ) : (
                <ChevronDown className="h-4 w-4 shrink-0 opacity-50" />
              )}
            </Button>
          </div>
        </PopoverTrigger>
        <PopoverContent className="w-[var(--radix-popover-trigger-width)] p-0"
          onOpenAutoFocus={e => e.preventDefault()}
          modal={true}
        >
          <Command>
            {isLoading ? (
              <CommandList>
                <CommandItem>
                  <Loader2 className="h-6 w-6 animate-spin" />
                </CommandItem>
              </CommandList>
            ) : (
              <>
                <CommandInput
                  placeholder="Search spaces..."
                  value={state.search}
                  onValueChange={handleSearch}
                  className="w-full"
                  aria-label="Search spaces"
                />

                {renderFixedHeader()}
                <CommandList>
                  <CommandEmpty>No space found.</CommandEmpty>
                  {renderContent()}
                </CommandList>
              </>
            )}
          </Command>
        </PopoverContent>
      </Popover>
      <div className="flex space-x-2">
        <Controller
          name="building_id"
          control={control}
          defaultValue={null}
          render={({ field }) => (
            <input type="hidden" {...field} value={field.value ?? ''} />
          )}
        />
        <Controller
          name="level_id"
          control={control}
          defaultValue={null}
          render={({ field }) => (
            <input type="hidden" {...field} value={field.value ?? ''} />
          )}
        />
        <Controller
          name="area_id"
          control={control}
          defaultValue={null}
          render={({ field }) => (
            <input type="hidden" {...field} value={field.value ?? ''} />
          )}
        />
      </div>
    </div>
  );
}

