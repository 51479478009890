import {
  addUpload,
  clearFormUploads,
  removeUpload,
  RootState,
  selectHasActiveUploads,
  selectUploadsByFormId,
  toggleUploadDelete,
  useAppDispatch,
  useAppSelector,
} from '@app.raytd.com/store';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { v4 as uuid } from 'uuid';
import { UploadItem } from './types';

// Helper functions for UploadItem
export const createUploadItem = (
  file: File,
  formId: string,
  testItemId?: number
): UploadItem => ({
  id: uuid(),
  fileName: file.name,
  size: file.size,
  progress: 0,
  status: 'idle',
  formId,
  testItemId,
  createdAt: (new Date()).toISOString(),
});

export const useUpload = (formId: string, collection = 'images') => {
  const dispatch = useAppDispatch();
  const uploads = useAppSelector((state: RootState) =>
    selectUploadsByFormId(state, formId)
  );
  const hasActiveUploads = useSelector(selectHasActiveUploads);

  const handleUpload = useCallback(
    (file: File) => {
      const uploadItem = createUploadItem(file, formId);
      dispatch(addUpload(uploadItem));
      dispatch({
        type: 'uploads/initiateUpload',
        payload: {
          id: uploadItem.id,
          file,
          formId,
        },
      });
      return uploadItem.id;
    },
    [dispatch, formId]
  );

  const removeUploadHandler = useCallback(
    (uploadId: string) => {
      dispatch(removeUpload(uploadId));
    },
    [dispatch]
  );

  const toggleDeleteUploadHandler = useCallback(
    (uploadId: string) => {
      const upload = uploads.find((u) => u.id === uploadId);

      if (upload?.mediaInfo) {
        dispatch(toggleUploadDelete({ id: uploadId }));
      }
    },
    [uploads, dispatch]
  );

  const clearUploads = useCallback(() => {
    dispatch(clearFormUploads(formId));
  }, [dispatch, formId]);

  return {
    uploads,
    hasActiveUploads,
    handleUpload,
    removeUpload: removeUploadHandler,
    toggleDeleteUpload: toggleDeleteUploadHandler,
    clearUploads,
    totalProgress:
      uploads.reduce((acc, upload) => acc + upload.progress, 0) /
      uploads.length,
  };
};
