import { FastFillText } from "@/app/features/reports/components/fast-fill-text"
import { PhotographEditor } from "@/app/features/reports/components/photograph-editor"
import { TestSuites } from "@/app/features/reports/types/test-specification"
import convertFormToTestItem from "@/app/features/reports/utils/convertFormToTestItem"
import convertInspectionToFormValues from "@/app/features/reports/utils/convertInspectionToFormValues"
import RaytdResultPicker from "@/components/raytd/raytd-result-picker"
import AuthorBadge from "@/components/raytd/user-avatar"
import { Badge } from "@/components/ui/badge"
import { Form, FormControl, FormField, FormItem } from "@/components/ui/form"
import { Input } from "@/components/ui/input"
import { useAlertDialog } from "@/hooks/useAlertDialog"
import { getDefaultFieldSettings } from '@/utils/getDefaultFieldSettings'
import { Assessment, AssetEntity, TestSpecificationsEntity, useCreateTestItemMutation, useUpdateTestItemMutation } from "@app.raytd.com/store"
import { AnimatePresence, motion } from "framer-motion"
import { Loader2 } from 'lucide-react'
import { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react"
import { FormProvider, useForm, useFormState, useWatch } from "react-hook-form"
import { CustomField, TestItem, TestSuite, ValidationTypes } from 'store/src/lib/tests/entity'
import { v4 as uuid } from 'uuid'
import { z } from "zod"
import { useInspectionData } from "../hooks/useInspectionData"
import { useSpacesData } from "../hooks/useSpacesData"
import { ImageSchema, type TestFormValues, type TestStatus } from '../types/test-form'
import { AssetDisplay } from "./asset-display"
import { CustomFieldsContainer } from "./custom-fields-container"
import { ElementSelector } from "./element-selector"
import { FormLabelWithIndicator } from './form-label-with-indicator'
import TestSpecificationSelector from "./hierarchical-test-select"
import { InspectionSelector } from "./inspection-selector"
import { SpaceSelector } from "./space-selector"
import { StatusIndicator } from "./status-indicator"
import { useAlert } from "@/app/contexts/AlertContext"
import { FileUploader } from "@/components/image-upload/FileUploader"
import { MediaInfo } from "@/components/image-upload/types"

const defaultValues: TestFormValues = {
  status: "draft",
  assetId: undefined,
  test_specification_id: undefined,
  assessmentId: undefined,
  element: undefined,
  element_name: undefined,
  element_id: undefined,
  observations: "",
  recommendations: "",
  images: [],
  result: undefined,
  custom: {}
};

const formatDateAndTime = (date: string | Date, locale: string = 'en-AU') => {

  if (!date) return null;

  return new Intl.DateTimeFormat(locale, {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    hour12: true,
  }).format(new Date(date));
};


const createBasicFieldSchema = (field: CustomField, type: ValidationTypes): z.ZodTypeAny => {
  switch (type) {
    case 'boolean':
      return field.required ? z.boolean() : z.boolean().nullable().optional();
    case 'numeric':
      return field.required
        ? z.coerce.number().min(1, `${field.label} is required`)
        : z.coerce.number().nullable().optional();
    default:
      return field.required
        ? z.coerce.string().min(1, `${field.label} is required`)
        : z.coerce.string().nullable().optional();
  }
};

const createArrayFieldSchema = (field: CustomField) => {
  return field.required
    ? z.array(z.string().min(1, `${field.label} is required`))
      .min(1, `${field.label} must have at least one item`)
    : z.array(z.string()).nullable().optional();
};

const createCustomFieldSchema = (field: CustomField): z.ZodTypeAny => {
  if (!field.active) return z.never();

  return field.selection_type === 'multiple'
    ? createArrayFieldSchema(field)
    : createBasicFieldSchema(field, (field.validations || 'string') as ValidationTypes);
};

const buildCustomFieldValidation = (customFields: CustomField[]) => {
  const schemas = customFields.reduce<Record<string, z.ZodTypeAny>>((acc, field) => {
    if (field.active !== false) {
      acc[field.field_id] = createCustomFieldSchema(field);
    }
    return acc;
  }, {});

  return z.object(schemas);
};

const createDynamicResolver = (testSuites: TestSuites[]) => {
  return async (data: TestFormValues, context: any, options: any) => {
    // Find the current test suite based on test_specification_id

    console.debug('Validation data', data);
    let currentTestSuite: TestSuite | undefined;
    for (const suite of testSuites) {
      const specification = suite.specifications.find(
        spec => spec.id === data.test_specification_id
      );
      if (specification) {
        currentTestSuite = suite as unknown as TestSuite;
        break;
      }
    }

    // Base validation schema (always required fields)
    const baseValidation = z.object({
      assessmentId: z.number().min(1, "Inspection is required"),
      assetId: z.number().min(1, "Asset is required"),
      test_specification_id: z.number().min(1, "Test is required"),
      status: z.enum(["archive", "draft", "qa", "published"]),
      images: z.array(ImageSchema).optional()
    });

    // Dynamic validation based on test suite settings
    const dynamicValidation: Record<string, any> = {};

    const fields = [
      'element',
      'element_name',
      'element_id',
      'observations',
      'recommendations',
      'images',
      'result',
      'location'
    ];



    fields?.forEach(fieldName => {

      const defaultSettings = getDefaultFieldSettings(fieldName, currentTestSuite?.type);
      const settingOverride = currentTestSuite?.settings.find(s => s.field_name === fieldName);

      const isActive = settingOverride?.active ?? defaultSettings.active;
      const isRequired = settingOverride?.required ?? defaultSettings.required;

      // console.debug('validation Processing setting:', {
      //   fieldName,
      //   active: isActive,
      //   required: isRequired
      // });

      if (isActive) { // Field is active
        let field = fieldName;
        const fieldSchema = (() => {
          switch (fieldName) {
            case 'element':
              return isRequired
                ? z.number().min(1, `${fieldName} is required`)
                : z.number().nullable().optional();
            case 'element_name':
            case 'element_id':
            case 'observations':
            case 'recommendations':
              return isRequired
                ? z.string().min(1, `${fieldName} is required`)
                : z.string().nullable().optional();
            case 'images':
              return isRequired
                ? z.array(ImageSchema).min(1, "At least one image is required")
                : z.array(ImageSchema).nullable().optional();
            case 'rating':
              fieldName = 'result';
              return isRequired
                ? z.enum(["very_poor", "poor", "fair", "good", "very_good"])
                : z.enum(["very_poor", "poor", "fair", "good", "very_good"]).nullable().optional();

            case 'location':
              // Add all location-related fields
              if (isRequired) {
                dynamicValidation['building_id'] = z.number().min(1, "Building is required");
                dynamicValidation['level_id'] = z.number().nullable().optional();
                dynamicValidation['area_id'] = z.number().nullable().optional();
              }

              return; // Skip adding location itself

            // Add more field types as needed
            default:
              return z.string().nullable().optional();
          }
        })();
        if (fieldSchema) {
          dynamicValidation[fieldName] = fieldSchema;
        }
      }
    });

    const customFieldValuesSchema = buildCustomFieldValidation(currentTestSuite?.custom_fields ?? []);

    // Add the custom field values schema to the dynamic validation
    dynamicValidation['custom'] = customFieldValuesSchema;


    // Additional validation for final status
    if (data.status === 'published') {
      // Add stricter validation for final status
      // if (dynamicValidation.result &&) {
      //   dynamicValidation.result = z.enum(["very_poor", "poor", "fair", "good", "very_good"]);
      // }
      // if (dynamicValidation.recommendations) {
      //   dynamicValidation.recommendations = z.string().min(1, "Recommendations required for final status");
      // }
    }

    // Combine base and dynamic validation
    const fullSchema = baseValidation.extend(dynamicValidation);

    // Perform validation
    try {
      const validatedData = await fullSchema.parseAsync(data);
      //   console.debug('Validation passed', validatedData);
      return {
        values: validatedData,
        errors: {}
      };
    } catch (error) {
      console.debug('Validation failed', error, data);
      if (error instanceof z.ZodError) {
        return {
          values: {},
          errors: error.formErrors.fieldErrors
        };
      }
      return {
        values: {},
        errors: { _form: ['Validation failed'] }
      };
    }
  };
};

interface TestEditFormProps {
  inspections: Assessment[],
  testItem: TestItem,
  assets: AssetEntity[],
  testSuites: TestSuites[],
  onSubmit: (data: TestFormValues) => void
  onDirtyChange?: (isDirty: boolean) => void
  initialData?: Partial<TestFormValues>
  saveStatus?: string
}

interface TestEditFormRef {
  resetForm: (initialData?: Partial<TestFormValues & { formId: string }>) => void
}

const TestEditForm = forwardRef<TestEditFormRef, TestEditFormProps>(({
  inspections,
  assets,
  testSuites,
  testItem,
  onSubmit,
  onDirtyChange,
  initialData,
  saveStatus
}, ref) => {
  const [testState, setTestState] = useState({
    selectedTestSpecificationId: null as number | null,
    isTestSelected: false
  });

  // const { AlertDialogComponent, showAlertDialog } = useAlertDialog();
  const { showAlertDialog } = useAlert();

  const formId = useRef<string>(undefined);

  const [isFormValid, setIsFormValid] = useState(false);
  const [isFormDirty, setIsFormDirty] = useState(false);
  const [areRequiredFieldsFilled, setAreRequiredFieldsFilled] = useState(false);
  const formRef = useRef<HTMLFormElement>(null);
  const [isExistingTest, setIsExistingTest] = useState(false);

  const [createTest, { isLoading: isCreatingTest }] = useCreateTestItemMutation();
  const [updateTest, { isLoading: isUpdating }] = useUpdateTestItemMutation();

  const methods = useForm<TestFormValues>({
    resolver: createDynamicResolver(testSuites),
    defaultValues,
    mode: "onChange",
  });

  // const asset_id = methods.watch("assetId");
  // console.debug('asset_id', asset_id);

  const asset_id = useWatch({
    control: methods.control,
    name: 'assetId', 
  });

  const { groupedInspections } = useInspectionData(inspections as any, assets);
  const { spaces: organizedSpaces, isLoading: isSpacesLoading } = useSpacesData(asset_id);

  const isLoadingTestSuiteData = false;

  const selectedTestSuite = useMemo(() => {

    if (!testSuites) return undefined;

    console.debug('selectedTestSpecificationId', testState.selectedTestSpecificationId);

    // return testSuites?.find(t => t.id === testState.selectedTestSpecificationId);
    for (const suite of testSuites) {
      const specification = suite.specifications.find(spec => spec.id === testState.selectedTestSpecificationId);
      if (specification) {
        return { suite: suite, specification: specification } as unknown as { suite: TestSuite, specification: TestSpecificationsEntity };
      }
    }
    return undefined;
  }, [testSuites, testState.selectedTestSpecificationId]);

  const assessmentId = useWatch({
    control: methods.control,
    name: "assessmentId",
  });

  const selectedAssessment = useMemo(() => {
    return inspections.find(i => i.id === assessmentId);
  }, [inspections, assessmentId]);

  const elementGroups = useMemo(() => {
    //N ot available until selectedTestSuite is available
    console.debug('elementGroups not available', selectedTestSuite, selectedAssessment);
    if (!selectedTestSuite || !selectedAssessment) return [];

    console.debug('elementGroups', selectedTestSuite.suite.type, selectedAssessment.elementGroups, selectedTestSuite.suite.type);
    return selectedAssessment.elementGroups?.filter(group => {
      return group.test_suite_types.includes(selectedTestSuite.suite.type);
    }) ?? [];

  }, [selectedTestSuite, selectedAssessment]);

  const resetForm = useCallback((initialData: Partial<TestFormValues & { formId: string; }> = {}) => {
    console.debug('Resetting form', initialData);
    methods.reset({
      ...defaultValues,
      ...initialData
    });
    setTestState({
      selectedTestSpecificationId: initialData?.test_specification_id ? Number(initialData?.test_specification_id) : null,
      isTestSelected: !!initialData?.test_specification_id
    });

    formId.current = initialData?.formId ?? uuid();

  }, [methods]);

  const handleSave = useCallback(async (data: TestFormValues) => {

    const saveValues = {
      ...convertFormToTestItem(data),
      assessmentTestId: testItem?.assessmentTestId ?? undefined,
      formId: formId.current
    }
    console.debug('Saving:', data.status, { formId, draftData: data, saveValues });

    try {

      let result = undefined;

      if (isExistingTest) {

        result = await updateTest(saveValues).unwrap();
        console.debug('Test updated', result);

      } else {
        result = await createTest(saveValues).unwrap();
        console.debug('Test created', result);

      }
      resetForm(convertInspectionToFormValues(result));
      onSubmit?.(result);
    } catch (error) {
      
      console.debug('Error saving test', error);
      showAlertDialog({
        title: 'Error saving test',
        description: 'An error occurred while saving the test. Please try again later.',
        confirmText: 'OK',
        cancelText: undefined
      });
    }
  }, [methods, testItem, isExistingTest, createTest, resetForm, onSubmit, showAlertDialog]);

  const handleStatusChange = useCallback((status: TestStatus) => {
    methods.setValue("status", status);

    if (status === 'published' || status === 'qa') {
      methods.handleSubmit(handleSave)();
    } else {
      const data = methods.getValues();
      handleSave(data);
    }

  }, [methods, handleSave]);

  const handleTestSelect = useCallback((value: number | null) => {
    setTestState(prevState => ({
      ...prevState,
      selectedTestSpecificationId: value,
      isTestSelected: !!value
    }));
    if (value) {
      methods.setValue("test_specification_id", value);
    } else {
      methods.setValue("test_specification_id", undefined);
    }
  }, [methods]);

  useImperativeHandle(ref, () => ({
    resetForm: resetForm
  }), [resetForm]);

  // useEffect(() => {
  //   console.debug('test state', testState);
  // }, [testState]);

  useEffect(() => {
    onDirtyChange?.(isFormDirty);
  }, [isFormDirty, onDirtyChange]);

  useEffect(() => {
    if (testItem) {

      const initialData = convertInspectionToFormValues(testItem);
      resetForm(initialData);

    } else {
      resetForm();
    }

    setIsExistingTest(!!testItem?.assessmentTestId);

    console.debug('Form reset', { testItem, initialData });
    methods.trigger();

  }, [testItem, methods, resetForm]);

  // useEffect(() => {
  //   const subscription = methods.watch((value, { name, type }) => {
  //     const formData = methods.getValues();
  //     const errors = methods.formState.errors;
  //     const isValid = methods.formState.isValid;
  //     const requiredFieldsFilled = !!formData.assessmentId && !!formData.test_specification_id && !!formData.assetId;

  //     setIsFormValid(isValid);
  //     setAreRequiredFieldsFilled(requiredFieldsFilled);

  //     console.debug('Are reqd fields filled', { isValid, requiredFieldsFilled, errors });
  //   });
  //   return () => subscription.unsubscribe();
  // }, [methods]);

  const formState = useFormState({
    control: methods.control
  });

  useEffect(() => {
    const { errors, isValid, dirtyFields, touchedFields, isDirty } = formState;
    const values = methods.getValues();

    const requiredFieldsFilled = !!values.assessmentId &&
      !!values.test_specification_id &&
      !!values.assetId;

    console.debug('Are reqd fields filled', { isValid, requiredFieldsFilled, errors, isDirty });

    setIsFormValid(isValid);
    setIsFormDirty(isDirty);
    setAreRequiredFieldsFilled(requiredFieldsFilled);

  }, [formState, methods]);

  const getFieldVisibility = useCallback((fieldName: string) => {
    if (!selectedTestSuite || !selectedTestSuite.suite.settings) return true;
    const defaultSettings = getDefaultFieldSettings(fieldName, selectedTestSuite?.suite?.type);
    const field = selectedTestSuite.suite.settings.find(s => s.field_name === fieldName);
    // return field ? (field.active ?? true) : true;
    return field ? field.active : defaultSettings.active;
  }, [selectedTestSuite]);

  const getFieldRequirement = useCallback((fieldName: string) => {
    if (!selectedTestSuite || !selectedTestSuite.suite.settings) return false;
    const defaultSettings = getDefaultFieldSettings(fieldName, selectedTestSuite?.suite?.type);
    const field = selectedTestSuite.suite.settings.find(s => s.field_name === fieldName);
    //    return field ? field.required : false;
    return field ? field.required : defaultSettings.required
  }, [selectedTestSuite]);

  // const handleOpenChange = useCallback((newOpen: boolean) => {
  //   if (!newOpen) {

  //     if (isFormDirty) {
  //       showAlertDialog({
  //         title: 'Unsaved Changes',
  //         description: 'You have unsaved changes. Are you sure you want to leave?',
  //         confirmText: 'Leave',
  //         cancelText: 'Stay',
  //       }).then((confirmed) => {
  //         if (confirmed) {
  //           onClose();
  //           resetForm();
  //         }
  //       });
  //     } else {
  //       onClose();
  //       resetForm();
  //     }

  //   }
  // }, [onClose, resetForm, showAlertDialog, isFormDirty]);

  return (
    (
      <>
        <FormProvider {...methods}>
          <Form {...methods}>
            <form ref={formRef} className="flex flex-col h-full" onSubmit={methods.handleSubmit(onSubmit)}>
              <div className="pb-0 flex flex-row justify-between items-center pt-6 px-6">
                <div className="text-2xl font-semibold leading-none tracking-tight">
                  {isExistingTest ? `Test (${testItem?.status})` : 'New Test '}
                </div>
                {isFormDirty && (<Badge className="ml-2" variant="secondary">Modified</Badge>)}
                {/* <Button onClick={handleNewTest}>New Test</Button> */}
                <StatusIndicator
                  currentStatus={methods.watch("status")}
                  onStatusChange={handleStatusChange}
                  saveStatus={saveStatus}
                  isFormValid={isFormValid}
                  areRequiredFieldsFilled={areRequiredFieldsFilled} />
              </div>
              {testItem?.assessmentTestId && (
                <div className="border-b border-zinc-200 py-4 flex flex-row justify-between gap-2 px-6 text-zinc-600">
                  <div className="flex flex-row gap-4 items-center text-xs">
                    {/* <UserRoundIcon className="h-4 w-4 text-zinc-500" /> */}
                    {/* {testItem.creator?.name}  */}

                    <AuthorBadge item={testItem.creator} />
                    <div className="text-zinc-500 flex flex-col"><span className="text-zinc-300">Created by&nbsp;</span><span className="font-medium">{formatDateAndTime(testItem.updated_at)}</span></div>
                    {/* <span className="text-zinc-500"> {formatDateAndTime(testItem.created_at)}</span> */}
                  </div>
                  {testItem.created_at !== testItem.updated_at && (
                    <div className="flex flex-row gap-4 items-center text-xs">
                      {/* <UserRoundPenIcon className="h-4 w-4 text-zinc-500" />  {testItem.updater?.name} */}
                      <AuthorBadge item={testItem.updater} />
                      <div className="text-zinc-500 flex flex-col"><span className="text-zinc-300">Last updated</span><span className="font-medium">{formatDateAndTime(testItem.updated_at)}</span></div>
                    </div>
                  )}

                </div>
              )}


              <div className="flex-grow overflow-y-auto p-6 space-y-6">
                <InspectionSelector
                  name="assessmentId"
                  label="Inspection"
                  required={true}
                  inspections={groupedInspections}
                  editable={!isExistingTest} />

                <AssetDisplay
                  name="assetId"
                  label="Asset"
                  required={true}
                  inspections={groupedInspections} />

                <TestSpecificationSelector
                  name="test_specification_id"
                  label="Test"
                  testSuites={testSuites}
                  onSelect={handleTestSelect}
                  labelComponent={<FormLabelWithIndicator label="Test" isValid={!!methods.watch("test_specification_id")} required={true} />} />

                {isLoadingTestSuiteData && (
                  <div className="flex justify-center items-center py-4">
                    <Loader2 className="h-6 w-6 animate-spin" />
                  </div>
                )}

                <AnimatePresence>
                  {selectedTestSuite && areRequiredFieldsFilled && !isLoadingTestSuiteData && (
                    <motion.div
                      initial={{ opacity: 0, height: 0 }}
                      animate={{ opacity: 1, height: "auto" }}
                      exit={{ opacity: 0, height: 0 }}
                      transition={{ duration: .5 }}
                      className="space-y-6"
                    >
                      {/* Render form fields based on visibility and requirement */}
                      {getFieldVisibility('location') && (
                        <FormField
                          control={methods.control}
                          name="building_id"
                          render={({ field }) => (
                            <FormItem>
                              <FormLabelWithIndicator label="Location" isValid={!!field.value} required={getFieldRequirement('location')} />
                              <FormControl>
                                <SpaceSelector
                                  spaces={organizedSpaces as any}
                                  disabled={!!!asset_id}
                                  // onSelect={(building_id, level_id, area_id) => {
                                  //   console.debug('onSelect', { building_id, level_id, area_id });
                                  //   //field.onChange(`${building_id}|${level_id}|${area_id}`);
                                  // }}
                                  isLoading={false} />
                              </FormControl>
                            </FormItem>
                          )} />
                      )}

                      {getFieldVisibility('element') && (
                        <FormField
                          control={methods.control}
                          name="element"
                          render={({ field }) => (
                            <FormItem>
                              <FormLabelWithIndicator label="Element" isValid={!!field.value} required={getFieldRequirement('element')} />
                              <FormControl>
                                <ElementSelector
                                  name="element"
                                  elements={elementGroups}
                                  onSelect={(value) => field.onChange(value)}
                                  isLoading={false} />
                              </FormControl>
                            </FormItem>
                          )} />
                      )}

                      {getFieldVisibility('element_name') && (
                        <FormField
                          control={methods.control}
                          name="element_name"
                          render={({ field }) => (
                            <FormItem>
                              <FormLabelWithIndicator label="Element Name" isValid={!!field.value} required={getFieldRequirement('element_name')} />
                              <FormControl>
                                <Input {...field} placeholder="Enter element name" />
                              </FormControl>
                            </FormItem>
                          )} />
                      )}

                      {getFieldVisibility('element_id') && (
                        <FormField
                          control={methods.control}
                          name="element_id"
                          render={({ field }) => (
                            <FormItem>
                              <FormLabelWithIndicator label="Element ID" isValid={!!field.value} required={getFieldRequirement('element_id')} />
                              <FormControl>
                                <Input {...field} placeholder="Enter element ID" />
                              </FormControl>
                            </FormItem>
                          )} />
                      )}

                      {getFieldVisibility('rating') && (

                        <FormField
                          control={methods.control}
                          name="result"
                          render={({ field }) => (
                            <FormItem>
                              <FormLabelWithIndicator label="Result" isValid={!!field.value} required={getFieldRequirement('rating')} />
                              <RaytdResultPicker
                                name="result"
                                onChange={(value) => field.onChange(value)}
                                onBlur={field.onBlur}
                                value={field.value}
                                testSuite={selectedTestSuite.suite} />
                            </FormItem>
                          )} />

                      )}

                      {getFieldVisibility('observations') && (
                        <FormField
                          control={methods.control}
                          name="observations"
                          render={({ field }) => (
                            <FormItem>
                              <FormLabelWithIndicator label="Observations" isValid={!!field.value} required={getFieldRequirement('observations')} />
                              <FormControl>

                                <FastFillText
                                  control={methods.control}
                                  name="observations"
                                  fastFills={selectedTestSuite?.specification?.observations ?? []} />

                                {/* <Textarea
                                  placeholder="Enter observations"
                                  {...field}
                                /> */}
                              </FormControl>
                            </FormItem>
                          )} />
                      )}

                      {getFieldVisibility('recommendations') && (
                        <FormField
                          control={methods.control}
                          name="recommendations"
                          render={({ field }) => (
                            <FormItem>
                              <FormLabelWithIndicator label="Recommendations" isValid={!!field.value} required={getFieldRequirement('recommendations')} />
                              <FormControl>
                                <FastFillText
                                  control={methods.control}
                                  name="recommendations"
                                  fastFills={selectedTestSuite?.specification?.recommendations ?? []} />
                              </FormControl>
                            </FormItem>
                          )} />

                      )}

                      {getFieldVisibility('photographs') && (
                        // <SelectField
                        //   name="photographs"
                        //   label="Photographs"
                        //   control={methods.control}
                        //   options={[
                        //     { value: "option1", label: "Option 1" },
                        //     { value: "option2", label: "Option 2" }
                        //   ]}
                        //   labelComponent={<FormLabelWithIndicator label="Photographs" isValid={!!methods.watch("photographs")} required={getFieldRequirement('photographs')} />}
                        //   required={getFieldRequirement('photographs')}
                        // />
                        // (<PhotographEditor name="images" />)
                        <FormField
                        control={methods.control}
                        name="images"
                        render={({ field }) => (
                            <FormItem>
                                <FormLabelWithIndicator label="Photographs" isValid={!!methods.watch("images")} required={getFieldRequirement('photographs')} />
                                <FormControl>
    
                                    <FileUploader
                                        formId={formId.current}
                                        value={field.value.filter((img): img is MediaInfo => 'id' in img)}
                                        // onChange={field.onChange}
                                        onChange={(updatedMedia) => {
                                            const currentFiles = field.value.filter(img => img instanceof File);
                                            field.onChange([...updatedMedia, ...currentFiles]);
                                        }}
                                        maxFiles={50}
                                        maxSize={20000000}
                                        acceptedTypes={["image/jpeg", "image/jpg", "image/png"]}
                                        disabled={methods.formState.isSubmitting}
                                    />
                                </FormControl>
                            </FormItem>
                        )}
                    />
                      )}



                      {testState.selectedTestSpecificationId && (
                        <CustomFieldsContainer
                          name="custom"
                          testSuite={selectedTestSuite.suite} />
                      )}
                    </motion.div>
                  )}
                </AnimatePresence>
              </div>

            </form>
          </Form>
        </FormProvider>
      </>
    )
  );
});

export { TestEditForm }

