import { useAlert } from "@/app/contexts/AlertContext"
import { TestSuites } from "@/app/features/reports/types/test-specification"
import { Dialog, DialogContent } from "@/components/ui/dialog"
import { Assessment, AssetEntity } from "@app.raytd.com/store"
import { forwardRef, useCallback, useImperativeHandle, useRef, useState } from "react"
import { TestItem } from 'store/src/lib/tests/entity'
import { TestEditForm } from "./test-edit-form"

type TesstEditDialogProps = {
    isOpen: boolean;
    onClose: () => void;
    testItem: TestItem;
    inspections: Assessment[];
    assets: AssetEntity[];
    testSuites: TestSuites;
    onSubmit: (data: any) => void;
}

const TestEditDialog = forwardRef(({
    isOpen,
    onClose,
    onSubmit,
    testItem,
    inspections,
    assets,
    testSuites
}: TesstEditDialogProps, ref) => {

    const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
    const { showAlertDialog } = useAlert();
    const formRef = useRef<any>(null);


    useImperativeHandle(ref, () => ({
        open: (dialogData) => {
            //   setData(dialogData);
            //   setIsOpen(true);
        },
        // close: () => setIsOpen(false)
    }));

    const handleDirtyChange = useCallback((isDirty: boolean) => {
        setHasUnsavedChanges(isDirty);
    }, []);

    const handleOpenChange = useCallback((newOpen: boolean) => {
        console.debug('Test dialog open change', newOpen);
        if (!newOpen) {

            if (hasUnsavedChanges) {
                showAlertDialog({
                    title: 'Unsaved Changes',
                    description: 'You have unsaved changes. Are you sure you want to leave?',
                    confirmText: 'Leave',
                    cancelText: 'Stay',
                }).then((confirmed) => {
                    if (confirmed) {
                        onClose();
                        formRef.current.reset();
                    }
                });
            } else {
                onClose();
                //resetForm();
                formRef.current.reset();
            }

        }
    }, [onClose, showAlertDialog, hasUnsavedChanges]);

    return (
        <>
            <Dialog open={isOpen} onOpenChange={handleOpenChange}>
                <DialogContent
                    className="max-w-2xl z-50 h-[90vh] flex flex-col py-6 px-2 bg-white"
                    disableOutsideClick
                    onOpenAutoFocus={(event) => event.preventDefault()}
                    onEscapeKeyDown={(event) => event.preventDefault()}
                >
                    <TestEditForm
                        ref={formRef}
                        testItem={testItem}
                        inspections={inspections}
                        assets={assets}
                        testSuites={testSuites as any}
                        // onSubmit={function (data: any): void {
                        //     handleEditorClose();
                        // }}
                        onDirtyChange={handleDirtyChange}
                        onSubmit={onSubmit}
                    />
                </DialogContent>
            </Dialog >
        </>
    )
})

export default TestEditDialog;