import { UploadItem } from "./types";

export const isUploadComplete = (upload: UploadItem): boolean =>
    upload.status === 'completed' && !!upload.mediaInfo;
  
  export const isUploadActive = (upload: UploadItem): boolean =>
    upload.status === 'uploading';
  
  export const getUploadError = (upload: UploadItem): string | undefined =>
    upload.status === 'error' ? upload.error : undefined;
  
  export const formatFileSize = (bytes: number): string => {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(2))} ${sizes[i]}`;
  };