import { useSelector, useDispatch } from 'react-redux';
import { AnimatePresence, motion } from 'framer-motion';
import { removeUpload, RootState, selectActiveUploads, selectUploadStatus } from '@app.raytd.com/store';
import {useEffect} from 'react';
import { cn } from '@/lib/utils';
import { X } from 'lucide-react';

const UploadMonitor = () => {
    const dispatch = useDispatch();
    const activeUploads = useSelector(selectActiveUploads);
  
    if (activeUploads.length === 0) return null;
  
    return (
      <div className="fixed bottom-4 left-4 z-50 flex flex-col gap-2 max-w-44">
        <AnimatePresence>
          {activeUploads.map((upload) => (
            <motion.div
              key={upload.id}
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, x: -100 }}
              className="bg-white rounded-lg shadow-lg p-4 flex items-center gap-3"
            >
              <div className="flex-1">
                <div className="flex justify-between mb-1">
                  <span className="text-sm truncate">
                    {upload.fileName}
                  </span>
                  <span className="text-xs text-zinc-700 w-12 text-right">
                  {upload.progress}%
                </span>
                </div>
                <div className="h-2 bg-gray-200 rounded-full overflow-hidden">
                  <div
                    className={cn(
                      "h-full transition-all duration-300",
                      {
                        'bg-blue-500': upload.status === 'uploading',
                        'bg-red-500': upload.status === 'error',
                        'bg-green-500': upload.status === 'completed'
                      }
                    )}
                    style={{ width: `${upload.progress}%` }}
                  />
                </div>
                {upload.error && (
                  <p className="text-xs text-red-500 mt-1">
                    {upload.error}
                  </p>
                )}
              </div>
              
              {/* Show thumbnail if available */}
              {upload.mediaInfo?.thumbnail_url && (
                <img 
                  src={upload.mediaInfo.thumbnail_url} 
                  alt="Preview"
                  className="w-12 h-12 object-cover rounded"
                />
              )}
              
              <div className="flex items-center gap-2">
                {upload.status === 'uploading' && (
                  <button
                    onClick={() => dispatch(removeUpload(upload.id))}
                    className="text-sm text-gray-500 hover:text-gray-700"
                  >
                    Cancel
                  </button>
                )}
                {upload.status === 'error' && (
                  <button
                    onClick={() => dispatch(removeUpload(upload.id))}
                    className="text-sm text-red-500 hover:text-red-700"
                  >
                      <X className="w-4 h-4" />
                  </button>
                )}
              </div>
            </motion.div>
          ))}
        </AnimatePresence>
      </div>
    );
  };
  // Optional: Create a wrapper component to handle auto-dismissal of completed uploads
const UploadMonitorWrapper = () => {
    const dispatch = useDispatch();
    const completedUploads = useSelector((state: RootState) => 
      Object.entries(state.uploads.uploads).filter(
        ([_, upload]) => upload.status === 'completed'
      )
    );
  
    useEffect(() => {
      const timeouts = completedUploads.map(([id]) => {
        return setTimeout(() => {
          dispatch(removeUpload(id));
        }, 3000);
      });
  
      return () => {
        timeouts.forEach(timeout => clearTimeout(timeout));
      };
    }, [completedUploads, dispatch]);
  
    return <UploadMonitor />;
  };
  
  export default UploadMonitor;