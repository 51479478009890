import UploadMonitor from "../_components/UploadMonitor";
import { ProfileForm } from './TestAvatarUpload';
import { UploadForm } from './UploadForm';
import TestEditDialog from '../features/reports/components/test-edit-dialog';
import { useGetReportQuery, useGetReportTestSuitesQuery, useGetTestItemQuery } from "@app.raytd.com/store";
import { useCallback, useEffect, useState } from "react";
import { skipToken } from "@reduxjs/toolkit/query";
import { TooltipProvider } from "@/components/ui/tooltip";
import { Button } from "@/components/ui/button";

//2432


const TestEditingDialog = () => {

    const { data: report } = useGetReportQuery("13");
    //2432
    const [selectedId, setSelectedId] = useState<number | null>(2165);
    const [isTestEditorOpen, setTestEditorIsOpen] = useState(false);
    const { data: testSuites, isLoading: isLoadingSpecifications } = useGetReportTestSuitesQuery(
        report?.id ? Number(report.id) : skipToken
    );

    const inspections = report?.assessments || []
    const assets = report?.assets || []
    const { data: testItem, refetch } = useGetTestItemQuery(
        selectedId ? selectedId : skipToken
    );

    const [editingTest, setEditingTest] = useState();

    useEffect(() => {
        if (testItem) {
            setEditingTest(testItem);
            setTestEditorIsOpen(true);
        }
    }, [testItem]);

    const handleEditorClose = useCallback(() => {
        setTestEditorIsOpen(false);
        setEditingTest(null);
        //setSelectedId(null);
    }, []);


    if (!report || isLoadingSpecifications || !testSuites || !inspections || !assets) {
        return <div>Loading...</div>
    }

    return (
        <div>
            <TooltipProvider>
                <h1>Test Editing Dialog</h1>
                <Button onClick={() => setSelectedId(2432)}>Open Test Editor</Button>
                <TestEditDialog
                    isOpen={isTestEditorOpen}
                    onClose={handleEditorClose}
                    testItem={editingTest}
                    inspections={inspections}
                    assets={assets}
                    testSuites={testSuites as any}
                    onSubmit={function (data: any): void {
                       // handleEditorClose();
                    }}
                />
            </TooltipProvider>
        </div>
    )

}

export default function Page() {
    return (
        <div className="p-8">
            <TestEditingDialog />
            {/* <ProfileForm />
            <UploadForm formId='8afa46af-8c52-475c-ada9-283b7f7e19d4' /> */}
            <UploadMonitor />
        </div>
    );
}