import React from 'react';
import { Suspense, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Navigate, useLocation } from 'react-router-dom';
import { authActions, selectIsLoggedIn, selectLoggedInUser, selectOnboardingStatus, selectUserOrganisation, useAppDispatch, useAppSelector } from '@app.raytd.com/store';

import LoadingState from '../../components/raytd/loading-state';
import HeaderLogo from '../layout2/HeaderLogo';
import ErrorBoundary from './ErrorBoundary';

const ROUTES = {
  LOGIN: '/login',
  ONBOARDING: '/onboarding',
  INVITATION_ACCEPT: '/invite/',
};

export const useAuth = () => {

  // check if the user is authenticated
  const isAuthenticated = useAppSelector(selectIsLoggedIn);
  const userOrganisation = useAppSelector(selectUserOrganisation);
  const userOnboardingStatus = useAppSelector(selectOnboardingStatus);
  const loggedInUser = useAppSelector(selectLoggedInUser);

  const dispatch = useAppDispatch();

  const refreshUser = useCallback(async () => {
    await dispatch(authActions.getUser());
  }, [dispatch]);

  return { user: loggedInUser, isAuthenticated, userOrganisation, userOnboardingStatus, refreshUser };
};

const LazyOutlet = React.lazy(() => import('react-router-dom').then(module => ({ default: module.Outlet })));


const PageLoading = React.memo(() => {
  return (
<div className="min-h-screen flex flex-col justify-center items-center bg-gray-50">
<div className="text-center space-y-4">
                        {/* Error Icon */}
                        <div className="flex flex-col justify-center items-center text-zinc-500 space-y-6">
                            <HeaderLogo />
                        </div>

        <LoadingState />
        </div>
    </div>
  )
});

export const PrivateRoute = ({ fallback = <PageLoading /> }) => {
  const location = useLocation();
  const { isAuthenticated, userOrganisation, userOnboardingStatus } = useAuth();

  if (!isAuthenticated) {
    console.info('Not logged in, redirecting to login page', location);
    return <Navigate to={ROUTES.LOGIN} state={{ from: location }} replace />;
  }

  const userIsAcceptingInvite = location.pathname.startsWith(ROUTES.INVITATION_ACCEPT);

  if (!userIsAcceptingInvite && !userOrganisation && userOnboardingStatus === 'pending') {
    console.info('No organisation selected, redirecting to organisation selection page', {userOnboardingStatus, location, userOrganisation, userIsAcceptingInvite});
    //we should redirect to signup page if the user does not have an organisation;
    //return <Navigate to={ROUTES.ONBOARDING} state={{ from: location }} />;
  }

  return (
    <ErrorBoundary>
      <Suspense fallback={fallback}>
        <LazyOutlet />
      </Suspense>
    </ErrorBoundary>
  );
};

PrivateRoute.propTypes = {
  fallback: PropTypes.node,
};

export default React.memo(PrivateRoute);

// export const PrivateRoute = () => {

//   const location = useLocation();

//   const isAuthenticated = useAppSelector(selectIsLoggedIn);
//   const userOrganisation = useAppSelector(selectUserOrganisation);
//   const userOnboardingStatus = useAppSelector(selectOnboardingStatus)

//   if (!isAuthenticated) {
//     console.info('Not logged in, redirecting to login page', history.location);
//     return <Navigate to="/login" state={{ from: history.location }} replace />;
//   }

//   //chgeck that the user isn't accepting an invite
  
//   const userIsAcceptingInvite = location.pathname.startsWith('/invitation/accept');
//   console.info('user is accepting invite', userIsAcceptingInvite, location)
//   //User onboarding
//   if (!userIsAcceptingInvite && !userOrganisation && userOnboardingStatus === 'pending') {
//     console.info('No organisation selected, redirecting to organisation selection page', userOnboardingStatus);
//     return <Navigate to="/onboarding" state={{ from: history.location }} />;
//   }

//   return <Outlet />;
// };
