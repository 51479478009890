import { FormControl, FormDescription, FormField, FormItem, FormMessage } from "@/components/ui/form"
import { Control } from "react-hook-form"
import { FormLabelWithIndicator } from "../form-label-with-indicator"
import { Switch } from "@/components/ui/switch"
import { CustomFieldProps } from '../custom-fields-container';

type CustomCheckboxFieldProps = CustomFieldProps & {
}

export function CustomCheckboxField({
  control,
  name,
  label,
  description,
  showDescription,
  info,
  required,
  isValid,
}: CustomCheckboxFieldProps) {
  return (
    <FormField
      control={control}
      name={name}
      render={({ field }) => (
        <FormItem className="">
          <FormLabelWithIndicator label={label} isValid={isValid} required={required} info={info} />
          <FormControl>
            <Switch
              checked={field.value}
              onCheckedChange={field.onChange}
            />
          </FormControl>
          {showDescription && (<FormDescription>{description}</FormDescription>)}          
          {/* <FormMessage /> */}
        </FormItem>
      )}
    />
  )
}

