import {
  ButtonProps as AriaButtonProps,
  Input as AriaInput,
  InputProps as AriaInputProps,
  NumberField as AriaNumberField,
  NumberFieldProps as AriaNumberFieldProps,
  ValidationResult as AriaValidationResult,
  composeRenderProps,
  Text,
} from "react-aria-components"

import { cn } from "@/lib/utils"

import { Button } from "./button"
import { FieldError, FieldGroup, Label } from "./field"
import { ChevronDownIcon, ChevronUpIcon } from "@radix-ui/react-icons"

const NumberField = AriaNumberField

function NumberFieldInput({ className, ...props }: AriaInputProps) {
  return (
    <AriaInput
      className={composeRenderProps(className, (className) =>
        cn(
          "w-fit min-w-0 flex-1 border-transparent bg-transparent pr-2 text-sm outline outline-0 placeholder:text-muted-foreground [&::-webkit-search-cancel-button]:hidden",
          className
        )
      )}
      {...props}
    />
  )
}

function NumberFieldSteppers({
  className,
  ...props
}: React.ComponentProps<"div">) {
  return (
    <div
      className={cn(
        "absolute right-0 flex h-full flex-col border-l",
        className
      )}
      {...props}
    >
      <NumberFieldStepper slot="increment">
        <ChevronUpIcon aria-hidden className="size-4" />
      </NumberFieldStepper>
      <div className="border-b" />
      <NumberFieldStepper slot="decrement">
        <ChevronDownIcon aria-hidden className="size-4" />
      </NumberFieldStepper>
    </div>
  )
}

function NumberFieldStepper({ className, ...props }: AriaButtonProps) {
  return (
    <Button
    // @ts-ignore
      className={composeRenderProps(className, (className) =>
        cn("w-auto grow rounded-none px-0.5 text-muted-foreground", className)
      )}
      variant={"ghost"}
      size={"icon"}
      {...props}
    />
  )
}

export {
  NumberField,
  NumberFieldInput,
  NumberFieldSteppers,
  NumberFieldStepper,
}

