import { FormControl, FormDescription, FormField, FormItem, FormMessage } from "@/components/ui/form"
import { Input } from "@/components/ui/input"
import { FormLabelWithIndicator } from "../form-label-with-indicator"
import { CustomFieldProps } from '../custom-fields-container';

type CustomTextFieldProps = CustomFieldProps & {
}

export function CustomTextField({
  control,
  name,
  label,
  description,
  showDescription,
  info,
  required,
  placeholder,
  isValid,
}: CustomTextFieldProps) {
  return (
    <FormField
      control={control}
      name={name}
      render={({ field }) => (
        <FormItem>
          <FormLabelWithIndicator label={label} isValid={isValid} required={required} info={info} />
          <FormControl>
            <Input placeholder={placeholder} {...field} />
          </FormControl>
          {showDescription && (<FormDescription>{description}</FormDescription>)}
          <FormMessage />
        </FormItem>
      )}
    />
  )
}

