import { configureStore } from "@reduxjs/toolkit";
import { rootReducer } from "./root-state.reducer"; 
import { rAPI } from "../services";
import { uploadMiddleware } from "../images/upload-middleware";

declare const process: any;

const isDevelopment = process.env.NODE_ENV === 'development';

const rootStore = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: {
            // Ignore these action types
            ignoredActions: ['uploads/initiateUpload'],
            // Ignore these field paths in all actions
            ignoredActionPaths: ['payload.file']
          }        
    }).concat(rAPI.middleware, uploadMiddleware),
    devTools: isDevelopment,
    //preloadedState: initialRootState,
});

export type AppDispatch = typeof rootStore.dispatch;

export { rootStore };

export type RootState = ReturnType<typeof rootStore.getState>;

export default rootStore;