import { cn } from "@/lib/utils"
import { Button } from "@/components/ui/button"
import type { TestStatus } from "../types/test-form"

interface StatusIndicatorProps {
  currentStatus: TestStatus
  onStatusChange: (status: TestStatus) => void
  saveStatus?: string
  isFormValid: boolean
  areRequiredFieldsFilled: boolean
}

const testBadgeVariants = {
  archived: "bg-white text-zinc-500 border-zinc-400",
  draft: "bg-white text-zinc-950 border border-zinc-950",
  qa: "bg-zinc-500 text-white",
  final: "bg-black text-white",
  inactive: 'border-zinc-300 bg-transparent text-transparent',
  disabled: 'bg-zinc-300 text-red-500'
};

const getStatusLabel = (status: TestStatus): string => {
  switch (status) {
    case "archive": return "Archive";
    case "draft": return "Draft";
    case "qa": return "QA";
    case "published": return "Final";
    default: return "Inactive";
  }
}

export function StatusIndicator({ currentStatus, onStatusChange, saveStatus, isFormValid, areRequiredFieldsFilled }: StatusIndicatorProps) {
  const statuses: TestStatus[] = ["archive", "draft", "qa", "published"]

  const getVariant = (status: TestStatus): string => {
    switch (status) {
      case "archive": return testBadgeVariants.archived;
      case "draft": return testBadgeVariants.draft;
      case "qa": return testBadgeVariants.qa;
      case "published": return testBadgeVariants.final;
      default: return testBadgeVariants.inactive;
    }
  }

  return (
    <div className="flex items-center gap-2">
      {saveStatus && (
        <p className="text-sm text-green-500 mr-2">
          ({saveStatus})
        </p>
      )}
      {statuses.map((status) => (
        <Button
          key={status}
          variant="outline"
          className={cn(
            "capitalize",
            getVariant(status),
            currentStatus === status ? "ring-none ring-offset-0" : "",
            (status === 'published' || status === 'qa') && !isFormValid ? "opacity-50 cursor-not-allowed text-red-500 bg-zinc-200" : "",
          )}
          onClick={() => onStatusChange(status)}
          disabled={(status === 'published' || status === 'qa') ? !isFormValid : !areRequiredFieldsFilled}
        >
          {getStatusLabel(status)}
        </Button>
      ))}
    </div>
  )
}

