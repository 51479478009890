import { AssetEntity, useGetInspectionQuery, useGetReportInspectionsQuery } from '@app.raytd.com/store';
import { useState, useEffect, useMemo } from 'react';

interface Inspection {
  id: number;
  title: string;
  asset: number;
}

export interface GroupedInspections {
  [assetTitle: string]: Inspection[];
}

export function useInspectionData(inspections: Inspection[], assets: AssetEntity[]) {


  const groupedInspections = useMemo(() => {

    console.debug('groupedInspections', inspections, assets);

    if (!inspections) {
      return {};
    }

    const grouped = inspections.reduce((acc, inspection) => {
      const assetTitle = assets.find(asset => asset.id === inspection.asset)?.name || 'Unknown';  
      if (!acc[assetTitle]) {
        acc[assetTitle] = [];
      }
      acc[assetTitle].push(inspection);
      return acc;
    }, {} as GroupedInspections);

    return grouped;

  }, [inspections, assets]);

  return {groupedInspections};
 

}

