import React, { useState, useRef, useCallback } from 'react'
import { useController, Control } from 'react-hook-form'
import { Textarea } from "@/components/ui/textarea"
import { Button } from "@/components/ui/button"
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover"
import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem } from "@/components/ui/command"
import { ChevronsUpDownIcon as ChevronUpDown } from 'lucide-react'

interface FastFillItem {
  id: number
  description: string
}

interface FastFillTextAreaProps {
  name: string;
  control: Control<any>;
  placeholder?: string;
  fastFills: FastFillItem[];
  disabled?: boolean;
  className?: string;
}

export function FastFillText({ 
  name, 
  control, 
  placeholder, 
  fastFills,
  disabled,
  className 
}: FastFillTextAreaProps) {
  const {
    field: { onChange, onBlur, value, ref },
    fieldState: { error }
  } = useController({
    name,
    control,
  })

  const [open, setOpen] = useState(false)
  const textareaRef = useRef<HTMLTextAreaElement>(null)

  const insertTextAtCursor = useCallback((text: string) => {
    console.debug('insertTextAtCursor', textareaRef?.current);
    if (!textareaRef.current) return

    const textarea = textareaRef.current
    const start = textarea.selectionStart
    const end = textarea.selectionEnd
    const currentValue = textarea.value

    // Create new value with inserted text
    const newValue = currentValue.substring(0, start) + text + currentValue.substring(end)
    onChange(newValue)

    // Focus and set cursor position immediately
    requestAnimationFrame(() => {
      if (textareaRef.current) {
        const newCursorPosition = start + text.length
        textareaRef.current.focus()
        textareaRef.current.setSelectionRange(newCursorPosition, newCursorPosition)
      }
    })
  }, [onChange])

  const handleFastFillSelect = useCallback((text: string) => {
    setOpen(false)
    insertTextAtCursor(text)
  }, [insertTextAtCursor])

  return (
    <div className="space-y-0 border border-input rounded-md">
      <div className="w-full">
        <Textarea
          ref={(e) => {
            ref(e)
            textareaRef.current = e
          }}
          id={name}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          placeholder={placeholder}
          disabled={disabled}
          className={`min-h-[100px] border-none focus-visible:ring-0 ${className}`}
        />
      </div>
      <div className="flex justify-start bg-zinc-100">
        <Popover open={open} onOpenChange={setOpen} modal={true}>   
          <PopoverTrigger asChild>
            <Button 
              variant="ghost" 
              role="combobox" 
              aria-expanded={open} 
              className="w-full justify-start p-0"
              disabled={disabled}
            >
              <ChevronUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
              Insert Fast Fill
            </Button>
          </PopoverTrigger>
          <PopoverContent className="w-[var(--radix-popover-trigger-width)] p-0">
            <Command>
              <CommandInput 
                placeholder="Search fast fill..." 
                className="h-9"
              />
              <CommandEmpty>No fast fill found.</CommandEmpty>
              <CommandGroup>
                {fastFills?.length > 0 ? (
                  fastFills.map((item) => (
                    <CommandItem
                      key={item.id}
                      onSelect={() => handleFastFillSelect(item.description)}
                      className="cursor-pointer"
                    >
                      {item.description}
                    </CommandItem>
                  ))
                ) : (
                  <CommandItem disabled>No fast fill items available</CommandItem>
                )}
              </CommandGroup>
            </Command>
          </PopoverContent>
        </Popover>
      </div>
    </div>
  )
}
