import React from 'react';

const SkeletonTile = ({ height = 'h-24', className = '' }) => {

    const animation = 'h-4 bg-gray-200 mb-2 rounded skeleton';

    return (
        <div className={`${height} ${className} p-4 rounded-md`}>
            <div className={`${animation} `}></div>
            <div className={`${animation} w-3/4`}></div>
            <div className={`${animation} w-1/2`}></div>
        </div>
    );
};

export const SkeletonLoadingScreen = ({ tileHeight, tileCount = 5, className = '' }: { tileHeight: string; tileCount: number, className: string }) => {
    return (
        <div className="flex flex-col space-y-4">
            {Array(tileCount).fill(null).map((_, index) => (
                <SkeletonTile key={index} height={tileHeight} className={className} />
            ))}
        </div>
    );
};

export default SkeletonLoadingScreen;