import { Button } from "@/components/ui/button";
import { Dialog, DialogContent } from "@/components/ui/dialog";
import { ImageType } from "@/types/Images";
import { ChevronLeft, ChevronRight, X, ZoomIn, ZoomOut } from "lucide-react";
import { useCallback, useEffect, useState } from "react";

interface PhotoViewerDialogProps {
    images: ImageType[];
    isOpen: boolean;
    initialIndex?: number;
    onClose: () => void;
}

export function PhotoViewerDialog({ images, initialIndex = 0, isOpen = false, onClose }: PhotoViewerDialogProps) {
    const [selectedIndex, setSelectedIndex] = useState<number | null>(initialIndex);
    const [scale, setScale] = useState(1);

    useEffect(() => {
        setSelectedIndex(initialIndex);
    }, [initialIndex]);

    useEffect(() => {
        console.debug('PhotoViewerDialog', { isOpen, initialIndex });
        if (isOpen) {
            setSelectedIndex(initialIndex);
        } else {
            setSelectedIndex(null);
            setScale(1);
        }
    }, [isOpen, initialIndex]);

    const showNext = useCallback(() => {
        if (!isOpen || selectedIndex === null) return;
        setScale(1);
        setSelectedIndex((selectedIndex + 1) % images.length);
    }, [selectedIndex, images.length]);

    const showPrevious = useCallback(() => {
        if (!isOpen || selectedIndex === null) return;
        setScale(1);
        setSelectedIndex(selectedIndex === 0 ? images.length - 1 : selectedIndex - 1);
    }, [selectedIndex, images.length]);

    const handleKeyDown = useCallback((event: KeyboardEvent) => {
        if (!isOpen || selectedIndex === null) return;

        switch (event.key) {
            case 'ArrowRight':
                showNext();
                break;
            case 'ArrowLeft':
                showPrevious();
                break;
            case '+':
                setScale(prev => Math.min(prev + 0.25, 3));
                break;
            case '-':
                setScale(prev => Math.max(prev - 0.25, 0.5));
                break;
        }
    }, [showNext, showPrevious, selectedIndex]);

    useEffect(() => {
        window.addEventListener('keydown', handleKeyDown);
        return () => window.removeEventListener('keydown', handleKeyDown);
    }, [handleKeyDown]);

    return (
        <Dialog open={isOpen} onOpenChange={onClose}>
            <DialogContent className="max-w-5xl p-0">
                <div className="relative w-full bg-black/95 rounded-lg">
                    {/* Navigation buttons */}
                    <div className="absolute top-0 right-0 p-2 flex gap-2 z-10">
                        <Button
                            variant="ghost"
                            size="icon"
                            onClick={() => setScale(prev => Math.max(prev - 0.25, 0.5))}
                            className="text-white hover:bg-white/20"
                        >
                            <ZoomOut className="h-4 w-4" />
                        </Button>
                        <Button
                            variant="ghost"
                            size="icon"
                            onClick={() => setScale(prev => Math.min(prev + 0.25, 3))}
                            className="text-white hover:bg-white/20"
                        >
                            <ZoomIn className="h-4 w-4" />
                        </Button>
                        <Button
                            variant="ghost"
                            size="icon"
                            onClick={() => onClose?.()}
                            className="text-white hover:bg-white/20"
                        >
                            <X className="h-4 w-4" />
                        </Button>
                    </div>


                    <div className="relative h-[80vh] overflow-auto">
                        {selectedIndex !== null && (
                            <img
                                src={images[selectedIndex]?.uri || images[selectedIndex]?.thumbnail_url}
                                alt={images[selectedIndex]?.name}
                                className="w-full h-full object-contain transition-transform duration-200"
                                style={{ transform: `scale(${scale})` }}
                            />
                        )}
                    </div>

                    {images.length > 1 && (
                        <>
                            <Button
                                variant="ghost"
                                size="icon"
                                className="absolute left-2 top-1/2 -translate-y-1/2 text-black bg-white rounded-full hover:bg-white/20"
                                onClick={showPrevious}
                            >
                                <ChevronLeft className="h-8 w-8" />
                            </Button>
                            <Button
                                variant="ghost"
                                size="icon"
                                className="absolute right-2 top-1/2 -translate-y-1/2 text-black bg-white rounded-full hover:bg-white/20"
                                onClick={showNext}
                            >
                                <ChevronRight className="h-8 w-8" />
                            </Button>
                        </>
                    )}

                    <div className="absolute bottom-2 left-1/2 -translate-x-1/2 text-white bg-black/50 px-3 py-1 rounded-full text-sm">
                        {selectedIndex !== null && `${selectedIndex + 1} / ${images.length}`}
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    );
}
