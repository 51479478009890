
export function Header() {
    return (
<div className="sm:mx-auto sm:w-full sm:max-w-sm mb-4">
          <img
            className="mx-auto h-24 w-auto"
            src="/raytd_logo_primary_light_bg.svg"
            alt="RAYTD"
          />
          <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-raytd-700">
            THE NEXT GENERATION IN ASSET ASSESSMENT
          </h2>
        </div>
    )
}

export default Header;