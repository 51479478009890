import { useMemo } from 'react';
import { TestSpecification, TestSuites } from '../types/test-specification';

interface OrganizedData {
  [suite: string]: {
    [category: string]: {
      [subcategory: string]: TestSpecification[]
    }
  }
}

export function useTestSpecificationData(testSuites: TestSuites[]): OrganizedData {
  return useMemo(() => {

    if (!testSuites) {
      // is empty
      return {};
    }

    if (!Array.isArray(testSuites)) {
      console.error('Invalid testSuites input: expected array');
      return {};
    }

    const data: OrganizedData = {};

    testSuites.forEach((testSuite, suiteIndex) => {
      if (!testSuite?.specifications || !Array.isArray(testSuite.specifications)) {
        console.warn(`Invalid specifications array in suite at index ${suiteIndex}`);
        return;
      }

      const suiteId = testSuite.title?.trim();
      if (!suiteId) {
        console.warn(`Missing suite title at index ${suiteIndex}`);
        return;
      }

      testSuite.specifications.forEach((spec, specIndex) => {
        if (!spec?.category || !spec?.subcategory) {
          console.warn(`Invalid specification at index ${specIndex} in suite ${suiteId}`);
          return;
        }

        const category = spec.category.trim();
        const subcategory = spec.subcategory.trim();

        data[suiteId] = data[suiteId] || {};
        data[suiteId][category] = data[suiteId][category] || {};
        data[suiteId][category][subcategory] = data[suiteId][category][subcategory] || [];
        
        try {
          data[suiteId][category][subcategory].push({
            ...spec,
            category,
            subcategory
          });
        } catch (error) {
          console.error(`Failed to add specification at index ${specIndex}:`, error);
        }
      });
    });

    return data;
  }, [testSuites]);
}
