import { Avatar } from '@/components/ui/avatar';
import { UsersEntity } from '@app.raytd.com/store';
import { AvatarImage } from '@radix-ui/react-avatar';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/components/ui/tooltip";

import React from 'react';
import { cn } from '@/lib/utils';


interface AuthorBadgeProps {
    item: UsersEntity;
    size?: 'small' | 'medium' | 'large';
}

const sizeClasses = {
    small: {
        org: 'h-6 w-6',
        user: 'h-6 w-6',
        container: '-space-x-1 items-end'
    },
    medium: {
        org: 'h-8 w-8',
        user: 'h-8 w-8',
        container: '-space-x-2 items-end'
    },
    large: {
        org: 'h-24 w-24',
        user: 'h-14 w-14',
        container: '-space-x-4 items-end',
    }
};

const AuthorBadge = React.memo(({ item, size = 'medium' }: AuthorBadgeProps) => {
    if (!item) {
        return null;
    }


    return (    
        <div className={cn("flex flex-row -space-x-2 items-end", sizeClasses[size].container)}>
            <TooltipProvider>
                <Tooltip>
                    <TooltipTrigger asChild>
                        <Avatar className={cn("border-2 border-background", 
                            sizeClasses[size].org
                        )}>
                            <AvatarImage src={item?.company?.logo_url} alt={item?.company?.name} sizes='' />
                        </Avatar>
                    </TooltipTrigger>
                    <TooltipContent>
                        <p>{item?.company?.name}</p>
                    </TooltipContent>
                </Tooltip>

                <Tooltip>
                    <TooltipTrigger asChild>
                        <Avatar className={cn("border-2 border-background",
                            sizeClasses[size].user
                        )}>
                            <AvatarImage src={item?.avatar} alt={item?.name} sizes='' />
                        </Avatar>
                    </TooltipTrigger>
                    <TooltipContent>
                        <p>{item?.name}</p>
                    </TooltipContent>
                </Tooltip>
            </TooltipProvider>
        </div>
    )
});

export default AuthorBadge;