import { ClassificationItem, ClassificationType } from './types';
export const transformClassificationTree = {
    getLevelType(level: number): ClassificationType {
      const types: Record<number, ClassificationType> = {
        0: 'category',
        1: 'subcategory',
        2: 'type',
        3: 'subtype'
      };
      return types[level] ?? 'category';
    },
  
    buildTree(
      items: ClassificationItem[],
      level = 0,
      parentNames: string[] = []
    ): ClassificationItem[] {
      if (level > 3) return [];
  
      return items.map((item) => {
        const type = this.getLevelType(level);
        const currentParents = [...parentNames];
        
        return {
          ...item,
          level,
          type,
          parents: currentParents,
          children: this.buildTree(
            item.children || [], 
            level + 1,
            [...currentParents, item.name]
          ),
        };
      });
    }
  };
  