import * as React from "react"
import * as ProgressPrimitive from "@radix-ui/react-progress"
import { cn } from "@/lib/utils"

interface ProgressProps extends React.ComponentPropsWithoutRef<typeof ProgressPrimitive.Root> {
  value: number;
  indicateProgress?: boolean;
  size?: 'sm' | 'default' | 'lg';
}

const Progress = React.forwardRef<
  React.ElementRef<typeof ProgressPrimitive.Root>,
  ProgressProps
>(({ className, value, indicateProgress = false, size = "default", ...props }, ref) => {
  const [progress, setProgress] = React.useState(0);

  React.useEffect(() => {
    const timer = setTimeout(() => setProgress(value), 100);
    return () => clearTimeout(timer);
  }, [value]);

  return (
    <ProgressPrimitive.Root
      ref={ref}
      className={cn(
        "relative overflow-hidden rounded-full bg-secondary",
        {
          "h-2": size === "default",
          "h-1": size === "sm",
          "h-3": size === "lg",
        },
        className
      )}
      {...props}
    >
      <ProgressPrimitive.Indicator
        className={cn(
          "h-full w-full flex-1 transition-all",
          {
            "bg-primary": !indicateProgress || progress === 100,
            "bg-amber-500": indicateProgress && progress < 100 && progress > 0,
          }
        )}
        style={{ transform: `translateX(-${100 - progress}%)` }}
      />
      {indicateProgress && (
        <div className="absolute inset-0 flex items-center justify-center">
          <span className="text-xs font-medium text-white">
            {Math.round(progress)}%
          </span>
        </div>
      )}
    </ProgressPrimitive.Root>
  )
});

Progress.displayName = ProgressPrimitive.Root.displayName;

export { Progress };
