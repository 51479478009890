const HeaderLogo: React.FC = () => {


    if (process.env.NODE_ENV === 'development') {
        return null;
    }

    return (
        <div className="flex items-center">
            <img src="/raytd_beta_logo.png" alt="logo" className="h-10 w-auto" />
        </div>
    )
}

export default HeaderLogo;