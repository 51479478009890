import { FormControl, FormDescription, FormField, FormItem, FormMessage } from "@/components/ui/form"
import { Input } from "@/components/ui/input"
import { Control } from "react-hook-form"
import { FormLabelWithIndicator } from "../form-label-with-indicator"
import { CustomFieldProps } from '../custom-fields-container';
import { useCallback } from "react";
import { NumericFormat } from 'react-number-format';

import { FieldGroup, Label } from "@/components/ui/field"
import {
  NumberField,
  NumberFieldInput,
  NumberFieldSteppers,
} from "@/components/ui/numberfield"

type CustomNumberFieldProps = CustomFieldProps & {
  decimalPlaces: number;
  min?: number;
  max?: number;
}

export function CustomNumberField({
  control,
  name,
  label,
  decimalPlaces,
  ...props
}: CustomNumberFieldProps) {
  return (
    <FormField
      control={control}
      name={name}
      render={({ field }) => (
        <FormItem>
          <FormLabelWithIndicator label={label} {...props} />
          <FormControl>
            <NumberField {...field}
              aria-label={label}
              formatOptions={{
                minimumFractionDigits: decimalPlaces,
                maximumFractionDigits: decimalPlaces,

              }}
            >
              <FieldGroup variant="outline">
                <NumberFieldInput />
                {/* <NumberFieldSteppers /> */}
              </FieldGroup>
            </NumberField>

            {/* <NumericFormat
              customInput={Input}
              value={field.value}
              onValueChange={({ floatValue }) => {
                field.onChange(floatValue);
              }}
              decimalScale={decimalPlaces}
              allowNegative={true}
              decimalSeparator="."
              allowedDecimalSeparators={['.', ',']}
              thousandSeparator=","
              {...props}
            /> */}
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}
