import { useFormContext } from "react-hook-form"
import { CustomTextField } from "./custom-fields/custom-text-field"
import { CustomNumberField } from "./custom-fields/custom-number-field"
import { CustomDateField } from "./custom-fields/custom-date-field"
import { CustomSelectField } from "./custom-fields/custom-select-field"
import { CustomCheckboxField } from "./custom-fields/custom-checkbox-field"
import { TestSuite } from "store/src/lib/tests/entity"
import { CustomRadioField } from "@/app/features/reports/components/custom-fields/custom-radio-field"
import { CustomTextAreaField } from "@/app/features/reports/components/custom-fields/custom-textarea-field"

export type CustomFieldProps = {
  control: any;
  name: string;
  label: string;
  description: string;
  showDescription?: boolean;
  info: string;
  required: boolean;
  placeholder?: string;
  isValid: boolean;
}

interface CustomFieldsContainerProps {
  testSuite: TestSuite
  name: string;
}

export function CustomFieldsContainer({ name = 'custom', testSuite: testSuiteData }: CustomFieldsContainerProps) {
  const { control, watch, formState: { errors } } = useFormContext();

  if (!testSuiteData?.custom_fields?.length) return null;
  if (!testSuiteData || !testSuiteData.custom_fields || testSuiteData.custom_fields.length === 0) return null

  const sortedCustomFields = [...testSuiteData.custom_fields]
    .sort((a, b) => a.field_order - b.field_order);

  const getFieldValidation = (field: any) => {
    const fieldValue = watch(`${name}.${field.field_id}`);
    const fieldError = errors[name]?.[field.field_id];

    console.debug('custom field validation', { field, fieldValue, fieldError });

    // Field is valid if it has a value and no errors
    if (field.required) {
      return !!fieldValue && !fieldError;
    }
    // Optional fields are valid if they're empty or have no errors
    return !fieldValue || !fieldError;
  };

  //const sortedCustomFields = [...testSuiteData.custom_fields].sort((a, b) => a.field_order - b.field_order)

  return (
    <div className="space-y-4">
      {sortedCustomFields
        .filter(field => field.active)
        .map((field) => {
          const commonProps: CustomFieldProps & { key: string; } = {
            key: field.field_id,
            control: control,
            name: `${name}.${field.field_id}`,
            label: field.label,
            description: field.description,
            info: field.info,
            required: field.required,
            placeholder: field.placeholder || undefined,
            isValid: getFieldValidation(field),
            showDescription: false
          }

          switch (field.type) {
            case 'text':
              return <CustomTextField {...commonProps} />
            case 'number':
              return <CustomNumberField {...commonProps} decimalPlaces={field.decimal_places} />
            case 'date':
              return <CustomDateField {...commonProps} />
            case 'select':
              return (
                <CustomSelectField
                  {...commonProps}
                  options={field.data}
                  selectionType={field.selection_type}
                  allowAdd={field.allow_add}
                />
              )
            case 'radio':
              return <CustomRadioField {...commonProps} options={field.data} />
            case 'checkbox':
            case 'switch':
              return <CustomCheckboxField {...commonProps} />
            case 'textarea':
              return <CustomTextAreaField {...commonProps} options={field.data} />
            default:
              return <div key={field.field_id}>Unknown field type: {field.type}</div>
          }
        })}
    </div>
  )
}

