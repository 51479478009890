import { Button } from '@/components/ui/button';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { LoginCredentials, authActions, useAppDispatch } from "@app.raytd.com/store";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useLocation, useNavigate } from 'react-router-dom';

export type LoginProps = object;

const LoginError = ({ error }: { error: string }) => {

  if (!error) {
    return null;
  }

  return (
    <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
      <span className="block sm:inline" data-testid="login-error-message">{error}</span>
    </div>
  );
}

export function Login(props: LoginProps) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [saveRequestStatus, setSaveRequestStatus] = useState('idle');
  const [errorMessage, setErrorMessage] = useState('');


  const canSubmit = saveRequestStatus === 'idle';

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginCredentials>();

  const onSubmit = async (data: LoginCredentials) => {
    if (!canSubmit) {
      return;
    }

    try {
      setSaveRequestStatus('pending');
      setErrorMessage('');

      const response = await dispatch(authActions.login(data)).unwrap();
      console.debug('Login response', response);
      const user = await dispatch(authActions.getUser()).unwrap();


      console.debug('user response', response, user);

      const from = location.state?.from?.pathname || '/';

      const redirectTo = from === '/logout' ? '/' : from;

      console.info('Redirecting to', from);
      navigate(redirectTo);

    } catch (err: any) {
      console.debug(err);
      setErrorMessage(err?.message ?? err);
      setSaveRequestStatus('idle');
    }
  };

  return (
    <Card className="w-96 border-none shadow-none">
      <CardHeader className="mb-4">
        <CardTitle className="text-center text-2xl">Log in to your account</CardTitle>
      </CardHeader>
      <CardContent>
        <LoginError error={errorMessage} />

        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="mb-4">
            <Label htmlFor="email" className="sr-only">Email address</Label>
            <Input
              id="email"
              type="email"
              {...register('email', { required: true })}
              className="mt-1"
              autoComplete="email"
              aria-invalid={errors.email ? 'true' : 'false'}
              placeholder="Email address"
            />
            {errors.email && <p className="text-red-600 text-sm mt-1">Email is required</p>}
          </div>

          <div className="mb-4">
            <Label htmlFor="password" className="sr-only">Password</Label>
            <Input
              id="password"
              type="password"
              {...register('password', { required: true })}
              className="mt-1"
              autoComplete="current-password"
              aria-invalid={errors.password ? 'true' : 'false'}
              placeholder="Password"
            />
            {errors.password && <p className="text-red-600 text-sm mt-1">Password is required</p>}
            <div className="flex justify-end w-full mt-2">
              <Link to="/forgot-password" className="text-sm text-zinc-500 hover:text-zinc-800" data-testid="forgot-password-link">Forgot Password</Link>
            </div>
          </div>



          <Button type="submit" className="w-full">
            Login
          </Button>
          <div className="flex justify-end w-full mt-2">
            <Link to="/signup" className="text-sm text-zinc-500 hover:text-zinc-800" data-testid="signup-link">Don't have an account? Get Raytd Free</Link>
          </div>
        </form>
      </CardContent>
    </Card>

  );
}

export default Login;
