import React from 'react'
import { FormLabel } from "@/components/ui/form"
import { cn } from "@/lib/utils"
import { Tooltip, TooltipContent, TooltipTrigger } from "@/components/ui/tooltip"
import { InfoIcon } from "lucide-react"

interface FormLabelWithIndicatorProps extends React.HTMLAttributes<HTMLLabelElement> {
  label: string
  isValid: boolean
  required: boolean
  info?: string
  className?: string
  tooltipSide?: "top" | "right" | "bottom" | "left"
}

export const FormLabelWithIndicator = React.memo(({
  label,
  isValid,
  required,
  info,
  className,
  tooltipSide = "top",
  ...props
}: FormLabelWithIndicatorProps) => {
  const indicatorColor = required && !isValid
    ? "bg-destructive"
    : isValid && required
      ? "bg-green-500"
      : "bg-zinc-300"


    console.debug('FormLabelWithIndicator: ', { label, isValid, required, color: required&&!isValid });

  return (
    <FormLabel
      className={cn(
        "flex items-center gap-2",
        required && !isValid ? "text-destructive" : "text-foreground",
        className
      )}
      {...props}
      onClick={(e) => e.preventDefault()}
    >
      <span
        className={cn(
          "h-2 w-2 rounded-full transition-colors -ml-3",
          indicatorColor
        )}
        aria-hidden="true"
      />
      <span>{label}</span>
      {required && (
        <span
          className="text-destructive ml-1 sr-only"
          aria-label="Required field"
        >
          *
        </span>
      )}
      {info && (
        <Tooltip>
          <TooltipTrigger asChild>
            <InfoIcon
              className="h-4 w-4 text-muted-foreground cursor-help"
              aria-hidden="true"
            />
          </TooltipTrigger>
          <TooltipContent side={tooltipSide}>
            {info}
          </TooltipContent>
        </Tooltip>
      )}
    </FormLabel>
  )
});
