import { FormControl, FormField, FormItem } from "@/components/ui/form"
import { Select, SelectContent, SelectGroup, SelectItem, SelectLabel, SelectTrigger, SelectValue } from "@/components/ui/select"
import { FieldValues, useFormContext, useWatch } from "react-hook-form"
import { FormLabelWithIndicator } from './form-label-with-indicator'
import { GroupedInspections, useInspectionData } from '../hooks/useInspectionData'
import { useEffect, useCallback } from 'react'
import { Loader2 } from 'lucide-react'

interface InspectionSelectorProps {
  inspections: GroupedInspections;
  name: string;
  label: string;
  required: boolean;
  editable?: boolean;
}

export function InspectionSelector({ inspections: groupedInspections, name, label, required, editable = true }: InspectionSelectorProps) {
  const { control } = useFormContext();

  const selectedValue = useWatch({
    control,
    name,
    defaultValue: ""
  });

  const selectedInspection = selectedValue ?
    Object.values(groupedInspections)
      ?.flat()
      ?.find(inspection => Number(inspection.id) === Number(selectedValue)) : null;

  return (
    <FormField
      control={control}
      name={name}
      render={({ field }) => (
        <FormItem>
          <FormLabelWithIndicator
            label={label}
            isValid={!!field.value}
            required={required}
          />
          <Select
            {...field}
            value={field.value || ""}
            onValueChange={(value) => field.onChange(Number(value))}
            
          >
            <FormControl>
              <SelectTrigger disabled={!editable}>
                <SelectValue placeholder="Select Inspection" className="text-zinc-950">
                  {selectedInspection ? (
                    selectedInspection.title
                  ) : (
                    "Select Inspection"
                  )}
                </SelectValue>
              </SelectTrigger>
            </FormControl>
            <SelectContent>
              {(
                Object.entries(groupedInspections).map(([assetTitle, inspections]) => (
                  <SelectGroup key={assetTitle}>
                    <SelectLabel>{assetTitle}</SelectLabel>
                    {inspections.map((inspection) => (
                      <SelectItem key={inspection.id} value={inspection.id.toString()}>
                        {inspection.title}
                      </SelectItem>
                    ))}
                  </SelectGroup>
                ))
              )}
            </SelectContent>
          </Select>
        </FormItem>
      )}
    />
  )
}

