import { cn } from "@/lib/utils";
import { ImageOffIcon, Loader2, UploadIcon, XIcon } from "lucide-react";
import { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { toast } from "sonner";
import { UploadPreview } from './image-preview';
import { Progress } from "./progress";
import { MediaInfo, UploadItem } from "./types";
import { useUpload } from './useUpload';
import { formatFileSize } from "@/components/image-upload/utils";
import { ExistingMediaPreview } from './existing-media-preview';
import { rootStore } from "@app.raytd.com/store";
import { ImageType } from "@/types/Images";
import { usePhotoViewer } from "@/app/contexts/PhotoViewerContext";

interface FileUploaderProps {
  formId: string;
  onChange: (media: MediaInfo[]) => void;
  value?: MediaInfo[];
  maxFiles?: number;
  maxSize?: number;
  acceptedTypes?: string[];
  className?: string;
  disabled?: boolean;
}

export function FileUploader({
  formId,
  value = [],
  onChange,
  maxFiles = 10,
  maxSize = 20000000,
  acceptedTypes = ["image/jpeg", "image/jpg", "image/png", "image/webp"],
  className,
  disabled = false
}: FileUploaderProps) {
  const { uploads, hasActiveUploads, handleUpload, removeUpload, toggleDeleteUpload, totalProgress } = useUpload(formId);
  const [isDraggingOver, setIsDraggingOver] = useState(false);
  const [previewUrls, setPreviewUrls] = useState<Record<string, string>>({});
  const activeMediaCount = value.filter(media => !media.deleted).length;
  const totalFiles = activeMediaCount + uploads.length;

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: (acceptedFiles, rejectedFiles) => {
      if (disabled) return;

      // Handle file rejections
      rejectedFiles.forEach(({ file, errors }) => {
        errors.forEach(error => {
          switch (error.code) {
            case 'file-too-large':
              toast.error(`${file.name} is too large. Maximum size is ${formatFileSize(maxSize)}`);
              break;
            case 'file-invalid-type':
              toast.error(`${file.name} has an invalid file type. Accepted types: ${acceptedTypes.join(', ')}`);
              break;
            default:
              toast.error(`Error uploading ${file.name}: ${error.message}`);
          }
        });
      });

      // Check total files limit
      if (totalFiles + acceptedFiles.length > maxFiles) {
        toast.error(`Maximum ${maxFiles} files allowed`);
        return;
      }

      // Create preview URLs and start uploads
      acceptedFiles.forEach(file => {
        const previewUrl = URL.createObjectURL(file);
        setPreviewUrls(prev => ({ ...prev, [file.name]: previewUrl }));
        handleUpload(file);
      });

     // console.debug('value', value);
      // onChange(acceptedFiles);
     // onChange(value);
    },
    // accept: {
    //   'image/*': acceptedTypes
    // },
    accept: {
      'image/*': ['.jpg', '.jpeg', '.png', '.webp'] 
    },
    maxSize,
    maxFiles: maxFiles - uploads.length,
    disabled,
    noClick: disabled,
    noDrag: disabled
  });

  // Cleanup preview URLs on unmount
  useEffect(() => {
    return () => {
      Object.values(previewUrls).forEach(URL.revokeObjectURL);
    };
  }, [previewUrls]);

  // useEffect(() => {
  //   const completedUploads = uploads.filter(upload => upload.status === 'completed');
  //   const newMedia = completedUploads
  //     .map(upload => upload.mediaInfo)
  //     .filter(media => media && !value.some(item => item.id === media.id));
  
  //   if (newMedia.length > 0) {
  //     //maybe removeUpload to remove it from the uploads list
  //     newMedia.forEach(media => {
  //       removeUpload(media.id.toString());
  //     })
  //     onChange([...value, ...newMedia]);
  //   }
  // }, [uploads, onChange, value]);

  useEffect(() => {
    const checkImageAvailability = async (mediaInfo: MediaInfo): Promise<boolean> => {
      return new Promise((resolve) => {
        const maxAttempts = 5;
        let attempts = 0;
  
        const attemptLoad = () => {
          const img = new Image();
          
          img.onload = () => {
            resolve(true);
          };
  
          img.onerror = () => {
            attempts++;
            if (attempts < maxAttempts) {
              setTimeout(attemptLoad, 2000); // Retry after 2 seconds
            } else {
              resolve(false);
            }
          };
  
          img.src = mediaInfo.thumbnail_url;
        };
  
        attemptLoad();
      });
    };
  
    const processCompletedUploads = async () => {
      const completedUploads = uploads.filter(upload => upload.status === 'completed');
      const newMedia = completedUploads
        .map(upload => upload.mediaInfo)
        .filter(media => media && !value.some(item => item.id === media.id));
  
      if (newMedia.length > 0) {
        const availableMedia = await Promise.all(
          newMedia.map(async (media) => {
            const isAvailable = await checkImageAvailability(media);
            return isAvailable ? media : null;
          })
        );
  
        const validMedia = availableMedia.filter((media): media is MediaInfo => 
          media !== null
        );
  
        if (validMedia.length > 0) {

          completedUploads
          .filter(upload => upload.mediaInfo && validMedia.some(media => media.id === upload.mediaInfo?.id))
          .forEach(upload => {
            console.debug('removing upload', upload.id);
            removeUpload(upload.id);
          });

          onChange([...value, ...validMedia]);
        }
      }
    };
  
    processCompletedUploads();
  }, [uploads, onChange, value]);

// Add this to your component
useEffect(() => {
  const subscription = rootStore.subscribe(() => {
    console.log('Current uploads:', rootStore.getState().uploads.uploads);
  });
  return () => subscription();
}, []);

  const handleToggleMediaDelete = useCallback((mediaId: number) => {
    const updatedMedia = value.map(media => 
      media.id === mediaId 
        ? { ...media, deleted: !media.deleted }
        : media
    );
    onChange(updatedMedia);
  }, [value, onChange]);

  const handleToggleUploadDelete = useCallback((uploadId: string) => {
    const upload = uploads.find(u => u.id === uploadId);
    
    if (upload?.mediaInfo) {
      // Dispatch the toggle action
      toggleDeleteUpload(uploadId);
      
      // If we're managing form state, update it
      if (onChange) {
        const currentImages = value;
        const updatedImages = currentImages.map(img => 
          img.id === upload.mediaInfo?.id
            ? { ...img, deleted: !img.deleted }
            : img
        );
        onChange(updatedImages);
      }
    }
  }, [uploads, onChange, value]);

    const [previewImages, setPreviewImages] = useState<MediaInfo[]>([]);
    const { openPhotoViewer } = usePhotoViewer();
  
    useEffect(() => {
      setPreviewImages(value?.filter((img: MediaInfo) => !img.deleted) || []);
    }, [value]);
  

  const handlePreview = useCallback((id : number) => {
    const index = previewImages.findIndex(img => img.id === id);
    openPhotoViewer(previewImages, index);
  }, [openPhotoViewer, previewImages]);

  return (
    <div className={className}>
      <div
        {...getRootProps()}
        className={cn(
          "grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4",
          "min-h-[120px] p-4 rounded-lg transition-colors",
          {
            "border-2 border-dashed": isDragActive || isDraggingOver,
            "border-primary bg-primary/10": isDraggingOver,
            "border-primary": isDragActive,
          },
          "hover:border-primary/50",
          className
        )}
        style={{ gridTemplateColumns: 'repeat(auto-fill, minmax(64px, 1fr))' }}
      >
        {/* Upload Button Placeholder */}
        <div 
          className={cn(
            "aspect-square rounded-md bg-gray-50 cursor-pointer",
            "flex flex-col items-center justify-center gap-2",
            "hover:bg-gray-100 transition-colors"
          )}
        >
          <UploadIcon className="h-6 w-6 text-gray-400" />
          <p className="text-xs text-gray-500">
            {`${totalFiles}/${maxFiles}`}
          </p>
          <input {...getInputProps()} />
        </div>

        {/* Existing Media */}
        {value
          .map((media) => (
            <ExistingMediaPreview
              key={media.id}
              media={media}
              onRemove={() => handleToggleMediaDelete(media.id)}
              onClick={handlePreview}
              disabled={disabled}
            />
          ))}

        {/* Upload Previews */}
        {uploads.map((upload) => (
          <UploadPreview
            key={upload.id}
            upload={upload}
            previewUrl={previewUrls[upload.fileName]}
            onRemove={disabled ? undefined : () => handleToggleUploadDelete(upload.id)}
          />
        ))}

        {/* Upload Progress Indicator */}
        {hasActiveUploads && (
          <div className="fixed bottom-4 right-4 bg-white rounded-lg shadow-lg p-4 z-50">
            <Progress
              value={totalProgress}
              size="sm"
              indicateProgress
              className="w-[200px]"
            />
          </div>
        )}
      </div>
    </div>
  );

}


