import { FormControl, FormDescription, FormField, FormItem, FormMessage } from "@/components/ui/form"
import { Control } from "react-hook-form"
import { FormLabelWithIndicator } from "../form-label-with-indicator"
import { DatePicker } from "@/components/ui/date-picker"
import { CustomFieldProps } from '../custom-fields-container';

type CustomDateFieldProps = CustomFieldProps & {
}


export function CustomDateField({
  control,
  name,
  label,
  description,
  showDescription,
  info,
  required,
  placeholder,
  isValid,
}: CustomDateFieldProps) {
  return (
    <FormField
      control={control}
      name={name}
      render={({ field }) => (
        <FormItem className="flex flex-col">
          <FormLabelWithIndicator label={label} isValid={isValid} required={required} info={info} />
          <FormControl>
            <DatePicker
              date={field.value}
              setDate={(date) => field.onChange(date)}
              placeholder={placeholder}
            />
          </FormControl>
          {showDescription && (<FormDescription>{description}</FormDescription>)}
          <FormMessage />
        </FormItem>
      )}
    />
  )
}

