import {
  createEntityAdapter,
  createSelector,
  EntityState,
} from '@reduxjs/toolkit';
import {
  BaseQueryFn,
  createApi,
  fetchBaseQuery,
} from '@reduxjs/toolkit/query/react';
import { Mutex } from 'async-mutex';
import { toast } from 'sonner';
import { Assessment } from '../assessment/assessment.slice';
import { AssetEntity } from '../asset';
import { authActions } from '../auth';
import { RootState } from '../root';
import { TestItem, TestSuite } from '../tests/entity';
import { TestSpecificationsEntity } from '../tests/test-specifications.slice';
import {
  ClassificationItem,
  ReportBlock,
  ReportEntity,
  ReportResponse,
  ReportPayload,
  ReportRevision,
  ReportUpdatePayload,
} from './types';
import { UsersEntity } from '../users';
import { ReportSection } from './types';
import { transformClassificationTree } from './elementTransformer';

const API_URL = (import.meta.env.VITE_API_URL as string) + '/api/v1/';
const mutex = new Mutex();

console.debug('API_URL:', API_URL);

interface PaginatedResponse<T> {
  data: T[];
  meta: {
    current_page: number;
    last_page: number;
    per_page: number;
    total: number;
  };
}

const baseQuery = fetchBaseQuery({
  baseUrl: API_URL,
  prepareHeaders: (headers, { getState }) => {
    const token = (getState() as RootState).auth.user?.token;
    const csrf = (getState() as RootState).auth.xsrfToken;

    console.debug('headers:', headers);

    // Only set these headers if we're not sending FormData
    if (!headers.get('Content-Type')?.includes('multipart/form-data')) {
      //  headers.set('Content-Type', 'application/json');
      headers.set('Accept', 'application/json');
    }

    headers.set('Accept', 'application/json');

    if (csrf) {
      headers.set('X-XSRF-TOKEN', csrf);
    }

    if (token) {
      headers.set('Authorization', `Bearer ${token}`);
    }

    console.info('headers:', headers);

    return headers;
  },
  credentials: 'include',
});

const customBaseQuery: BaseQueryFn = async (args, api, extraOptions) => {
  await mutex.waitForUnlock();
  let result = await baseQuery(args, api, extraOptions);

  // If we get a 401 error, attempt to refresh the token
  if (result.error && result.error.status === 401) {
    // Lock the mutex so other queries wait until the token refresh is complete
    if (!mutex.isLocked()) {
      const release = await mutex.acquire();

      try {
        //   const refreshResult = await baseQuery('/auth/refresh-token', api, extraOptions);

        //   if (refreshResult.data) {
        //     // Dispatch the new token to the store
        //     api.dispatch(refreshToken(refreshResult.data));

        //     // Retry the original query with the new token
        //     result = await baseQuery(args, api, extraOptions);
        //   } else {
        //     // If the refresh fails, log out the user
        //     api.dispatch(logout());
        //   }
        api.dispatch(authActions.logout());
      } finally {
        // Release the mutex no matter what happens
        release();
      }
    } else {
      // If another request is refreshing the token, wait for it to complete
      await mutex.waitForUnlock();
      result = await baseQuery(args, api, extraOptions);
    }
  }

  return result;
};

const assetsAdapter = createEntityAdapter<AssetEntity>();
const initialState = assetsAdapter.getInitialState();

const testItemsAdapter = createEntityAdapter({
  selectId: (item: TestItem) => item.assessmentTestId,
});

const initialTestItemsState = testItemsAdapter.getInitialState();

function getAllItemIds(item: ClassificationItem): number[] {
  return [item.id, ...(item.children?.flatMap(getAllItemIds) ?? [])];
}

const reportSectionTags = (reportId?: number, sectionId?: string) =>
  [
    { type: 'ReportSections' as const, id: reportId },
    sectionId && { type: 'ReportSection' as const, id: sectionId },
  ].filter(Boolean);

export const rAPI = createApi({
  reducerPath: 'assetApi',
  baseQuery: customBaseQuery,
  tagTypes: [
    'Asset',
    'Assets',
    'CoverImage',
    'Assessment',
    'Buildings',
    'Levels',
    'Areas',
    'Building',
    'Level',
    'Area',
    'User',
    'Users',
    'Qualifications',
    'Offices',
    'Organisations',
    'ElementGroups',
    'ElementTree',
    'ElementItems',
    'ElementItem',
    'Recommendations',
    'Observations',
    'TestSuites',
    'Specifications',
    'RatingScales',
    'TestItems',
    'TestItem',
    'ReportBlocks',
    'Assessments',
    'Report',
    'Reports',
    'ReportRevision',
    'ReportSections',
    'ReportSection',
  ],
  endpoints: (builder) => ({
    extendAndResendInvite: builder.mutation({
      query: (invitationId) => ({
        url: `organisations/invite/${invitationId}/extend`,
        method: 'POST',
      }),
    }),

    getAssets: builder.query<EntityState<AssetEntity, number>, void>({
      query: () => 'assets',
      transformResponse: (response: { data: AssetEntity[] }) => {
        //return response.data;
        return assetsAdapter.setAll(initialState, response.data);
      },
      providesTags: (result) =>
        result?.ids
          ? [
              ...result.ids.map((id) => ({ type: 'Asset' as const, id })),
              { type: 'Assets' as const, id: 'LIST' },
            ]
          : [{ type: 'Assets', id: 'LIST' }],
    }),
    getAsset: builder.query({
      query: (assetId) => `asset/${assetId}`,
      // providesTags: (result, error, id) => [{ type: 'Asset', id }],
      providesTags: (result, error, id) =>
        result
          ? [
              { type: 'Asset', id },
              ...(result.buildings?.map((building) => ({
                type: 'Building' as const,
                id: building.id,
              })) ?? []),
              ...(result.buildings?.flatMap((building) =>
                building.levels.map((level) => ({
                  type: 'Level' as const,
                  id: level.id,
                }))
              ) ?? []),
              ...(result.buildings?.flatMap((building) =>
                building.levels.flatMap((level) =>
                  level.locations.map((location) => ({
                    type: 'Area' as const,
                    id: location.id,
                  }))
                )
              ) ?? []),
            ]
          : [{ type: 'Asset', id }],
    }),
    createAsset: builder.mutation({
      query: ({ organisationId, ...asset }) => ({
        url: `organisations/${organisationId}/assets`,
        method: 'POST',
        body: asset,
      }),
      invalidatesTags: [{ type: 'Assets', id: 'LIST' }],
    }),
    updateAsset: builder.mutation({
      query: ({ id, ...asset }) => ({
        url: `/asset/${id}`,
        method: 'PUT',
        body: asset,
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: 'Asset', id },
        { type: 'Assets', id: 'LIST' },
      ],
    }),
    retireAsset: builder.mutation({
      query: (assetId) => ({
        url: `/asset/${assetId}/retire`,
        method: 'PUT',
      }),
      invalidatesTags: (result, error, id) => [
        { type: 'Asset', id },
        { type: 'Assets', id: 'LIST' },
      ],
    }),
    changeUserRole: builder.mutation({
      query: ({ assetId, user_id, role }) => ({
        url: `/asset/${assetId}/role`,
        method: 'PUT',
        body: {
          user_id,
          role,
        },
      }),
      invalidatesTags: ['Asset'],
    }),
    archiveAsset: builder.mutation({
      query: (assetId) => ({
        url: `asset/${assetId}/archive`,
        method: 'PATCH',
      }),
      async onQueryStarted(assetId, lifecycleApi) {
        const getAssetsPatchResult = lifecycleApi.dispatch(
          rAPI.util.updateQueryData('getAssets', undefined, (draft) => {
            const asset = draft.entities[assetId];

            if (asset) {
              if (asset.status === 'archived') {
                asset.status = 'active';
                asset.is_globally_archived = false;
              } else {
                asset.status = 'archived';
                asset.is_globally_archived = true;
              }
            }
          })
        );

        try {
          await lifecycleApi.queryFulfilled;
          //          const state = lifecycleApi.getState();
          //        const updatedAsset = state.assetApi.queries['getAssets']?.data?.entities[assetId];

          //      toast.success(`Asset ${updatedAsset.status === 'active' ? 'restored' : 'archived'} successfully`);
          toast.success(`Asset updated`);
        } catch (error) {
          console.error('Error archiving asset', error);
          toast.error('Error archiving asset');
          getAssetsPatchResult.undo();
        }
      },
      // invalidatesTags: ['Asset'],
    }),

    archiveAssetForUser: builder.mutation({
      query: (assetId) => ({
        url: `asset/${assetId}/status`,
        method: 'PATCH',
      }),
      async onQueryStarted(assetId, lifecycleApi) {
        const getAssetsPatchResult = lifecycleApi.dispatch(
          rAPI.util.updateQueryData('getAssets', undefined, (draft) => {
            const asset = draft.entities[assetId];

            if (asset) {
              if (asset.status === 'archived') {
                asset.status = 'active';
              } else {
                asset.status = 'archived';
              }
            }
          })
        );

        try {
          await lifecycleApi.queryFulfilled;
          //          const state = lifecycleApi.getState();
          //        const updatedAsset = state.assetApi.queries['getAssets']?.data?.entities[assetId];

          //      toast.success(`Asset ${updatedAsset.status === 'active' ? 'restored' : 'archived'} successfully`);
          toast.success(`Asset updated`);
        } catch (error) {
          console.error('Error archiving asset', error);
          toast.error('Error archiving asset');
          getAssetsPatchResult.undo();
        }
      },
      // invalidatesTags: ['Asset'],
    }),

    getAssetLocations: builder.query({
      query: (assetId) => `asset/${assetId}/locations`,
    }),
    addCollaborator: builder.mutation({
      query: ({ assetId, ...collaborator }) => ({
        url: `asset/${assetId}/collaborators`,
        method: 'POST',
        body: collaborator,
      }),
      invalidatesTags: ['Asset'],
    }),
    removeCollaborator: builder.mutation({
      query: ({ assetId, userId }) => ({
        url: `asset/${assetId}/collaborators`,
        method: 'DELETE',
        body: { user_id: userId },
      }),
      invalidatesTags: ['Asset'],
    }),
    uploadCoverImage: builder.mutation({
      query: ({ assetId, coverImage }) => {
        const formData = new FormData();
        formData.append('cover_image', coverImage);
        return {
          url: `asset/${assetId}/cover`,
          method: 'POST',
          body: formData,
          headers: {
            // Explicitly remove Content-Type header if it's being set somewhere
            'Content-Type': undefined,
          },
        };
      },
      invalidatesTags: ['CoverImage'],
    }),
    getLoggedInUser: builder.query({
      query: () => 'user/profile',
      transformResponse: (response: { data: any }) => response.data,
      providesTags: ['User'],
    }),
    getTeamUsers: builder.query({
      query: () => 'users',
      transformResponse: (response: { users: any[] }) => response.users,
      providesTags: ['Users'],
    }),
    updateUserProfile: builder.mutation({
      query: (profile) => ({
        url: 'user/profile',
        method: 'PUT',
        body: profile,
      }),
      invalidatesTags: ['User'],
    }),
    deleteCoverImage: builder.mutation({
      query: (assetId) => ({
        url: `asset/${assetId}/cover`,
        method: 'DELETE',
      }),
      invalidatesTags: ['CoverImage'],
    }),
    getAssetAssessments: builder.query({
      query: (assetId) => `asset/${assetId}/assessments`,
      providesTags: ['Assessment'],
    }),
    getAssetBuildings: builder.query({
      query: (assetId) => `asset/${assetId}/buildings`,
      providesTags: (result) =>
        result
          ? [
              ...result.map((building) => ({
                type: 'Building' as const,
                id: building.id,
              })),
              { type: 'Building', id: 'LIST' },
            ]
          : [{ type: 'Building', id: 'LIST' }],
    }),
    addBuilding: builder.mutation({
      query: (building) => ({
        url: `buildings`,
        method: 'POST',
        body: building,
      }),
      invalidatesTags: (result, error, { asset_id }) => [
        { type: 'Building', id: 'LIST' },
        { type: 'Asset', id: asset_id },
      ],
    }),
    updateBuilding: builder.mutation({
      query: ({ building_id, ...building }) => ({
        url: `buildings/${building_id}`,
        method: 'PUT',
        body: building,
      }),
      invalidatesTags: (result, error, { id, asset_id }) => [
        { type: 'Building', id },
        { type: 'Asset', id: asset_id },
      ],
    }),
    getAssetLevels: builder.query({
      query: (assetId) => `asset/${assetId}/levels`,
      providesTags: (result) =>
        result
          ? [
              ...result.map((level) => ({
                type: 'Level' as const,
                id: level.id,
              })),
              { type: 'Level', id: 'LIST' },
            ]
          : [{ type: 'Level', id: 'LIST' }],
    }),

    addAssetLevel: builder.mutation({
      query: (level) => ({
        url: `levels`,
        method: 'POST',
        body: level,
      }),
      invalidatesTags: (result, error, { asset_id, building_id }) => [
        { type: 'Level', id: 'LIST' },
        { type: 'Building', id: building_id },
        { type: 'Asset', id: asset_id },
      ],
    }),
    updateLevel: builder.mutation({
      query: ({ level_id, ...level }) => ({
        url: `levels/${level_id}`,
        method: 'PUT',
        body: level,
      }),
      invalidatesTags: (result, error, { id, asset_id, building_id }) => [
        { type: 'Level', id },
        { type: 'Building', id: building_id },
        { type: 'Asset', id: asset_id },
      ],
    }),
    getAssetAreas: builder.query({
      query: (assetId) => `asset/${assetId}/areas`,
      providesTags: (result) =>
        result
          ? [
              ...result.map((area) => ({ type: 'Area' as const, id: area.id })),
              { type: 'Area', id: 'LIST' },
            ]
          : [{ type: 'Area', id: 'LIST' }],
    }),
    addAssetArea: builder.mutation({
      query: (area) => ({
        url: `rooms`,
        method: 'POST',
        body: area,
      }),
      invalidatesTags: (result, error, { asset_id, building_id, level_id }) => [
        { type: 'Area', id: 'LIST' },
        { type: 'Level', id: level_id },
        { type: 'Building', id: building_id },
        { type: 'Asset', id: asset_id },
      ],
    }),
    updateArea: builder.mutation({
      query: ({ room_id, ...area }) => ({
        url: `rooms/${room_id}`,
        method: 'PUT',
        body: area,
      }),
      invalidatesTags: (
        result,
        error,
        { room_id, asset_id, building_id, level_id }
      ) => [
        { type: 'Area', id: room_id },
        { type: 'Level', id: level_id },
        { type: 'Building', id: building_id },
        { type: 'Asset', id: asset_id },
      ],
    }),

    getUserQualifications: builder.query({
      query: () => `qualifications`,
      transformResponse: (response: { data: any[] }) => response.data,
      providesTags: ['Qualifications'],
    }),
    createUserQualification: builder.mutation({
      query: (qualification) => ({
        url: `qualifications`,
        method: 'POST',
        body: qualification,
      }),
      invalidatesTags: ['Qualifications'],
    }),
    updateUserQualification: builder.mutation({
      query: ({ qualification_id, ...qualification }) => ({
        url: `qualifications/${qualification_id}`,
        method: 'PUT',
        body: qualification,
      }),
      invalidatesTags: ['Qualifications'],
    }),
    deleteUserQualification: builder.mutation({
      query: (qualification_id) => ({
        url: `qualifications/${qualification_id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Qualifications'],
    }),

    getOffices: builder.query({
      query: () => 'offices',
      transformResponse: (response: { data: any[] }) => response.data,
      providesTags: ['Offices'],
    }),

    createOffice: builder.mutation({
      query: (office) => ({
        url: 'offices',
        method: 'POST',
        body: office,
      }),
      invalidatesTags: ['Offices'],
    }),

    deleteOffice: builder.mutation({
      query: (officeId) => ({
        url: `offices/${officeId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Offices'],
    }),

    updateOffice: builder.mutation({
      query: ({ id, ...office }) => ({
        url: `offices/${id}`,
        method: 'PUT',
        body: office,
      }),
      invalidatesTags: ['Offices'],
    }),

    getAdminOrganisations: builder.query({
      query: () => 'admin/organisations',
      transformResponse: (response: { data: any[] }) => response.data,
      providesTags: ['Organisations'],
    }),
    getAdminUsers: builder.query({
      query: (organisationId) =>
        organisationId ? `admin/users/${organisationId}` : 'admin/users',
      transformResponse: (response: { data: any[] }) => response.data,
      providesTags: ['Users'],
    }),

    deleteAdminUser: builder.mutation({
      query: ({ userId }) => ({
        url: `admin/users/${userId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Users'],
    }),

    getOrganisation: builder.query({
      query: (organisationId) => `organisations/${organisationId}`,
      transformResponse: (response: { data: any }) => response.data,
      providesTags: ['Organisations'],
    }),

    updateOrganisation: builder.mutation({
      query: ({ id, ...organisation }) => ({
        url: `organisations/${id}`,
        method: 'PUT',
        body: organisation,
      }),
      invalidatesTags: ['Organisations'],
    }),

    leaveOrganisation: builder.mutation({
      query: ({ id, user_id }) => ({
        url: `organisations/${id}/leave`,
        method: 'DELETE',
        body: {
          user_id: user_id,
        },
      }),
      invalidatesTags: [
        'Organisations',
        'Users',
        'Offices',
        'Asset',
        'Buildings',
        'Levels',
        'Areas',
        'User',
      ],
    }),

    inviteTeamMember: builder.mutation({
      query: (user) => ({
        url: 'organisations/invite',
        method: 'POST',
        body: user,
      }),
      invalidatesTags: ['Users'],
    }),

    createUser: builder.mutation({
      query: (user) => ({
        url: 'register',
        method: 'POST',
        body: user,
      }),
      invalidatesTags: ['Users'],
    }),

    updateUserRole: builder.mutation({
      query: ({ user_id, organisation_id, role }) => ({
        url: `users/${user_id}/organisations/${organisation_id}`,
        method: 'PUT',
        body: { role },
      }),
      invalidatesTags: ['Users'],
    }),

    getElementGroups: builder.query({
      query: (organisationId) =>
        `classification-trees/organisation/${organisationId}`,
      transformResponse: (response: { data: any[] }) => response.data,
      providesTags: ['ElementGroups'],
    }),
    getElementGroup: builder.query({
      query: (elementGroupId) => `classification-trees/${elementGroupId}`,
      transformResponse: (response: { data: any }) => response.data,
      providesTags: ['ElementGroups'],
    }),
    createElementGroup: builder.mutation({
      query: (elementGroup) => ({
        url: 'classification-trees',
        method: 'POST',
        body: elementGroup,
      }),
      invalidatesTags: ['ElementGroups'],
    }),
    updateElementGroup: builder.mutation({
      query: ({ id, ...elementGroup }) => ({
        url: `classification-trees/${id}`,
        method: 'PUT',
        body: elementGroup,
      }),
      invalidatesTags: ['ElementGroups'],
    }),

    getElementTree: builder.query<ClassificationItem[], number>({
      query: (treeId) => `classification-trees/${treeId}/items`,
      transformResponse:  (response: ClassificationItem[]) => 
        transformClassificationTree.buildTree(response),
      providesTags: (result, error, treeId) => [
        { type: 'ElementTree', id: treeId },
        ...(result
          ?.flatMap((item) => getAllItemIds(item))
          .map((id) => ({ type: 'ElementItem' as const, id })) ?? []),
      ],
    }),

    archiveElementTreeForUser: builder.mutation({
      query: (classificationTreeId) => ({
        url: `classification-trees/${classificationTreeId}/status`,
        method: 'PUT',
      }),
      invalidatesTags: (result, error, id) => [
        { type: 'ElementTree', id },
        { type: 'ElementGroups', id: 'LIST' }
      ],
        }),

    // Archive Classification Tree Mutation
    archiveElementTree: builder.mutation({
      query: (classificationTreeId) => ({
        url: `classification-trees/${classificationTreeId}/archive`,
        method: 'PUT',
      }),
      invalidatesTags: (result, error, id) => [
        { type: 'ElementTree', id },
        { type: 'ElementGroups', id: 'LIST' }
      ],
    }),

    duplicateClassificationTree: builder.mutation({
      query: (classificationTreeId) => ({
        url: `classification-trees/${classificationTreeId}/duplicate`,
        method: 'POST',
      }),
      invalidatesTags: ['ElementGroups'],
    }),

    //POST classification-trees/{classificationTree}/items
    createClassificationItem: builder.mutation({
      query: ({ classification_tree_id, ...item }) => ({
        url: `classification-trees/${classification_tree_id}/items`,
        method: 'POST',
        body: item,
      }),
      invalidatesTags: (result, error, { classification_tree_id }) => [
        { type: 'ElementTree', id: classification_tree_id },
      ],
    }),
    // put classification-items/{classificationItem}
    updateClassificationItem: builder.mutation({
      query: ({ classificationItem, ...item }) => ({
        url: `classification-items/${classificationItem}`,
        method: 'PUT',
        body: item,
      }),
      invalidatesTags: (result, error, { classificationItem }) => [
        { type: 'ElementItem', id: classificationItem }
      ],
    }),

    // Observations Endpoints
    addObservation: builder.mutation({
      query: ({ classificationItem, observation }) => ({
        url: `classification-trees/element/${classificationItem}/observations`,
        method: 'POST',
        body: observation,
      }),
      invalidatesTags: ['Observations'],
    }),
    getObservations: builder.query({
      query: ({ classificationItem, page = 1, perPage = 10 }) => ({
        url: `classification-trees/element/${classificationItem}/observations`,
        params: { page, per_page: perPage },
      }),
      providesTags: ['Observations'],
    }),
    updateObservation: builder.mutation({
      query: ({ classificationItem, comment, observation }) => ({
        url: `classification-trees/element/${classificationItem}/observations/${comment}`,
        method: 'PUT',
        body: observation,
      }),
      invalidatesTags: ['Observations'],
    }),
    deleteObservation: builder.mutation({
      query: ({ classificationItem, comment }) => ({
        url: `classification-trees/element/${classificationItem}/observations/${comment}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Observations'],
    }),

    // Recommendations Endpoints
    addRecommendation: builder.mutation({
      query: ({ classificationItem, recommendation }) => ({
        url: `classification-trees/element/${classificationItem}/recommendations`,
        method: 'POST',
        body: recommendation,
      }),
      invalidatesTags: ['Recommendations'],
    }),
    getRecommendations: builder.query({
      query: ({ classificationItem, page = 1, perPage = 10 }) => ({
        url: `classification-trees/element/${classificationItem}/recommendations`,
        params: { page, per_page: perPage },
      }),
      providesTags: ['Recommendations'],
    }),
    updateRecommendation: builder.mutation({
      query: ({ classificationItem, recommendationId, recommendation }) => ({
        url: `classification-trees/element/${classificationItem}/recommendations/${recommendationId}`,
        method: 'PUT',
        body: recommendation,
      }),
      invalidatesTags: ['Recommendations'],
    }),
    deleteRecommendation: builder.mutation({
      query: ({ classificationItem, recommendationId }) => ({
        url: `classification-trees/element/${classificationItem}/recommendations/${recommendationId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Recommendations'],
    }),

    // Observations Mutations
    handleObservations: builder.mutation({
      query: ({ classificationItem, ...rest }) => ({
        url: `classification-trees/element/${classificationItem}/observations`,
        method: 'POST',
        body: { observations: rest.observations },
      }),
      invalidatesTags: ['Observations'],
    }),

    // Recommendations Mutations
    handleRecommendations: builder.mutation({
      query: ({ classificationItem, ...rest }) => ({
        url: `classification-trees/element/${classificationItem}/recommendations`,
        method: 'POST',
        body: { recommendations: rest.recommendations },
      }),
      invalidatesTags: ['Recommendations'],
    }),

    getTestSuites: builder.query({
      query: () => 'test-suites',
      transformResponse: (response: { data: any[] }) =>
        response.data.map((suite: any) => ({
          ...suite,
          inspections: suite.total_assessments,
          categories: suite.total_categories,
          subCategories: suite.total_specifications,
          tests: suite.total_specifications,
          // status: suite.status === 'inuse' ? 'active' : 'archived',
        })),
      providesTags: ['TestSuites'],
    }),
    getTestSuite: builder.query({
      query: (testSuiteId) => 'test-suites/' + testSuiteId,
      transformResponse: (response: { data: any }) => {
        const suite = response.data;
        return {
          ...suite,
          inspections: suite.total_assessments,
          categories: suite.total_categories,
          subCategories: suite.total_specifications,
          tests: suite.total_specifications,
          //status: suite.status === 'archived' ? 'archived' : 'active',
        };
      },
      providesTags: ['TestSuites'],
    }),
    createTestSuite: builder.mutation({
      query: (newTestSuite) => ({
        url: 'test-suites',
        method: 'POST',
        body: newTestSuite,
      }),
      invalidatesTags: ['TestSuites'],
    }),
    updateTestSuite: builder.mutation({
      query: ({ id, ...testSuite }) => ({
        url: `test-suites/${id}`,
        method: 'PUT',
        body: testSuite,
      }),
      invalidatesTags: ['TestSuites'],
    }),

    updateTestSuiteFields: builder.mutation({
      query: ({ id, ...fields }) => ({
        url: `test-suites/${id}/fields`,
        method: 'PUT',
        body: fields,
      }),
      invalidatesTags: ['TestSuites'],
    }),

    duplicateTestSuite: builder.mutation({
      query: (id) => ({
        url: `test-suites/${id}/duplicate`,
        method: 'POST',
      }),
      invalidatesTags: ['TestSuites'],
    }),

    getTestSpecifications: builder.query({
      query: (testSuiteId) => `test-suites/${testSuiteId}/tests`,
      transformResponse: (response: { data: any[] }) => response.data,
      providesTags: ['Specifications'],
    }),

    updateTestSpecification: builder.mutation<
      TestSpecificationsEntity,
      {
        testSuiteId: number;
        testSpecificationId: number;
        test: Partial<TestSpecificationsEntity>;
      }
    >({
      query: ({ testSuiteId, testSpecificationId, test }) => ({
        url: `test-suites/${testSuiteId}/tests/${testSpecificationId}`,
        method: 'PUT',
        body: test,
      }),
      invalidatesTags: ['Specifications'],
    }),

    createTestSpecification: builder.mutation<
      TestSpecificationsEntity,
      { testSuiteId: number; test: Partial<TestSpecificationsEntity> }
    >({
      query: ({ testSuiteId, test }) => ({
        url: `test-suites/${testSuiteId}/tests`,
        method: 'POST',
        body: test,
      }),
      invalidatesTags: ['Specifications'],
    }),

    deleteTestSpecification: builder.mutation<
      void,
      { testSuiteId: number; testSpecificationId: number }
    >({
      query: ({ testSuiteId, testSpecificationId }) => ({
        url: `test-suites/${testSuiteId}/tests/${testSpecificationId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Specifications'],
    }),

    renameTestSpecificationCategory: builder.mutation<
      void,
      {
        testSuiteId: string;
        new_category: string;
        old_category: string;
      }
    >({
      query: ({ testSuiteId, new_category, old_category }) => ({
        url: `test-suites/${testSuiteId}/categories`,
        method: 'PUT',
        body: { new_category, old_category },
      }),
      invalidatesTags: ['Specifications'],
    }),

    renameTestSpecificationSubCategory: builder.mutation<
      void,
      {
        testSuiteId: string;
        category: string;
        old_subcategory: string;
        new_subcategory: string;
      }
    >({
      query: ({ testSuiteId, category, old_subcategory, new_subcategory }) => ({
        url: `test-suites/${testSuiteId}/subcategories`,
        method: 'PUT',
        body: { category, old_subcategory, new_subcategory },
      }),
      invalidatesTags: ['Specifications'],
    }),

    getRatingScales: builder.query({
      query: () => 'rating-scales',
      transformResponse: (response: { data: any[] }) => response.data,
      providesTags: ['RatingScales'],
    }),

    getTestItem: builder.query({
      query: (testItemId) => `tests/${testItemId}`,
      transformResponse: (response: { data: any }) => response.data,
      providesTags: (result) => {
        return result
          ? [
              { type: 'TestItem' as const, id: result.assessmentTestId },
              { type: 'TestItem', id: 'LIST' },
            ]
          : [{ type: 'TestItem', id: 'LIST' }];
      },
    }),

    getTestItems: builder.query<EntityState<TestItem, number>, number>({
      query: (assessmentId) => `assessments/${assessmentId}/test-items`,
      //transformResponse: (response: {data: TestItem[]}) => response.data,
      transformResponse: (response: { data: TestItem[] }) => {
        // Transform the response into a normalized state using the adapter
        return testItemsAdapter.setAll(
          testItemsAdapter.getInitialState(),
          response.data
        );
      },
      providesTags: (result) =>
        result
          ? [
              ...result.ids.map((id) => ({ type: 'TestItems' as const, id })),
              { type: 'TestItems' as const, id: 'LIST' },
            ]
          : [{ type: 'TestItems' as const, id: 'LIST' }],
    }),

    getTestItemById: builder.query({
      query: (id) => `tests/${id}`,
      transformResponse: (response: { data: TestItem }) => {
        // Normalize the single item response using the adapter
        return testItemsAdapter.upsertOne(
          testItemsAdapter.getInitialState(),
          response.data
        );
      },
      providesTags: (result, error, id) => [{ type: 'TestItems', id }],
    }),

    archiveTestSuiteForUser: builder.mutation({
      query: (id) => ({
        url: `test-suites/${id}/status`,
        method: 'PUT',
      }),
      invalidatesTags: ['TestSuites'],
    }),

    archiveTestSuite: builder.mutation({
      query: (id) => ({
        url: `test-suites/${id}/archive`,
        method: 'PUT',
      }),
      invalidatesTags: ['TestSuites'],
    }),

    getAssessment: builder.query<Assessment, number>({
      query: (assessmentId) => `assessments/${assessmentId}`,
      transformResponse: (response: { data: Assessment }) => response.data,
      providesTags: ['Assessment'],
    }),

    getReportTestSuites: builder.query<TestSuite[], number>({
      query: (reportId) => `reports/${reportId}/test-suites`,
      transformResponse: (response: { data: TestSuite[] }) => response.data,
    }),

    getReportItems: builder.query<TestItem[], string>({
      query: (assessmentId) => `reports/${assessmentId}/test-items`,
      transformResponse: (response: { data: TestItem[] }) => response.data,
      providesTags: (result) =>
        result
          ? [
              ...result.map((item) => ({
                type: 'TestItems' as const,
                id: item.assessmentTestId,
              })),
              { type: 'TestItems' as const, id: 'LIST' },
            ]
          : [{ type: 'TestItems', id: 'LIST' }],
    }),

    createTestItem: builder.mutation({
      query: (newTestItem) => ({
        url: 'tests',
        method: 'POST',
        body: newTestItem,
      }),
      invalidatesTags: (result, error, { assessmentId }) => [
        { type: 'TestItems', id: assessmentId },
        { type: 'TestItems', id: 'LIST' },
      ],
    }),

    updateTestItem: builder.mutation<TestItem, Partial<TestItem>>({
      query: ({ assessmentTestId, ...updatedItem }) => ({
        url: `tests/${assessmentTestId}`,
        method: 'PUT',
        body: updatedItem,
      }),
      invalidatesTags: (result, error, { assessmentTestId, assessmentId }) => [
        { type: 'TestItems', id: assessmentTestId },
        { type: 'TestItems', id: assessmentId },
      ],
    }),

    getReportBlocks: builder.query<ReportBlock[], void>({
      query: () => 'report-blocks',
      transformResponse: (response: { data: ReportBlock[] }) => response.data,
      providesTags: ['ReportBlocks'],
    }),
    // Fetch a single ReportBlock by ID
    getReportBlock: builder.query<ReportBlock, string>({
      query: (id) => `report-blocks/${id}`,
      transformResponse: (response: { data: ReportBlock }) => response.data,
      providesTags: ['ReportBlocks'],
    }),
    // Create a new ReportBlock

    createReportBlock: builder.mutation({
      query: (newReportBlock) => ({
        url: 'report-blocks',
        method: 'POST',
        body: newReportBlock,
      }),
      invalidatesTags: ['ReportBlocks'],
    }),
    // Update an existing ReportBlock
    updateReportBlock: builder.mutation({
      query: ({ id, ...block }) => ({
        url: `report-blocks/${id}`,
        method: 'PUT',
        body: block,
      }),
      invalidatesTags: ['ReportBlocks'],
    }),
    // Delete a ReportBlock
    deleteReportBlock: builder.mutation<{ success: boolean }, number>({
      query: (id) => ({
        url: `report-blocks/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['ReportBlocks'],
    }),

    archiveReportBlockForUser: builder.mutation({
      query: (id) => ({
        url: `report-blocks/${id}/status`,
        method: 'PUT',
      }),
      invalidatesTags: ['ReportBlocks'],
    }),

    // Archive Classification Tree Mutation
    archiveReportBlock: builder.mutation({
      query: (id) => ({
        url: `report-blocks/${id}/archive`,
        method: 'PUT',
      }),
      invalidatesTags: ['ReportBlocks'],
    }),

    duplicateReportBlock: builder.mutation({
      query: (id) => ({
        url: `report-blocks/${id}/duplicate`,
        method: 'POST',
      }),
      invalidatesTags: ['ReportBlocks'],
    }),

    getInspections: builder.query<Assessment[], void>({
      query: () => 'assessments',
      providesTags: ['Assessment'],
    }),

    getReportInspections: builder.query<Assessment[], number>({
      query: (reportId) => `reports/${reportId}/assessments`,
      transformResponse: (response: { data: Assessment[] }) => response.data,
      providesTags: (result, error, reportId) => [
        { type: 'Assessment', id: reportId },
        'Assessment',
      ],
      keepUnusedDataFor: 60, // Cache lifetime in seconds
    }),

    getInspection: builder.query<Assessment, string>({
      query: (id) => `assessments/${id}`,
      transformResponse: (response: { data: Assessment }) => response.data,
      providesTags: ['Assessment'],
    }),

    archiveInspection: builder.mutation({
      query: (assessmentId) => ({
        url: `assessments/${assessmentId}/archive`,
        method: 'PUT',
      }),
      invalidatesTags: ['Assessment'],
    }),

    archiveInspectionForUser: builder.mutation({
      query: (assessmentId) => ({
        url: `assessments/${assessmentId}/status`,
        method: 'PUT',
      }),
      invalidatesTags: ['Assessment'],
    }),

    updateInspection: builder.mutation({
      query: ({ id, ...inspection }) => ({
        url: `assessments/${id}`,
        method: 'PUT',
        body: inspection,
      }),
      invalidatesTags: ['Assessment'],
    }),

    createInspection: builder.mutation({
      query: (inspection) => ({
        url: 'assessments',
        method: 'POST',
        body: inspection,
      }),
      invalidatesTags: ['Assessment'],
    }),

    getReports: builder.query<ReportEntity[], void>({
      query: () => 'reports',
      transformResponse: (response: { data: ReportEntity[] }) => response.data,
      providesTags: ['Reports'],
    }),
    getReport: builder.query<ReportEntity, string>({
      query: (id) => `reports/${id}`,
      transformResponse: (response: { data: ReportEntity }) => response.data,
      // providesTags: ['Reports'],
      providesTags: (result) =>
        result
          ? [
              { type: 'Report', id: result.id },
              { type: 'Reports', id: 'LIST' },
              ...result.sections.map((section) => ({
                type: 'ReportSection' as const,
                id: section.id,
              })),
            ]
          : [{ type: 'Report', id: 'LIST' }],
    }),
    createReport: builder.mutation<ReportResponse, ReportPayload>({
      query: (payload) => {
        // const formData = new FormData();

        // if (payload['logo']) {
        //   formData.append('logo', payload['logo']);
        // }

        // const data = JSON.stringify({
        //   ...payload,
        //   logo: undefined,
        // });

        // formData.append('payload', data);

        return {
          url: 'reports',
          method: 'POST',
          body: payload,
        };
      },
      invalidatesTags: [{ type: 'Reports', id: 'LIST' }],
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(
            rAPI.util.updateQueryData('getReports', undefined, (draft) => {
              draft.push(data['data']);
            })
          );
        } catch {
          console.error('Error creating report');
        }
      },
    }),
    updateReport: builder.mutation<
      ReportEntity,
      Partial<ReportUpdatePayload> & Pick<ReportEntity, 'id'>
    >({
      query: ({ id, ...patch }) => ({
        url: `reports/${id}`,
        method: 'PUT',
        body: patch,
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: 'Report', id },
        { type: 'Reports', id: 'LIST' },
      ],
    }),
    deleteReport: builder.mutation<{ success: boolean; id: string }, string>({
      query: (id) => ({
        url: `reports/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, id) => [
        { type: 'Report', id },
        { type: 'Reports', id: 'LIST' },
      ],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          rAPI.util.updateQueryData('getReports', undefined, (draft) => {
            const index = draft.findIndex((report) => report.id === id);
            if (index !== -1) draft.splice(index, 1);
          })
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),

    archiveReport: builder.mutation({
      query: (id) => ({
        url: `reports/${id}/archive`,
        method: 'PUT',
      }),
      invalidatesTags: (result, error, id) => [
        { type: 'Report', id },
        { type: 'Reports', id: 'LIST' },
      ],
    }),

    archiveReportForUser: builder.mutation({
      query: (id) => ({
        url: `reports/${id}/status`,
        method: 'PUT',
      }),
      invalidatesTags: (result, error, id) => [
        { type: 'Report', id },
        { type: 'Reports', id: 'LIST' },
      ],
    }),

    //api/v1/client

    getClients: builder.query({
      query: () => 'clients',
      transformResponse: (response: { data: any[] }) => response.data,
    }),
    getClient: builder.query({
      query: (clientId) => `client/${clientId}`,
    }),
    createClient: builder.mutation({
      query: (client) => ({
        url: 'client',
        method: 'POST',
        body: client,
      }),
    }),
    updateClient: builder.mutation({
      query: ({ id, ...client }) => ({
        url: `client/${id}`,
        method: 'PUT',
        body: client,
      }),
    }),
    deleteClient: builder.mutation({
      query: (clientId) => ({
        url: `client/${clientId}`,
        method: 'DELETE',
      }),
    }),

    getAuthors: builder.query<
      UsersEntity[],
      { assets: number[]; inspections: number[] }
    >({
      query: ({ assets, inspections }) => {
        const params = new URLSearchParams();
        assets.forEach((asset) => params.append('assets', asset.toString()));
        inspections.forEach((inspection) =>
          params.append('inspections', inspection.toString())
        );
        return {
          url: `authors?${params.toString()}`,
          method: 'GET',
        };
      },
      transformResponse: (response: { data: UsersEntity[] }) => response.data,
    }),

    getReportRevisions: builder.query<
      // PaginatedResponse<ReportRevision>,
      ReportRevision[],
      { report_id?: number; status?: string; page?: number; per_page?: number }
    >({
      query: (params) => ({
        url: `report/${params.report_id}/revisions`,
        params,
      }),
      transformResponse: (response: { data: ReportRevision[] }) =>
        response.data,
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({
                type: 'ReportRevision' as const,
                id,
              })),
              { type: 'ReportRevision', id: 'LIST' },
            ]
          : [{ type: 'ReportRevision', id: 'LIST' }],
    }),

    getReportRevision: builder.query<ReportRevision, number>({
      query: (id) => `report/revisions/${id}`,
      providesTags: (result, error, id) => [{ type: 'ReportRevision', id }],
    }),

    createReportRevision: builder.mutation<
      ReportRevision,
      Pick<ReportRevision, 'report_id' | 'notes'>
    >({
      query: (body) => ({
        url: 'report/revisions',
        method: 'POST',
        body,
      }),
      invalidatesTags: [{ type: 'ReportRevision', id: 'LIST' }],
    }),

    updateReportRevision: builder.mutation<
      ReportRevision,
      Pick<ReportRevision, 'id' | 'notes'>
    >({
      query: ({ id, ...patch }) => ({
        url: `report/revisions/${id}`,
        method: 'PUT',
        body: patch,
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: 'ReportRevision', id },
      ],
    }),

    deleteReportRevision: builder.mutation<void, number>({
      query: (id) => ({
        url: `report/revisions/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, id) => [{ type: 'ReportRevision', id }],
    }),

    publishRevision: builder.mutation<ReportRevision, number>({
      query: (id) => ({
        url: `report/revisions/${id}/publish`,
        method: 'POST',
      }),
      invalidatesTags: (result, error, id) => [
        { type: 'ReportRevision', id },
        { type: 'ReportRevision', id: 'LIST' },
      ],
    }),

    withdrawRevision: builder.mutation<ReportRevision, number>({
      query: (id) => ({
        url: `report/revisions/${id}/withdraw`,
        method: 'POST',
      }),
      invalidatesTags: (result, error, id) => [
        { type: 'ReportRevision', id },
        { type: 'ReportRevision', id: 'LIST' },
      ],
    }),

    getReportSections: builder.query<ReportSection[], number>({
      query: (reportId) => `reports/${reportId}/sections`,
      providesTags: (result, error, reportId) =>
        result
          ? [
              ...result.map(({ id }) => ({
                type: 'ReportSection' as const,
                id,
              })),
              { type: 'ReportSections' as const, id: reportId },
            ]
          : [{ type: 'ReportSections' as const, id: reportId }],
    }),
    getReportSection: builder.query<
      ReportSection,
      { reportId: number; sectionId: string }
    >({
      query: ({ reportId, sectionId }) =>
        `reports/${reportId}/sections/${sectionId}`,
      providesTags: (result, error, { reportId, sectionId }) =>
        reportSectionTags(reportId, sectionId),
    }),
    createReportSection: builder.mutation<
      ReportSection,
      { reportId: number; data: Partial<ReportSection> }
    >({
      query: ({ reportId, data }) => ({
        url: `reports/${reportId}/sections`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: (result, error, { reportId }) =>
        reportSectionTags(reportId),
    }),
    updateReportSection: builder.mutation<
      ReportSection,
      { reportId: number; sectionId: string; data: Partial<ReportSection> }
    >({
      query: ({ reportId, sectionId, data }) => ({
        url: `reports/${reportId}/sections/${sectionId}`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: (result, error, { reportId, sectionId }) =>
        reportSectionTags(reportId, sectionId),
      async onQueryStarted(
        { reportId, sectionId, data },
        { dispatch, queryFulfilled }
      ) {
        const patchResult = dispatch(
          rAPI.util.updateQueryData('getReportSections', reportId, (draft) => {
            const section = draft.find((s) => s.id === sectionId);
            if (section) {
              Object.assign(section, data);
            }
          })
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),
    deleteReportSection: builder.mutation<
      void,
      { reportId: number; sectionId: string }
    >({
      query: ({ reportId, sectionId }) => ({
        url: `reports/${reportId}/sections/${sectionId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, { reportId, sectionId }) =>
        reportSectionTags(reportId, sectionId),
    }),

    upsertReportSections: builder.mutation<
      ReportSection[],
      {
        reportId: number;
        sections: Array<Partial<ReportSection>>;
      }
    >({
      transformResponse: (response: { data: ReportSection[] }) => response.data,
      query: ({ reportId, sections }) => ({
        url: `reports/${reportId}/sections`,
        method: 'POST',
        body: { sections },
      }),
      invalidatesTags: (result, error, { reportId }) => [
        { type: 'Report', id: reportId.toString() },
      ],
    }),
  }),
});

export const {
  useExtendAndResendInviteMutation,

  useGetAssetsQuery,
  useGetAssetQuery,
  useCreateAssetMutation,
  useUpdateAssetMutation,
  useRetireAssetMutation,
  useArchiveAssetMutation,
  useArchiveAssetForUserMutation,
  useGetAssetLocationsQuery,
  useAddCollaboratorMutation,
  useRemoveCollaboratorMutation,
  useUploadCoverImageMutation,
  useDeleteCoverImageMutation,
  useGetAssetAssessmentsQuery,
  useGetAssetBuildingsQuery,
  useAddBuildingMutation,
  useUpdateBuildingMutation,
  useGetAssetLevelsQuery,
  useUpdateLevelMutation,
  useAddAssetLevelMutation,
  useGetAssetAreasQuery,
  useAddAssetAreaMutation,
  useUpdateAreaMutation,

  useGetLoggedInUserQuery,
  useGetTeamUsersQuery,
  useUpdateUserProfileMutation,

  useGetUserQualificationsQuery,
  useCreateUserQualificationMutation,
  useUpdateUserQualificationMutation,
  useDeleteUserQualificationMutation,

  useGetOfficesQuery,
  useCreateOfficeMutation,
  useDeleteOfficeMutation,
  useUpdateOfficeMutation,

  useGetOrganisationQuery,
  useUpdateOrganisationMutation,
  useLeaveOrganisationMutation,

  useInviteTeamMemberMutation,
  useCreateUserMutation,
  useUpdateUserRoleMutation,

  useGetAdminOrganisationsQuery,
  useGetAdminUsersQuery,
  useDeleteAdminUserMutation,
  useChangeUserRoleMutation,

  useGetElementGroupsQuery,
  useGetElementGroupQuery,
  useCreateElementGroupMutation,
  useUpdateElementGroupMutation,
  useArchiveElementTreeForUserMutation,
  useArchiveElementTreeMutation,
  useDuplicateClassificationTreeMutation,

  useGetElementTreeQuery,
  useCreateClassificationItemMutation,
  useUpdateClassificationItemMutation,

  useAddObservationMutation,
  useGetObservationsQuery,
  useUpdateObservationMutation,
  useDeleteObservationMutation,

  useAddRecommendationMutation,
  useGetRecommendationsQuery,
  useUpdateRecommendationMutation,
  useDeleteRecommendationMutation,

  useHandleObservationsMutation,
  useHandleRecommendationsMutation,

  useGetTestSuitesQuery,
  useGetTestSuiteQuery,
  useCreateTestSuiteMutation,
  useUpdateTestSuiteMutation,
  useUpdateTestSuiteFieldsMutation,

  useArchiveTestSuiteForUserMutation,
  useArchiveTestSuiteMutation,

  useDuplicateTestSuiteMutation,

  useGetTestSpecificationsQuery,
  useCreateTestSpecificationMutation,
  useUpdateTestSpecificationMutation,
  useDeleteTestSpecificationMutation,
  useRenameTestSpecificationCategoryMutation,
  useRenameTestSpecificationSubCategoryMutation,

  useGetRatingScalesQuery,

  useGetTestItemQuery,
  useGetTestItemsQuery,
  useGetAssessmentQuery,
  useCreateTestItemMutation,
  useUpdateTestItemMutation,

  useGetReportItemsQuery,

  useGetReportBlocksQuery,
  useGetReportBlockQuery,
  useCreateReportBlockMutation,
  useUpdateReportBlockMutation,
  useDeleteReportBlockMutation,

  useGetReportTestSuitesQuery,

  useArchiveReportBlockForUserMutation,
  useArchiveReportBlockMutation,
  useDuplicateReportBlockMutation,

  useGetInspectionsQuery,
  useArchiveInspectionMutation,
  useArchiveInspectionForUserMutation,
  useGetInspectionQuery,
  useUpdateInspectionMutation,
  useCreateInspectionMutation,

  useGetReportQuery,
  useGetReportsQuery,
  useCreateReportMutation,
  useUpdateReportMutation,
  useDeleteReportMutation,
  useArchiveReportForUserMutation,
  useArchiveReportMutation,

  useGetClientsQuery,
  useGetClientQuery,
  useCreateClientMutation,
  useUpdateClientMutation,
  useDeleteClientMutation,

  useGetAuthorsQuery,

  useGetReportRevisionsQuery,
  useGetReportRevisionQuery,
  useCreateReportRevisionMutation,
  useUpdateReportRevisionMutation,
  useDeleteReportRevisionMutation,
  usePublishRevisionMutation,
  useWithdrawRevisionMutation,

  useGetReportSectionsQuery,
  useGetReportSectionQuery,
  useCreateReportSectionMutation,
  useUpdateReportSectionMutation,
  useDeleteReportSectionMutation,
  useUpsertReportSectionsMutation,

  useGetReportInspectionsQuery,
} = rAPI;

export const selectAssetsResult = rAPI.endpoints.getAssets.select();

export const selectAssetsData = createSelector(
  selectAssetsResult,
  (result) => result?.data ?? initialState
);

export const { selectAll: selectAllAssets, selectById: selectAssetById } =
  assetsAdapter.getSelectors(selectAssetsData);

export const selectTestItemsResult = (assessmentId: number) =>
  rAPI.endpoints.getTestItems.select(assessmentId);

export const selectTestItemsData = createSelector(
  // Use a tuple of selectors to maintain referential stability
  [
    (state: RootState) => state,
    (_state: RootState, assessmentId: number) => assessmentId,
  ],
  (state, assessmentId) => {
    const result = selectTestItemsResult(assessmentId)(state);
    return result.data ?? testItemsAdapter.getInitialState();
  }
);

export const {
  selectAll: selectAllTestItemsAdapter,
  selectById: selectTestItemByIdAdapter,
  selectIds: selectTestItemIdsAdapter,
} = testItemsAdapter.getSelectors();

export const selectAllTestItems = createSelector(
  [
    (state: RootState, assessmentId: number) =>
      selectTestItemsData(state, assessmentId),
  ],
  (testItemsState) => selectAllTestItemsAdapter(testItemsState)
);

export const selectTestItemById = createSelector(
  [
    (state: RootState, assessmentId: number) =>
      selectTestItemsData(state, assessmentId),
    (_state: RootState, _assessmentId: number, itemId: number) => itemId,
  ],
  (testItemsState, itemId) => selectTestItemByIdAdapter(testItemsState, itemId)
);
