'use client'

import React, { useEffect, useMemo, useState } from 'react';
import { cn } from "@/lib/utils"
import { Button } from "@/components/ui/button"
import { Label } from "@/components/ui/label"
import { TestSuite } from 'store/src/lib/tests/entity';
import { useRaytingDimensions } from '@/utils/useRaytingDimensions';
import { RatingMixedScore, RatingScore } from '@/components/raytd/rating-tool';
import { convertRatingToScore } from '@/app/features/reports/ratingHelpers';
import { Controller, useForm, useFormContext } from 'react-hook-form';

export type RatingTypes = 'compliance' | 'compliance_b' | 'condition' | 'general' | 'generic';
export type Rating = 'very_poor' | 'poor' | 'fair' | 'good' | 'very_good';

export type RatingScale = {
  id: number;
  label: string;
  rateable: boolean;

  very_poor: string;
  poor: string;
  fair: string;
  good: string;
  very_good: string;

  organisation_id: number;
  //type: 'single' | 'individual' | 'no_rating';
  type: RatingTypes;
};


export const RATING_LABELS: Rating[] = ['very_poor', 'poor', 'fair', 'good', 'very_good'];

export const convertIndexToRating = (index: number): Rating => RATING_LABELS[index];

interface RaytdResultProps {
  value: string | undefined | null,
  testSuite: TestSuite | null
  width?: number;
}


const RaytdResult = React.memo(({ value, testSuite, width }: RaytdResultProps) => {

  console.info('RaytdResult', value, testSuite?.ratingScale?.type);
  return <RatingScore type={testSuite.ratingScale.type} score={convertRatingToScore(value)} width={width} height={54} showSelector={true} />;

});

const RatingCircle: React.FC<{
  index: number;
  circleSize: number;
  overlap: number;
  startX: number;
  onPress: () => void;
}> = React.memo(({ index, circleSize, overlap, startX, onPress }) => (
  <Button
    variant="ghost"
    className={cn(
      "absolute rounded-full p-0",
      "hover:bg-transparent focus:ring-0 focus:ring-offset-0"
    )}
    style={{
      width: circleSize,
      height: circleSize,
      left: startX + index * (circleSize - overlap),
    }}
    onClick={onPress}
  />
));

const RatingLabel: React.FC<{ text: string }> = React.memo(({ text }) => (
  <div className="py-2.5">
    {text.split('\\n').map((line, index) => (
      <p key={index} className="text-sm text-zinc-950">
        {line}
      </p>
    ))}
  </div>
));


interface ResultPickerProps extends RaytdResultProps {
  name: string;
  onChange: (value: string) => void,
  onBlur?: () => void,
  allowDeselection?: boolean;
}

const RaytdResultPicker: React.FC<ResultPickerProps> = ({
  name,
  width = 230,
  onChange,
  onBlur,
  value,
  testSuite,
  allowDeselection = false,
}) => {
  const [selectedIndex, setSelectedIndex] = useState<number | null>(value ? RATING_LABELS.indexOf(value as Rating) : null);
  const { circleSize, overlap, startX } = useRaytingDimensions(width);

  const formContext = useFormContext();
    const { control, setValue, watch } = formContext || useForm();
    

  useEffect(() => {
    console.info('rating updated', value);
    setSelectedIndex(value ? RATING_LABELS.indexOf(value as Rating) : null);
  }, [value]);

  const handleSelection = React.useCallback((index: number) => {
    if (testSuite?.ratingScale[convertIndexToRating(index)] === '') {
      return; // Do nothing if the rating is not available
    }

    const newSelectedIndex = selectedIndex === index && allowDeselection ? null : index;
    setSelectedIndex(newSelectedIndex);
    onChange?.(convertIndexToRating(newSelectedIndex ?? index));
    onBlur?.();

    console.debug('rating selected', newSelectedIndex, convertIndexToRating(newSelectedIndex ?? index));

  }, [selectedIndex, allowDeselection, onChange, testSuite, onBlur]);

  const labelText = useMemo(() => {
    if (selectedIndex === null || !testSuite?.ratingScale?.rateable) {
      return null;
    }
    return testSuite.ratingScale[convertIndexToRating(selectedIndex)];
  }, [selectedIndex, testSuite]);

  const circles = useMemo(
    () =>
      Array.from({ length: 5 }, (_, index) => (
        <RatingCircle
          key={index}
          index={index}
          circleSize={circleSize}
          overlap={overlap}
          startX={startX}
          onPress={() => handleSelection(index)}
        />
      )),
    [circleSize, overlap, startX, handleSelection]
  );

  const ratingScaleType = testSuite?.ratingScale?.type || '';

  if (!testSuite?.ratingScale) {
    return <Label>Rating scale not loaded</Label>
  }

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
  return (
    <div
      className="flex flex-col"
      style={{
        minHeight: ratingScaleType === 'generic' || !testSuite.ratingScale.rateable ? 52 : 90,
      }}
    >
      <div className="mb-4">
        <div className="relative flex justify-center items-center" style={{ height: circleSize, width: width }}>
          <RaytdResult testSuite={testSuite} value={value} width={width} />
          {circles}
        </div>
      </div>
      {labelText && <RatingLabel text={labelText} />}
    </div>
  );
}}
/>
);
}
export default React.memo(RaytdResultPicker);

